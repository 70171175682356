import React from 'react';
import styled from 'styled-components';
import translate from '../../../../providers/i18n/translate';
import { ThemeContext } from '../../../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { API_URL } from '../../../../config';
import axios from 'axios';
import BasicEditor from '../../../textEditors/basicEditor';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../../store/action-creators";
import { loadingConstants } from "../../../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: 100%;
    @media (min-width: 1024px) {
        width: ${props => props.width ? props.width : "inherit"};
    }
`

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10px 0;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`

const NewTreatment = ({diagnosisId, data, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress} = bindActionCreators(actionCreators, dispatch);

    const [ newTreatment, setNewTreatment ] = React.useState(data ? 
        {
            name: data.name,
            description: data.info,
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate)
        }
        :
        {
            name: '',
            description: '',
            startDate: new Date(),
            endDate: new Date()
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewTreatment({
            ...newTreatment,
            [name]: value
        });
    }

    const handleEditorDescription = (e, dataeditor) => {
        setNewTreatment({
            ...newTreatment,
            description: dataeditor
        })
    }

    const handleChangeDateStart = (newValue) => {
        setNewTreatment({
            ...newTreatment,
            startDate: newValue
        });
    }

    const handleChangeDateEnd = (newValue) => {
        setNewTreatment({
            ...newTreatment,
            endDate: newValue
        });
    }

    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        var formdata = new FormData();
        formdata.append("diagnosisId", parseInt(diagnosisId));
        formdata.append("name", newTreatment.name);
        formdata.append("description", newTreatment.description);
        formdata.append("startDate", newTreatment.startDate);
        formdata.append("endDate", newTreatment.startDate);
        if(data){
            const url = `${API_URL}/clinicHistory/treatments/${data.id}`;
            axios.put(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful editing' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                console.log(error.response.data.msg);
            })
        } else {
            const url = `${API_URL}/clinicHistory/treatments`;
            axios.post(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful creation' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                alert(intl.formatMessage({ id: 'errorContact' }));
                console.log(error.response?.data?.msg);
            })
        }
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

    return (
        <Root onSubmit={handleAction}>
            <Title>{data ? translate("editTreatment") : translate("addTreatment")}</Title>
            <TextField id="name" name="name" onChange={handleChange} value={newTreatment.name} label={intl.formatMessage({ id: 'name' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <InputGroup>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'start' })}
                        value={newTreatment.startDate}
                        onChange={handleChangeDateStart}
                    />
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'end' })}
                        value={newTreatment.endDate}
                        onChange={handleChangeDateEnd}
                    />
                </LocalizationProvider>
            </InputGroup>
            <BasicEditor
                name="description"
                value={newTreatment.description}
                onChange={handleEditorDescription}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: 'insertDescription' })}
            />
            <Button bg={theme.palette.accent} color={theme.palette.base}><CheckIcon /></Button>
        </Root>
    );
}

export default NewTreatment;