import React from "react";
import styled from 'styled-components';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ThemeContext } from "../../providers/theme";
import StarIcon from '@mui/icons-material/Star';
import { useIntl } from 'react-intl';
import TimeEvent from "./TimeEvent";


/* Styled components */
const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`



const Timeline = ({data, refresh}) => {

    return (
        <Root>
            {
                data && data.length > 0 && data.map((hc, index) => 
                    <TimeEvent key={`data_${index}`} index={index} data={hc} last={hc.delete} refresh={refresh} />
                )
                
            }
        </Root>
    );
}

export default Timeline;