import { createGlobalStyle } from 'styled-components';
import GeneralSansBold from './fonts/sans/GeneralSans-Bold.ttf'
import GeneralSansRegular from './fonts/sans/GeneralSans-Regular.ttf'
import GeneralSansLight from './fonts/sans/GeneralSans-Light.ttf'
import GeneralSansSemibold from './fonts/sans/GeneralSans-Semibold.ttf'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: GeneralSans;
  }
  @font-face {
    font-family: GeneralSans;
    src: url(${GeneralSansBold});
    font-weight: bold;
  }
  @font-face {
    font-family: GeneralSans;
    src: url(${GeneralSansSemibold});
    font-weight: 600;
  }
  @font-face {
    font-family: GeneralSans;
    src: url(${GeneralSansRegular});
  }
  @font-face {
    font-family: GeneralSans;
    src: url(${GeneralSansLight});
    font-weight: 100;
  }
  input:-webkit-autofill {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
  }
`
export default GlobalStyle;
