import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import {mob, mobText, desk, deskText} from '../utilities'
import { ThemeContext } from "../providers/theme";

const StyledButton = styled.button`
all: unset;
position: relative;
display: flex;
align-items: center;
justify-content: center;
font-family: helvetica;
font-weight: 100;
font-size: ${props => props.fontSize ? mobText(props.fontSize) : mobText(10)};
color: white;
background-color: ${props => props.backgroundColor};
box-shadow: 0px 3px 6px #00000029;
border-radius: 10px;
height: ${props => props.height ? mob(props.height) : mob(25) };
width: ${props => props.height ? mob(2.88*props.height) : mob(2.88*25)};
:hover{
  opacity: 0.9;
  cursor: pointer;
}
@media (min-width: 1024px){
  height: ${props => props.height ? desk(228 * props.height / 100) : desk(57) };
  width: ${props => props.height ? desk(2.81*(228 * props.height / 100)) : desk(2.81*57) };
  font-size: ${props => props.fontSize ? deskText(2.2*props.fontSize) : deskText(22)}; 
}
`

export default function VermasButton(props) {

  const theme = useContext(ThemeContext);

  return <StyledButton backgroundColor={theme.palette.accent} className={props.className} height={props.height} fontSize={props.fontSize}>ver más</StyledButton>;
}

