import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import NoImage from "../../images/no-image.png";
import Circle from "../Circle";
import { useIntl } from 'react-intl';
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LastClassificacionHorseCard from './LastClassificacionHorseCard';
import { useAuth } from "../../providers/authentication";
import hasScope from "../../providers/authentication/hasScope";

const Root = styled.div`
    position: relative;
    width: 320px;
    height: 425px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    margin: 10px 0;
    @media (min-width: 400px) {
        margin: 10px;
    }
`

const CardImg = styled.div`
    position: relative;
    width: 100%;
    height: 48.20%;
    background-color: #FFF;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
    @media (min-width: 1024px){
        height: 47.5%;
    }
`

const CircleContainer = styled.div`
    position: absolute;
    width: 50px;
    height: 50px;
    left: 50%;
    bottom: -18px;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 9;
`

const ContentCard = styled.div`
    position: relative;
    width: 100%;
    height: 51.80%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    background-color: #FFF;
`

const Title = styled.div`
    grid-column: 1/3;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.5rem;
    h2 {
        margin: 0;
        font-size: 1.25rem;  
        text-transform: uppercase; 
        font-weight: 700;      
    }
    a {
        text-decoration: none;
        color: ${(props) => props.color ? props.color : "#000"};
    }
    span{ 
        color: #873d90;
        font-weight: 600;
    }   
    strong{
        font-weight: 600;
    }
`


const BoxData = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : 'inherit'};
    text-align: center;
    font-size: 0.875rem;
    margin-top: 0.5rem;
    span {
        color: ${(props) => props.color1 ? props.color1 : "#000"};
        font-size: 1rem;
        font-weight: 600;
        margin-right: 0.2em;
    }
    strong {
        color: ${(props) => props.color2 ? props.color2 : "#000"};
        font-size: 1rem;
        font-weight: 600;
    }
`

const OptionsButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 25px;
    z-index: 9;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #000;
    background-color: #FFF;
    transition: all 300ms ease-in-out;
    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }
`

const OptionsList = styled.ul`
    position: absolute;
    top: 100%;
    right: 0;
    width: 100px;
    height: ${props => props.showMore ? "auto" : "0px"};
    overflow-y: hidden;
    z-index: 999;
    background-color: #FFF;
    opacity: ${props => props.showMore ? 1 : 0};
    border-radius: 10px 0 10px 10px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    transition: all 300ms ease-in-out;
`

const Option = styled.li`
    width: 100%;
    text-align: center;
    padding: 5px 10px;
    transition: all 300ms ease-in-out;
    color: ${props => props.color ? props.color : "#000"};
    &:hover {
        background-color: ${props => props.bgAccent ? `${props.bgAccent}50` : "grey"};
    }
`

const HorseCard = ({data, actionsTable}) => {

    const theme = React.useContext(ThemeContext);
    const intl = useIntl();
    const [ showMore, setShowMore ] = React.useState(false);
    const { token } = useAuth();

    const openMore = () => {
        setShowMore(!showMore);
    }

    const closeMore = () => {
        setShowMore(false);
    }

    const handleEdit = () => {
        actionsTable.edit.onClick(data);
        closeMore();
    }

    const handleDelete = () => {
        actionsTable.delete.onClick(data);
        closeMore();
    }

    return (
        <Root>
            <OptionsButton showMore={showMore}>
                <MoreVertIcon onClick={openMore} />
                <OptionsList showMore={showMore}>
                    {
                        hasScope(token, "editHorse") &&
                        <Option onClick={handleEdit} color={theme.palette.principal} bgAccent={theme.palette.accent}>{actionsTable.edit.tooltip}</Option>
                    }
                    {
                        hasScope(token, "deleteHorse") &&
                        <Option onClick={handleDelete} color={theme.palette.principal} bgAccent={theme.palette.accent}>{actionsTable.delete.tooltip}</Option>
                    }
                    
                </OptionsList>
            </OptionsButton>
            <CardImg>
                <Link to={`/backoffice/horses/${data.id}`}>
                    <img src={data.imageProfile ? data.imageProfile.url : NoImage} alt={`${data.name} preview`} />
                    <CircleContainer>
                        <Circle type="comp" color={theme.palette.accent} />
                    </CircleContainer>
                </Link>
            </CardImg>
            <ContentCard>
                <Title color={theme.palette.principal}>
                    <Link to={`/backoffice/horses/${data.id}`}>
                        <h2>{data.name}</h2>
                    </Link>
                    <BoxData color1={theme.palette.principal} color2={theme.palette.accent}>
                    <span>{`${intl.formatMessage({id:'born on'})} `}</span> 
                    <strong>{`${intl.formatDate(data.dateOfBirth, {year: "numeric",month: "numeric" ,day: "numeric"})}`}</strong>   
                    </BoxData>                
                </Title>
                <hr></hr>
                <BoxData color1={theme.palette.principal} color2={theme.palette.accent}>
                    <span>{translate('father')}: </span>
                    <strong>{data.father ? data.father.name : translate("unknown")}</strong>
                </BoxData>
                <BoxData color1={theme.palette.principal} color2={theme.palette.accent}>
                    <span>{translate('mother')}:</span>
                    <strong>{data.mother ? data.mother.name : translate("unknown")}</strong>
                </BoxData>
                <BoxData gridColumn="1/3" color1={theme.palette.principal} color2={theme.palette.accent}>
                    <span>{translate('location')}:</span>
                    <strong>{data.location.name}</strong>
                </BoxData>
                <BoxData gridColumn="1/3" color1={theme.palette.principal} color2={theme.palette.accent}>
                    <LastClassificacionHorseCard id={data.id} />
                </BoxData>
            </ContentCard>
        </Root>
    );
}

export default HorseCard;
