import { combineReducers } from "redux";
import testReducer from "./testReducer";
import { alertMessage } from "./messageBar/alertMessageReducer";
import { loading } from "./loading/loadingReducer";
import horsesReducer from "./horses/horsesReducer";

const reducers = combineReducers({
    alertMessage,
    loading,
    test: testReducer,
    horsesReducer
});

export default reducers;