import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { API_URL } from '../../config';

/* Styled components */
const LastTrainingStyled = styled.div`
    display: ${(props) => props.hide ? "none" : "flex"};
    flex-direction:row;
    justify-content:flex-start;
    align-items: flex-start;
    width: 100%;
        @media (min-width: 1024px) {
            flex-direction:column;
            justify-content:flex-start;;
            align-items: start;
            justify-content:flex-start;
        }
`

const BoxTitle2 = styled.h2`
    display: ${(props) => props.hide ? "none" : "flex"};
    align-items: center;
    justify-content: flex-start;
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : "inherit"};
    text-transform: uppercase;
    color: ${props => props.theme.palette.accent};
    font-size: 0.875rem;
    width: 100%;
    transition: all 300ms ease-in-out;
    font-weight: 400;
    @media (min-width: 1024px) {
        justify-content: center;
        align-items: flex-start;
        font-size: 1rem;
        color: ${props => props.theme.palette.accent};
        font-weight: 800;
    }
`

const BoxText = styled.p`
    width: 100%;
    display: ${(props) => props.hide ? "none" : "flex"};
    align-items: center;
    justify-content: center;
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : "inherit"};
    color: ${props => props.mobileStyle ? props.theme.palette.principal : props.theme.palette.base};
    font-size: 0.75rem;
    font-weight: 400;
    transition: all 300ms ease-in-out;
    @media (min-width: 1024px) {
        font-size: 0.85rem;
        color: ${props => props.theme.palette.secundary};
    }
`

const LastTraining = ({id}) => {

    const intl = useIntl();

    const [ lastTraining, setLastTraining ] = React.useState(null);

    React.useEffect(() => {
        if(!lastTraining){
            axios.get(`${API_URL}/mediacenter/trainings/${id}`)
            .then(result => {
                if(result.data && result.data.rows.length > 0){
                    setLastTraining(result.data.rows[result.data.rows.length - 1]);
                }
            })
            .catch(err => {
                console.log(err);
            })
        }
    }, [lastTraining])

    return (
        <React.Fragment>
            <LastTrainingStyled>
                <BoxTitle2>{translate("latest jump")}</BoxTitle2>
                <BoxText mobileStyle={true}>{lastTraining ? `${lastTraining.jump} (${intl.formatMessage({ id: 'meters' })})` : "---"}</BoxText>
            </LastTrainingStyled>
            <LastTrainingStyled>
            <BoxTitle2>{translate("latest training")}</BoxTitle2>
                <BoxText mobileStyle={true}>{lastTraining ? lastTraining.club : "---"}</BoxText>
            </LastTrainingStyled>
        </React.Fragment>
    );
}

export default LastTraining;