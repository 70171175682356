import React from 'react';

const ThemeContext = React.createContext();

const Provider = ({children, theme}) => (
	<ThemeContext.Provider value={theme} >
		{children}
	</ThemeContext.Provider>

);

export {
    ThemeContext,
    Provider
}

