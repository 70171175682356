import React from "react";
import styled from 'styled-components';
import { Table } from "../components/tables/table";
import AccordionRow from "../components/tables/table/AccordionRow";
import axios from "axios";
import HeadAccordion from "../components/tables/table/HeadAccordion";
import ContentAccordion from "../components/tables/table/ContentAccordion";
import Intervention from "../components/clinichistory/Intervention";
import Edit from "@mui/icons-material/Edit";
import { useIntl } from 'react-intl';
import NewIntervention from "../components/appointment/intervention/NewIntervention";
import { Modal } from "@mui/material";
import InterventionAccordion from "../components/appointment/intervention/InterventionAccordion";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`



const InterventionsPage = () => {

    const tableRef = React.useRef();

    const intl = useIntl();

    const { id } = useParams();

    const refresh = () => {
        tableRef.current.clean();
    }

    return (
        <Root>
            <Table 
                ref={tableRef}
                viewTable="accordion"
                accordion={(data) => <InterventionAccordion data={data} refresh={refresh} />}
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_URL}/clinicHistory/appointments/horse/${id}`,{
                            params: {
                                page: query.page,
                                limit: query.pageSize,
                                order: query.order
                            }
                        })
                        .then(appointments => {
                            const interventions = [];
                            appointments.data.rows.forEach(app => {
                                app.interventions.forEach(inter => {
                                    interventions.push({
                                        ...inter,
                                        appointmentId: app.id
                                    });
                                });
                            });
                            resolve({rows: interventions})
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
                }
            >
            </Table>
            
        </Root>
    );
}

export default InterventionsPage;

const interventionsExample = [
    {
        id: 1,
        date: "2022-02-01",
        description: "Descripción intervención 1",
        type: {
            id: 1,
            value: "Vacunación"
        },
        attachments: []
    },
    {
        id: 2,
        date: "2022-02-05",
        description: "Descripción intervención 2",
        type: {
            id: 1,
            value: "Radiografía"
        },
        attachments: [
            {
                name: "Attachment 1",
                url: "url attachment 1"
            },
            {
                name: "Attachment 2",
                url: "url attachment 2"
            }
        ]
    },
    {
        id: 3,
        date: "2022-02-01",
        description: "Descripción intervención 3",
        type: {
            id: 1,
            value: "Vacunación"
        },
        attachments: []
    },
    {
        id: 4,
        date: "2022-02-05",
        description: "Descripción intervención 4",
        type: {
            id: 1,
            value: "Radiografía"
        },
        attachments: [
            {
                name: "Attachment 1",
                url: "url attachment 1"
            },
            {
                name: "Attachment 2",
                url: "url attachment 2"
            }
        ]
    },
    {
        id: 5,
        date: "2022-02-01",
        description: "Descripción intervención 5",
        type: {
            id: 1,
            value: "Vacunación"
        },
        attachments: []
    },
    {
        id: 6,
        date: "2022-02-05",
        description: "Descripción intervención 6",
        type: {
            id: 1,
            value: "Radiografía"
        },
        attachments: [
            {
                name: "Attachment 1",
                url: "url attachment 1"
            },
            {
                name: "Attachment 2",
                url: "url attachment 2"
            }
        ]
    }
]