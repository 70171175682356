import React from "react";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";
import { useIntl } from "react-intl";
import { API_URL } from "../../../config";
import axios from "axios";
import BasicEditor from "../../textEditors/basicEditor";
import SelectInput from "../../inputs/SelectInput";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { loadingConstants } from "../../../store/reducers/loading/loadingConstants";
import CheckIcon from "@mui/icons-material/Check";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const NewIntervention = ({ appointmentId, data, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const dispatch = useDispatch();

  const { loadingAction, loadingProgress } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [newIntervention, setNewIntervention] = React.useState(
    data
      ? {
          description: data.description,
          typeId: data.type
            ? {
                label: data.type.value,
                value: data.type.id,
              }
            : null,
        }
      : {
          description: "",
          typeId: null,
        }
  );

  const handleEditorDescription = (e, dataeditor) => {
    setNewIntervention({
      ...newIntervention,
      description: dataeditor,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewIntervention({
      ...newIntervention,
      [name]: value,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    loadingAction(loadingConstants.WAIT);
    var formdata = new FormData();
    formdata.append("appointmentId", parseInt(appointmentId));
    formdata.append("typeId", newIntervention.typeId?.value);
    formdata.append("description", newIntervention.description);
    if (data) {
      const url = `${API_URL}/clinicHistory/interventions/${data.id}`;
      axios
        .put(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          //Listen for the onuploadprogress event
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            //Using local progress events
            if (e.lengthComputable) {
              let progress = (loaded / total) * 100;
              loadingProgress(loadingConstants.PROGRESS, progress);
            }
          },
        })
        .then((response) => {
          loadingAction(loadingConstants.READY);
          handleClose();
        })
        .catch((error) => {
          loadingAction(loadingConstants.READY);
          alert(intl.formatMessage({ id: "errorContact" }));
          console.log(error.response.data.msg);
        });
    } else {
      const url = `${API_URL}/clinicHistory/interventions`;
      axios
        .post(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          //Listen for the onuploadprogress event
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            //Using local progress events
            if (e.lengthComputable) {
              let progress = (loaded / total) * 100;
              loadingProgress(loadingConstants.PROGRESS, progress);
            }
          },
        })
        .then((response) => {
          loadingAction(loadingConstants.READY);
          handleClose();
        })
        .catch((error) => {
          loadingAction(loadingConstants.READY);
          alert(intl.formatMessage({ id: "errorContact" }));
          console.log(error.response?.data?.msg);
        });
    }
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Root onSubmit={handleAction}>
      <Title>
        {data ? translate("editIntervention") : translate("newIntervention")}
      </Title>
      <SelectInput
        value={newIntervention.typeId}
        fieldlabel="value"
        fieldvalue="id"
        dataurl={`${API_URL}/interventionType`}
        label={intl.formatMessage({ id: "interventionType" })}
        name="typeId"
        onChange={handleChangeAutocomplete}
        width="100%"
        margin="dense"
        size="small"
        required
      />
      <BasicEditor
        name="description"
        value={newIntervention.description}
        onChange={handleEditorDescription}
        editorLoaded={editorLoaded}
        maxContentHeight="100px"
        minContentHeight="100px"
        placeholder={intl.formatMessage({ id: "insertDescription" })}
      />
      <Button bg={theme.palette.accent} color={theme.palette.base}>
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewIntervention;
