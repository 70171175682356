import React from "react";
import translate from "../../providers/i18n/translate";
import { API_URL } from "../../config";
import axios from 'axios';

const LastClassificacionHorseCard = ({id}) => {

    const [ classification, setClassification ] = React.useState(null);

    const getClassification = () => {
        axios.get(`${API_URL}/historyclassification/lastbyhorse/${id}`)
        .then(result => {
            setClassification(result.data);
        })
        .catch(err => {
            console.log(err);
        })
    }

    React.useEffect(() => {
        getClassification();
    },[ id])

    return (
        classification &&
        <React.Fragment>
            <span>{translate('classification')}:</span>
            <strong>{classification.clasificationName.value}</strong>
        </React.Fragment>
    );
}

export default LastClassificacionHorseCard;