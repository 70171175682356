import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from '../inputs/SelectInput';
import { API_URL, API_URL_UM } from '../../config';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";
import { alertConstants } from '../../store/reducers/messageBar/alertConstants';

/* Styled components */
const Root = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 90vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const NewUser = ({userData, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress, alertMessage} = bindActionCreators(actionCreators, dispatch);

    const VETERINARIAN_ID = 3;

    const [ newUser, setNewUser ] = React.useState(userData ? 
        {
            firstName: userData.firstName,
            lastName: userData.lastName,
            userName: userData.userName,
            email: userData.email,
            role: null,
            registrationNumber: '',
            dni: ''
        }
        :
        {
            firstName: '',
            lastName: '',
            userName: '',
            email: '',
            password: '',
            repassword: '',
            role: null,
            registrationNumber: '',
            dni: ''
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser({
            ...newUser,
            [name]: value
        });
    }

    const handleChangeAutocomplete = (name, value) => {
        setNewUser({
            ...newUser,
            [name]: value
        });
    }


    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        if(userData){
            const url = `${API_URL_UM}/api/users?id=${userData.id}`;
            axios.put(url, {
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    userName: newUser.userName,
                    email: newUser.email
                },
                {
                    //Listen for the onuploadprogress event
                    onUploadProgress: e => {
                        const {loaded, total} = e;
                        //Using local progress events
                        if (e.lengthComputable) {
                            let progress = loaded / total * 100;
                            loadingProgress(loadingConstants.PROGRESS, progress);
                        }
                    },
                    withCredentials: true
                }
            )
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                alertMessage(intl.formatMessage({ id: 'successful editing' }), alertConstants.SUCCESS);
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                alertMessage(error.response.data.msg, alertConstants.ERROR);
                console.log(error);
            })
        } else {
            if(newUser.password === newUser.repassword){
                const url = `${API_URL_UM}/api/users`;
                axios.post(url, {
                    firstName: newUser.firstName,
                    lastName: newUser.lastName,
                    userName: newUser.userName,
                    email: newUser.email,
                    password: newUser.password,
                    role: newUser.role.value
                },
                {
                    //Listen for the onuploadprogress event
                    onUploadProgress: e => {
                        const {loaded, total} = e;
                        //Using local progress events
                        if (e.lengthComputable) {
                            let progress = loaded / total * 100;
                            loadingProgress(loadingConstants.PROGRESS, progress);
                        }
                    },
                    withCredentials: true 
                })
                .then(response => {
                    var formdata = new FormData();
                    formdata.append("userManagerId", response.data.id);
                    formdata.append("firstName", newUser.firstName);
                    formdata.append("lastName", newUser.lastName);
                    formdata.append("userName", newUser.userName);
                    formdata.append("email", newUser.email);
                    formdata.append("dni", newUser.dni);
                    if(newUser.role && newUser.role.value === VETERINARIAN_ID){
                        formdata.append("registrationNumber", newUser.registrationNumber);
                    }
                    const backend_url = newUser.role && newUser.role.value === VETERINARIAN_ID ? `${API_URL}/human/veterinarian` : `${API_URL}/human/peoples`
                    axios.post(backend_url, formdata, {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'multipart/form-data'
                        },
                        //Listen for the onuploadprogress event
                        onUploadProgress: e => {
                            const {loaded, total} = e;
                            //Using local progress events
                            if (e.lengthComputable) {
                                let progress = loaded / total * 100;
                                loadingProgress(loadingConstants.PROGRESS, progress);
                            }
                        },
                    })
                    .then(profile => {
                        loadingAction(loadingConstants.READY);
                        handleClose();
                        alertMessage(intl.formatMessage({ id: 'successful creation' }), alertConstants.SUCCESS);
                    })
                    .catch(err => {
                        console.log(err);
                        alertMessage(err.response.data.msg, alertConstants.ERROR);
                        // Delete created user if error
                        axios.delete(`${url}/user?id=${response.data.id}`, { withCredentials: true })
                        .then(responseDelete => {
                            loadingAction(loadingConstants.READY);
                        })
                        .catch(err => {
                            loadingAction(loadingConstants.READY);
                            alertMessage(err.response.data.msg, alertConstants.ERROR);
                            console.log(err);
                        })
                    })
                    
                })
                .catch(err => {
                    loadingAction(loadingConstants.READY);
                    alertMessage(err.response.data.msg, alertConstants.ERROR);
                    console.log(err);
                })
            } else {
                loadingAction(loadingConstants.READY);
                alertMessage(intl.formatMessage({ id: 'check password' }), alertConstants.ERROR);
            }
        }
    }

    return (
        <Root onSubmit={handleAction}>
            <Title>{userData ? translate("edit user") : translate("new user")}</Title>
            <TextField  id="firstName" name="firstName" onChange={handleChange} value={newUser.firstName} label={intl.formatMessage({ id: 'firstName' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <TextField  id="lastName" name="lastName" onChange={handleChange} value={newUser.lastName} label={intl.formatMessage({ id: 'lastName' })} variant="outlined" fullWidth margin="dense" size="small" required />
            {
                !userData && <TextField  id="dni" name="dni" onChange={handleChange} value={newUser.dni} label={intl.formatMessage({ id: 'dni' })} variant="outlined" fullWidth margin="dense" size="small" required />
            }
            <TextField  id="userName" name="userName" onChange={handleChange} value={newUser.userName} label={intl.formatMessage({ id: 'userName' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <TextField  id="email" name="email" type="email" onChange={handleChange} value={newUser.email} label={intl.formatMessage({ id: 'email' })} variant="outlined" fullWidth margin="dense" size="small" required />
            {
                !userData && <TextField  id="password" type="password" name="password" onChange={handleChange} value={newUser.password} label={intl.formatMessage({ id: 'password' })} variant="outlined" fullWidth margin="dense" size="small" required />
            }
            {
                !userData && <TextField  id="repassword" type="password" name="repassword" onChange={handleChange} value={newUser.repassword} label={intl.formatMessage({ id: 'repassword' })} variant="outlined" fullWidth margin="dense" size="small" required />
            }
            {
                !userData && <SelectInput  value={newUser.role} fieldlabel="name" fieldvalue="id" dataurl={`${API_URL_UM}/api/users/roles`} label={intl.formatMessage({ id: 'role' })} name="role" onChange={handleChangeAutocomplete} width="100%" margin="dense" size="small" withCredentials={true} required />
            }
            {
                newUser && newUser.role && newUser.role.value === VETERINARIAN_ID && 
                <TextField  id="registrationNumber" name="registrationNumber" onChange={handleChange} value={newUser.registrationNumber} label={intl.formatMessage({ id: 'registrationNumber' })} variant="outlined" fullWidth margin="dense" size="small" />
            }
            <Button bg={theme.palette.accent} color={theme.palette.base} ><CheckIcon /></Button>
        </Root>
    );
}

export default NewUser;