import React from "react";
import {useSelector} from "react-redux";
import Cover from "../components/cover"
import styled from 'styled-components/macro';
import Carousel from "../components/carousel";
import HarasSJDM from "../components/HarasSJDM";
import PrimerosPasos from "../components/PrimerosPasos";
import MediaSection from "../components/MediaSection";

/* Styled components */
const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    height: auto;
    width: 100%;
`

export default function HomePage()  {

    const state = useSelector(state => state);
    console.log(state);

    return(
        <Root>
            <Cover/>
            {/* <Carousel/> */}
            {/* <HarasSJDM/>
            <PrimerosPasos/> */}
            {/* <MediaSection/> */}
        </Root>
    )
};