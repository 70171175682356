import { useContext } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import styled from 'styled-components';
import { ThemeContext } from "../../../providers/theme";

const Root = styled.button`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    background: none;
    border: none;
    cursor: pointer;
    user-select: none;
    &:hover {
        transform: scale(1.1);
        color: ${props => props.hoverColor};
    }
`

const ExportButton = ({onClick}) => {

    const theme = useContext(ThemeContext);

    return (
        <Root onClick={onClick} hoverColor={theme.palette.accent}>
            <FileDownloadIcon />
        </Root>
    );
}

export default ExportButton;