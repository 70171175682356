import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import { useIntl } from 'react-intl';
import Edit from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

/* Styled components */

const Root = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    padding: 5px;
    gap: 2px;
    margin: 5px 0;
    border-radius: 8px;
`

const Name = styled.h4`
    grid-column: 1/3;
    font-weight: 400;
    color: ${props => props.theme.palette.details};
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: center;
`

const Description = styled.p`
    grid-column: 1/3;
    font-size: 0.65rem;
    font-weight: 400;
    margin: 5px 0;
    font-style: italic;
    padding: 0 5px;
    text-align: center;
`

const Title = styled.span`
    color: ${props => props.theme.palette.details};
    text-transform: uppercase;
    font-size: 0.65rem;
    font-weight: 400;
    text-align: center;
`

const TreatDate = styled.span`
    text-align: center;
    font-size: 0.65rem;
    font-weight: 400;
`

const EditIcon = styled.div`
    position: relative;
    grid-column: 1/3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 20px;
    cursor: pointer;
    z-index: 99;
    svg {
        font-size: 0.875rem;
    }
`

const Treatment = ({data, handleEdit}) => {

    const intl = useIntl();

    const handleEditTreatment = () => {
        handleEdit(data);
    }

    return (
        <Root>
            <EditIcon >
                <Tooltip title={intl.formatMessage({ id: 'editTreatment' })}>
                    <Edit onClick={handleEditTreatment} />
                </Tooltip>
            </EditIcon>
            <Name>{data.name}</Name>
            <Title>{translate("start")}</Title>
            <Title>{translate("end")}</Title>
            <TreatDate>{intl.formatDate(data.startDate, {year: "numeric",month: "numeric" ,day: "numeric" })}</TreatDate>
            <TreatDate>{intl.formatDate(data.endDate, {year: "numeric",month: "numeric" ,day: "numeric" })}</TreatDate>
            <Description dangerouslySetInnerHTML={{ __html: data.info }} />
            
        </Root>
    );
}

export default Treatment;