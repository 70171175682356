import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyIcon from '@mui/icons-material/Key';
import { PlayCircleFilledWhiteOutlined } from '@mui/icons-material';

export const THEMES = {
    default: {
        palette: {
            principal: "#00271C",
            base: "#FFFFFF",
            accent: "#873d90",
            details: "#707070",
            font: "#56876D",
            bgColor: "#56876D",
            secundary: "black",
        },
        icons: {
            view: <VisibilityIcon />,
            edit: <EditIcon />,
            delete: <DeleteForeverIcon />,
            password: <KeyIcon />,
            link: <PlayCircleFilledWhiteOutlined/>
        },
    }
}

