import {horsesConstants} from "./horsesConstants";

const initialState = {
    search: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case horsesConstants.CHANGE_SEARCH:
            return {
                search: action.payload
            };
        case horsesConstants.CLEAR_SEARCH:
            return {
                search: ""
            };
        default:
            return state
    }
};

export default reducer;