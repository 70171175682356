import React from "react";
import styled from "styled-components";
import {
  FilterGroup,
  Filters,
  SearchInput,
  Table,
} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from "axios";
import SwitchFilter from "../components/tables/table/filters/SwitchFilter";
import CheckFilter from "../components/tables/table/filters/CheckFilter";
import HorseCard from "../components/cards/HorseCard";
import { useNavigate } from "react-router-dom";
import { useIntl } from "react-intl";
import AddIcon from "@mui/icons-material/Add";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import Modal from "@mui/material/Modal";
import NewHorse from "../components/horse/NewHorse";
import { API_URL } from "../config";
import hasScope from "../providers/authentication/hasScope";
import { useAuth } from "../providers/authentication";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../store/action-creators";
import { Tooltip } from "@mui/material";
import NewBatchAppointment from "../components/appointment/NewBatchAppointment";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr;
  height: 100%;
  width: 100%;
  padding: 0;
  @media (min-width: 1024px) {
    padding: 0;
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  /* padding: 20px 10px; */
  border-radius: 10px;
  @media (min-width: 1024px) {
    /* padding: 20px; */
  }
`;

const NewIcon = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : "7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 50px;
  }
`;

const NewIcon1 = styled.div`
  position: fixed;
  right: 20px;
  bottom: 80px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : "7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 110px;
  }
`;

export default function HorsesPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();
  const { token } = useAuth();

  const navigate = useNavigate();

  const intl = useIntl();

  const dispatch = useDispatch();

  const { changeHorsesSearch } = bindActionCreators(actionCreators, dispatch);

  const { search } = useSelector((state) => state.horsesReducer);

  const [selectedView, setSelectedView] = React.useState("grid");

  const [openAppointment, setOpenAppointment] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenAppointment = () => setOpenAppointment(true);

  const handleOpen = () => setOpen(true);

  const handleCloseAppointment = () => {
    tableRef.current.clean();
    setOpenAppointment(false);
  };

  const handleClose = () => {
    tableRef.current.clean();
    setOpen(false);
  };
  const handleCloseEdit = () => {
    tableRef.current.clean();
    setOpenEdit(false);
  };

  const [editHorse, setEditHorse] = React.useState(null);

  const columnsTable = [
    {
      title: translate("name"),
      field: "name",
      width: "23%",
      order: true,
      hidden: false,
    },
    {
      title: translate("birthday_short"),
      field: "dateOfBirth",
      width: "10%",
      order: true,
      type: "date",
      hidden: false,
    },
    {
      title: translate("father"),
      field: "father.name",
      width: "20%",
      hidden: false,
      mobile: false,
    },
    {
      title: translate("mother"),
      field: "mother.name",
      width: "20%",
      hidden: false,
      mobile: false,
    },
    {
      title: translate("location"),
      field: "location.name",
      width: "20%",
      order: true,
      hidden: false,
      mobile: false,
    },
    {
      title: translate("stock"),
      field: "stock",
      width: "7%",
      order: true,
      hidden: false,
    },
    {
      title: translate("actions"),
      actions: true,
      width: "auto",
      hidden: false,
    },
  ];

  const filters = [
    {
      name: "status",
      label: intl.formatMessage({ id: "active" }),
      type: "switch",
    },
  ];

  const [selectedFilters, setSelectedFilters] = React.useState([
    {
      name: "status",
      values: ["true"],
      condition: "equal",
      type: "boolean",
    },
  ]);

  const changeView = (value) => {
    setSelectedView(value);
  };

  const onSearchChange = (value) => {
    changeHorsesSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const handleViewItem = (data) => {
    navigate(`/backoffice/horses/${data.id}`);
  };

  const handleNewAppointment = () => {
    handleOpenAppointment();
  };

  const handleNewItem = () => {
    handleOpen();
  };

  const handleEditItem = (data) => {
    setEditHorse(data);
    setOpenEdit(true);
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: "confirm delete" }))) {
      axios
        .delete(`${API_URL}/horses/${data.id}`)
        .then(() => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCheckFilter = (name, value) => {
    const olds = selectedFilters;
    const news = selectedFilters.filter((f) => f.name !== name);
    const filterChange = olds.find((f) => f.name === name);
    if (filterChange.values.includes(parseInt(value))) {
      filterChange["values"] = filterChange.values.filter(
        (val) => val !== parseInt(value)
      );
      news.push(filterChange);
      setSelectedFilters(news);
    } else {
      const uptArray = filterChange.values;
      uptArray.push(parseInt(value));
      filterChange["values"] = uptArray;
      news.push(filterChange);
      setSelectedFilters(news);
    }
  };

  const handleChangeSwitch = (name, value) => {
    const oldFilters = selectedFilters;
    const newFilters = selectedFilters.filter((f) => f.name !== name);
    const filterChange = oldFilters.find((f) => f.name === name);
    filterChange["values"] = [value];
    newFilters.push(filterChange);
    setSelectedFilters(newFilters);
  };

  const actionsTable = {
    view: {
      icon: theme.icons.view,
      tooltip: intl.formatMessage({ id: "view" }),
      onClick: handleViewItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    edit: {
      icon: theme.icons.edit,
      tooltip: intl.formatMessage({ id: "edit" }),
      onClick: handleEditItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: !hasScope(token, "editHorse"),
    },
    delete: {
      icon: theme.icons.delete,
      tooltip: intl.formatMessage({ id: "delete" }),
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: !hasScope(token, "deleteHorse"),
    },
  };

  const onApplyFilter = () => {
    console.log(selectedFilters);
    tableRef.current.clean();
  };

  const options = {
    exportButton: true,
    exportName: intl.formatMessage({ id: "horse_List" }),
    pagination: true,
    directExport: true,
  };

  return (
    <Root>
      <Content>
        <Table
          title={intl.formatMessage({ id: "horses" })}
          disableTotalTitle={true}
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable={selectedView}
          changeView={changeView}
          gridCard={(data) => (
            <HorseCard data={data} actionsTable={actionsTable} />
          )}
          options={options}
          data={(query) =>
            new Promise((resolve, _reject) => {
              const filterList = selectedFilters.filter(
                (fil) => fil.values.length > 0
              );
              axios
                .get(`${API_URL}/horses`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                    filter: JSON.stringify(filterList),
                  },
                })
                .then((horses) => {
                  resolve(horses.data);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
          dataExport={(query) =>
            new Promise((resolve, _reject) => {
              axios
                .get(`${API_URL}/export/allhorses`, { responseType: "blob" })
                .then((response) => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", query.exportName + ".xlsx"); //or any other extension
                  document.body.appendChild(link);
                  link.click();
                  resolve(link);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        >
          <SearchInput
            search={search}
            onChange={onSearchChange}
            onSearch={handleSearch}
          />
          <Filters action={onApplyFilter}>
            {filters &&
              filters.map((fil, index) => (
                <FilterGroup
                  key={`filter_group_${index}`}
                  title={fil.type === "switch" ? null : fil.label}
                >
                  {fil.type === "switch" ? (
                    <SwitchFilter
                      filter={fil}
                      value={
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .values[0]
                      }
                      onChange={handleChangeSwitch}
                    />
                  ) : (
                    <CheckFilter
                      filter={fil}
                      values={
                        selectedFilters.find((ft) => ft.name === fil.name)
                          .values
                      }
                      handleChange={handleCheckFilter}
                    />
                  )}
                </FilterGroup>
              ))}
          </Filters>
        </Table>

        <Tooltip title="Nueva cita masiva" placement="top">
          <NewIcon1
            color={theme.palette.base}
            bg={theme.palette.accent}
            onClick={handleNewAppointment}
          >
            <MonitorHeartOutlinedIcon />
          </NewIcon1>
        </Tooltip>

        <NewIcon
          color={theme.palette.base}
          bg={theme.palette.accent}
          onClick={handleNewItem}
        >
          <AddIcon />
        </NewIcon>

        <Modal open={open} onClose={handleClose}>
          <NewHorse handleClose={handleClose} />
        </Modal>
        <Modal open={openEdit} onClose={handleCloseEdit}>
          <NewHorse horseData={editHorse} handleClose={handleCloseEdit} />
        </Modal>

        <Modal open={openAppointment} onClose={handleCloseAppointment}>
          <NewBatchAppointment handleClose={handleCloseAppointment} />
        </Modal>
      </Content>
    </Root>
  );
}
