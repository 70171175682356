import React from 'react';
import styled from 'styled-components/macro';
import VermasButton from './vermasbutton';
import {mob, mobText, hsjdmDesk, hsjdmDeskText} from '../utilities'
import {Link} from 'react-router-dom';

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    width: auto;
    height: auto;
    @media(min-width: 1024px){
        padding: 0 ${hsjdmDesk(100)} 0 ${hsjdmDesk(100)};
    }
`
const ImageContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: ${mob(239)};
    img{
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    @media(min-width: 1024px){
        height: 70.3784570597vh;
    }
`
const ContentContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: ${mob(432-239)};
    padding: ${mob(17)} 0 ${mob(18)} 0;
    background-color: ${props => props.theme.palette.base};
    @media (min-width: 1024px){
        height: 29.6215429403vh;
        padding: ${hsjdmDesk(50)} 0 0 0;
        background-color: ${props => props.theme.palette.bgColor};
    }
`
const Title = styled.h1`
    all: unset;
    position: relative;
    box-sizing: border-box;
    width: auto;
    height: auto;
    font-family: helvetica;
    font-weight: bold;
    font-size: ${mobText(19)};
    line-height: ${mobText(22)};;
    text-transform: uppercase;
    color: ${props => props.theme.palette.base};
    margin-bottom: ${mob(6)};
    @media(min-width: 1024px){
        font-size: ${hsjdmDeskText(40)};
        line-height: ${hsjdmDeskText(45)};
        margin-bottom: ${hsjdmDesk(31)};
    }
`
const TextBox = styled.div`
    position: relative;
    box-sizing: border-box;
    width: ${mob(318)};
    height: ${mob(90)};
    font-family: helvetica;
    font-size: ${mobText(14)};;
    color: ${props => props.theme.palette.base};
    text-align: center;
    line-height: ${mobText(16)};;
    margin-bottom: ${mob(16)};
    @media(min-width: 1024px){
        width: ${hsjdmDesk(697)};
        height: ${hsjdmDesk(93)};
        font-size: ${hsjdmDeskText(21)};
        line-height: ${hsjdmDeskText(24)};
        font-weight: 100;
        margin-bottom: ${hsjdmDesk(21)};
    }
`

export default function HarasSJDM() {
  return (
        <Root>
            <ImageContainer><img src={require("../public/DJI_0024.jpeg")}/></ImageContainer>
            <ContentContainer>
                <Title>HARAS SAN JOSÉ DEL MORO</Title>
                <TextBox>Siguiendo una larga trayectoria familiar, promovemos la cría de caballos y la posterior preparación para el salto en pista, buscando impulsar su participación en competencias de máximo nivel mundial.</TextBox>
                <Link style={{all: "unset"}} to="nosotros"><VermasButton height="18" fontSize="9"></VermasButton></Link>
            </ContentContainer>
        </Root>
  )
}
