import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { API_URL } from '../../config';
import axios from 'axios';
import BasicEditor from '../textEditors/basicEditor';
import SelectInput from '../inputs/SelectInput';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: 100%;
    @media (min-width: 1024px) {
        width: ${props => props.width ? props.width : "inherit"};
    }
`

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyContent ? props.justifyContent : "space-between"};
    width: 100%;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`

const NewClassification = ({id, actualStatus, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress} = bindActionCreators(actionCreators, dispatch);

    const [ newClassification, setNewClassification ] = React.useState(
        {
            nextStatus: null,
            relatedHorseId: null,
            comment: '',
            stock: 0,
            dosis: 0,
            date: new Date()
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewClassification({
            ...newClassification,
            [name]: value
        });
    }

    const handleChangeAutocomplete = (name, value) => {
        setNewClassification({
            ...newClassification,
            [name]: value
        });
        console.log(value)
    }

    const handleEditorComment = (e, dataeditor) => {
        setNewClassification({
            ...newClassification,
            comment: dataeditor
        })
    }

    const handleChangeDate = (newValue) => {
        setNewClassification({
            ...newClassification,
            date: newValue
        });
    }


    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        var formdata = new FormData();
        formdata.append("actualStatus", actualStatus.id);
        formdata.append("nextStatus", newClassification.nextStatus?.value);
        formdata.append("horseId", id);
        formdata.append("relatedHorseId", newClassification.relatedHorseId?.value);
        formdata.append("stock", newClassification.stock);
        formdata.append("dosis", newClassification.dosis);
        formdata.append("comment", newClassification.comment);
        formdata.append("date", newClassification.date);
        const url = `${API_URL}/historyclassification/changestatus`;
        axios.post(url, formdata,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            },
            //Listen for the onuploadprogress event
            onUploadProgress: e => {
                const {loaded, total} = e;
                //Using local progress events
                if (e.lengthComputable) {
                    let progress = loaded / total * 100;
                    loadingProgress(loadingConstants.PROGRESS, progress);
                }
            }
        })
        .then(response => {
            loadingAction(loadingConstants.READY);
            handleClose();
        })
        .catch(error => {
            loadingAction(loadingConstants.READY);
            console.log(error);
        })
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

  var array = [0, 1, 3];

    return (
        <Root>
            <Title>{translate("new classification")}</Title>
            <SelectInput value={newClassification.nextStatus} fieldlabel="value" fieldvalue="id" fieldextra1="horseRequired" fieldextra2="stockRequired" dataurl={`${API_URL}/historyclassification/possibleNextStatus?horseId=${id}&actualClassification=${actualStatus.key}`} label={intl.formatMessage({ id: 'next classification' })} name="nextStatus" onChange={handleChangeAutocomplete} width="100%" margin="dense" size="small" required />
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    renderInput={(props) => <StyledTextfield size="small" margin="dense" fullWidth {...props} />}
                    label={intl.formatMessage({ id: 'date' })}
                    value={newClassification.date}
                    onChange={handleChangeDate}
                    required
                />
            </LocalizationProvider>
            {
                newClassification.nextStatus && newClassification.nextStatus.extra1 &&
                <SelectInput value={newClassification.relatedHorseId} fieldlabel="name" fieldvalue="id" dataurl={Array('10', '11').includes(newClassification.nextStatus.key) ? `${API_URL}/horses/sexcode/S` : Array('4').includes(newClassification.nextStatus.key) ? `${API_URL}/horses/sexcode/M` : `${API_URL}/horses/list`} label={Array('10', '11').includes(newClassification.nextStatus.key) ? intl.formatMessage({ id: 'father stallion' }) : Array('4').includes(newClassification.nextStatus.key) ? intl.formatMessage({ id: 'mother mare' }) : intl.formatMessage({ id: 'foal' })} name="relatedHorseId" onChange={handleChangeAutocomplete} width="100%" margin="dense" size="small" />
            }
            {
                newClassification.nextStatus && newClassification.nextStatus.extra2 &&
                <InputGroup>
                    <StyledTextfield id="stock" type="number" name="stock" onChange={handleChange} value={newClassification.stock} label={intl.formatMessage({ id: 'stock' })} variant="outlined" width="45%"  margin="dense" size="small"/>
                    <StyledTextfield id="dosis" type="number" name="dosis" onChange={handleChange} value={newClassification.dosis} label={intl.formatMessage({ id: 'dosis' })} variant="outlined" width="45%"  margin="dense" size="small"/>
                </InputGroup>
            }
            <BasicEditor
                name="comment"
                value={newClassification.comment}
                onChange={handleEditorComment}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: 'insertComment' })}
            />
            <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
}

export default NewClassification;
