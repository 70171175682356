import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from "../../../../providers/theme";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

const Root = styled.div`
    position: relative;
    z-index: 99;
`

const StyledIcon = styled(FilterAltIcon)`
    cursor: pointer;
    margin: 0 10px;
    color: ${(props) => props.hide ? props.color ? props.color : '#000' : props.selectedColor ? props.selectedColor : '#000'};
`

const FilterList = styled.div`
    position: absolute;
    top: 100%;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: #f1f1f1;
    width: 250px;
    z-index: 9;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    border-radius: 8px 0 8px 8px;
    transition: all 300ms ease-in-out;
    padding: ${(props) => props.hide ? 0 : "5px"};
    height: ${(props) => props.hide ? 0 : "auto"};
    overflow-y: ${(props) => props.hide ? "hidden" : "auto"};
    opacity: ${(props) => props.hide ? 0 : 1};
    @media (min-width: 600px) {
        width: 600px;
    }
`
const ButtonRow = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const CloseButton = styled(CloseIcon)`
    margin: 0 auto 0 5px;
    cursor: pointer;
`

const Button = styled.button`
    grid-column: 1/3;
    align-self: flex-end;
    color: ${(props) => props.color ? props.color : "blue"};
    background: ${(props) => props.background ? props.background : "blue"};
    border: none;
    outline: none;
    border-radius: 8px;
    margin: 5px 10px 5px 0;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    justify-self: flex-end;
    font-size: 1rem;
`

const ContentList = styled.div`
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 70vh;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    @media (min-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }
`

const Filters = ({children, action, clear}) => {

    const theme = React.useContext(ThemeContext);

    const [ hide, setHide ] = React.useState(true);

    const handleHide = (e) => {
        e.preventDefault();
        setHide(!hide);
    }

    const handleAction = (e) => {
        e.preventDefault();
        action();
        setHide(true);
    }

    const handleClear = (e) => {
        e.preventDefault();
        clear();
    }

    return (
        <Root>
            <StyledIcon hide={hide} onClick={handleHide} color={theme.palette.principal} selectedColor={theme.palette.accent} />
            <FilterList hide={hide}>
                <ButtonRow>
                    <CloseButton onClick={handleHide}/>
                    <Button color={theme.palette.base} background={theme.palette.principal} onClick={handleClear}>Limpiar</Button>
                    <Button color={theme.palette.base} background={theme.palette.principal} onClick={handleAction}>Aplicar</Button>
                </ButtonRow>
                <ContentList>
                    {children}
                </ContentList>
            </FilterList>
        </Root>
    );
}

export default Filters;