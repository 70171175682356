import React from "react";
import styled from 'styled-components';
import AccordionRow from "../../../tables/table/AccordionRow";
import HeadAccordion from "../../../tables/table/HeadAccordion";
import ContentAccordion from "../../../tables/table/ContentAccordion";
import { useIntl } from "react-intl";
import { Modal } from "@mui/material";
import NewTreatment from "./NewTreatment";
import Treatment from "../../../clinichistory/Treatment";

const Title = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
`

const Date = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 10px 0 0;
`

const Name = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const EditIcon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 25px;
    cursor: pointer;
    svg {
        font-size: 1.25rem;
    }
`

const TreatmentAccordion = ({data, refresh}) => {

    const intl = useIntl();

    const [openEditTreatment, setOpenEditTreatment] = React.useState(false);

    const [ editTreatment, setEditTreatment ] = React.useState(null);

    const handleCloseEditTreatment = () => {
        refresh();
        setOpenEditTreatment(false);   
    }

    const handleEditTreatment = (data) => {
        setEditTreatment(data);
        setOpenEditTreatment(true);
    }

    return (
        <React.Fragment>
        <AccordionRow>
            <HeadAccordion>
                <Title>
                    <Date>{intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" })}</Date>
                    <Name>{data.name}</Name>
                </Title>
            </HeadAccordion>
            <ContentAccordion>
                <Treatment data={data} handleEdit={handleEditTreatment} />
            </ContentAccordion>
        </AccordionRow>
        <Modal
            open={openEditTreatment}
            onClose={handleCloseEditTreatment}
        >
            <NewTreatment diagnosisId={data.diagnosisId} data={editTreatment} handleClose={handleCloseEditTreatment} />
        </Modal>
        </React.Fragment>
    )

}

export default TreatmentAccordion;