import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {Table} from "../tables/table";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import axios from 'axios';
import { useIntl } from 'react-intl';
import { API_URL } from "../../config";
import { useParams } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';
import BoxNumber from "./BoxNumber";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50px 50px 400px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    gap: 10px;
    @media (min-width: 1024px) {
        flex-direction: inherit;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 50px 300px;
    }
`

const Box = styled.div`
    position: relative;
    display: flex;
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    height: 100%;
    width: 100%;
    grid-column: ${props => props.gridColumnMobile ? props.gridColumnMobile : "inherit"};
    grid-row: ${props => props.gridRow ? props.gridRow : "inherit"};
    border-radius: 10px;
    background-color: #FFF;
    padding: ${props => props.padding ? props.padding : "20px"};
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    @media (min-width: 1024px) {
        grid-column: ${props => props.gridColumn ? props.gridColumn : "inherit"};
    }
`

const RelatedHorse = styled.div`
    svg {
        color: #D1AC00;
        font-size: 0.85rem;
        margin: 0 0 0 5px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    }
    svg:hover {
        transform: scale(1.1);
    }
`

const Classification = styled.div`
    color: ${props => props.color ? props.color : "inherit"};
    svg {
        color: #D1AC00;
        font-size: 0.85rem;
        margin: 0 0 0 5px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    }
    svg:hover {
        transform: scale(1.1);
    }
`

const Loading = styled(CircularProgress)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`


const PregnantMares = ({ gridColumn }) => {

    const theme = React.useContext(ThemeContext);

    const { id } = useParams();

    const tableRef = React.useRef();

    const intl = useIntl();

    const ESTIMATED_BIRTHDAY_DATES = 336;

    const INSEMINATED_MARE = 10;

    const TRANSFERRED_MARE = 11;

    const MOTHER_MARE = 3;

    const RECIPIENT_MARE = 4;

    const PREGNANT_MARE = 5;

    const FOAL_AT_FOOT = 6;

    const [ dataset, setDataset ] = React.useState(null);

    const [ originalData, setOriginalData ]  = React.useState(null);

    const [ pregnantMares, setpregnantMares ] = React.useState(null);

    const [ inseminatedMares, setinseminatedMares ] = React.useState(null);

    const [ transferredMares, settransferredMares ] = React.useState(null);

    const [ foalAtFootMares, setFoalAtFootMares ] = React.useState(null);

    const [ activeList, setActiveList ] = React.useState(null);

    const allActiveList = [INSEMINATED_MARE, TRANSFERRED_MARE, PREGNANT_MARE, FOAL_AT_FOOT];

    const addToActiveList = async (id) => {
        const newList = allActiveList.filter(al => id.includes(parseInt(al)));
        setActiveList(state => newList);
        await updateData(newList);
        refresh();
    }

    // const addToActiveList = async (id) => {
    //     console.log(id);
    //     setActiveList(state => [...state, ...id]);
    //     await updateData([...activeList, ...id]);
    //     refresh();
    // }

    const compareHorses = (horseA, horseB) => {
        if(!horseA){
            return -1;
        }
        if(!horseB){
            return -1;
        }
        if(horseA && horseB){
            const iwfA = horseA.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
            const iwfB = horseB.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
            return iwfA.length > 0 ? iwfB.length > 0 ? new Date(iwfA[0].date) > new Date(iwfB[0].date) ? -1 : 1 : -1 : 1;
        }
        return 1;
    }

    const columnsTable = [
        { title: translate("pregnant mares"), field: 'name',width: "15%", hidden: false, render: (data) =>
            <RelatedHorse>
                {data.name}
                <Link to={`/backoffice/horses/${data.id}`} target="_blank"><OpenInNewIcon /></Link>
            </RelatedHorse>
        },
        { title: translate("mother mare"), field: 'lastCycle', width: "15%", mobile: false,  hidden: false, render: (data) => {

            const mm = data.lastCycle.filter(m => parseInt(m.clasificationName.key) === RECIPIENT_MARE);
            return <RelatedHorse>
                        {mm.length > 0 ? mm[0].idRelatedHorse?.name : data.name}
                        <Link to={`/backoffice/horses/${mm.length > 0 ? mm[0].idRelatedHorse?.id : data.id}`} target="_blank"><OpenInNewIcon /></Link>
                    </RelatedHorse>
        } },
        { title: translate("inseminated with father"), field: 'name', width: "15%", mobile: false,  hidden: false, render: (data) => {
            const iwf = data.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
            return <RelatedHorse>
                        {iwf.length > 0 ? iwf[0].idRelatedHorse?.name : "---"}
                        {
                            iwf.length > 0 &&
                            <Link to={`/backoffice/horses/${iwf[0].idRelatedHorse?.id}`} target="_blank"><OpenInNewIcon /></Link>
                        }
                    </RelatedHorse>
        } },
        { title: translate("location"), field: 'location.name', mobile: false, width: "15%",  hidden: false },
        { title: translate("classification"), field: 'lastCycle', mobile: true, width: "20%",  hidden: false, render: (data) => {
            return <Classification color={parseInt(data.lastCycle[0].clasificationName.key) === PREGNANT_MARE ? theme.palette.accent : parseInt(data.lastCycle[0].clasificationName.key) === FOAL_AT_FOOT ? "#0c795b" : "inherit"} >
                        {data.lastCycle && data.lastCycle.length > 0 ? data.lastCycle[0].clasificationName.value : "---"}
                        {
                            data.lastCycle && data.lastCycle.length > 0 && parseInt(data.lastCycle[0].clasificationName.key) === FOAL_AT_FOOT &&
                            <Link to={`/backoffice/horses/${data.lastCycle[0].idRelatedHorse?.id}`} target="_blank"><OpenInNewIcon /></Link>
                        }
                    </Classification>
        } },
        { title: translate("insemination date"), field: 'dateOfBirth', type: "date", mobile: false, width: "10%",  hidden: false, render: (data) => {
            const iwf = data.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
            return <span>{iwf.length > 0 ? intl.formatDate(iwf[0].date, {year: "numeric",month: "numeric" ,day: "numeric" }) : "---"}</span>
        } },
        { title: translate("estimated birthday"), field: 'dateOfBirth', type: "date", mobile: false, width: "10%",  hidden: false, render: (data) => {
            if(data.lastCycle && data.lastCycle.length > 0 && parseInt(data.lastCycle[0].clasificationName.key) === FOAL_AT_FOOT){
                return <span>{intl.formatDate(new Date(data.lastCycle[0].idRelatedHorse.dateOfBirth), {year: "numeric",month: "numeric" ,day: "numeric" })}</span>
            } else {
                const iwf = data.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
                const tempDate = iwf && iwf.length > 0 ? new Date(iwf[0].date) : new Date();
                return <span>{iwf && iwf.length > 0 ? intl.formatDate(tempDate.setDate(tempDate.getDate() + ESTIMATED_BIRTHDAY_DATES), {year: "numeric",month: "numeric" ,day: "numeric" }) : "---"}</span>
            }
        } },
        { title: translate("actions"), actions: true, mobile: false, width: "auto", hidden: true, hiddenMobile: true }
    ]

    const refresh = () => {
        tableRef.current.clean();
    }

    const optionsTable = {
        pagination: false,
        exportButton: true,
        directExport: true,
        exportName: intl.formatMessage({ id: 'pregnant_mares' })
    }

    const actionsTable={}

    const isPregnantMare = (horseData) => {
        const pm = horseData.filter(h => parseInt(h.clasificationName.key) === MOTHER_MARE || parseInt(h.clasificationName.key) === RECIPIENT_MARE || parseInt(h.clasificationName.key) === INSEMINATED_MARE || parseInt(h.clasificationName.key) === TRANSFERRED_MARE || parseInt(h.clasificationName.key) === PREGNANT_MARE || parseInt(h.clasificationName.key) === FOAL_AT_FOOT);
        return pm.length > 0;
    }

    const updateData = async (list) => {
        const filterList = originalData.filter(h => list.includes(parseInt(h.lastCycle[0].clasificationName.key)))
        setDataset(filterList);
    }

    const getData = () => {
        axios.get(`${API_URL}/horses/getLastCycle`)
            .then(result => {
                let pm = 0;
                let im = 0;
                let tm = 0;
                let faf = 0;
                const horsesList = result.data.rows.filter(h => isPregnantMare(h.lastCycle))
                horsesList.forEach(element => {
                    if(parseInt(element.lastCycle[0].clasificationName.key) === PREGNANT_MARE){
                        pm++;
                    }
                    if(parseInt(element.lastCycle[0].clasificationName.key) === INSEMINATED_MARE){
                        im++;
                    }
                    if(parseInt(element.lastCycle[0].clasificationName.key) === TRANSFERRED_MARE){
                        tm++;
                    }
                    if(parseInt(element.lastCycle[0].clasificationName.key) === FOAL_AT_FOOT){
                        faf++;
                    }
                });
                setpregnantMares(pm);
                setinseminatedMares(im);
                settransferredMares(tm);
                setFoalAtFootMares(faf);
                const sortedHorseList = horsesList.sort(compareHorses);
                console.log(sortedHorseList)
                setDataset(sortedHorseList);
                setOriginalData(sortedHorseList)
            })
            .catch(err => {
                console.log("ERROR QUERY TABLA PREGNANT")
                console.log(err);
            })
    }

    // const exportData = (query) => new Promise((resolve, reject) => {
    //     const horsesExport = [];
    //     originalData.forEach(element => {
    //         const mm = element.lastCycle.filter(m => parseInt(m.clasificationName.key) === RECIPIENT_MARE);
    //         const iwf = element.lastCycle.filter(f => parseInt(f.clasificationName.key) === INSEMINATED_MARE || parseInt(f.clasificationName.key) === TRANSFERRED_MARE);
    //         const tempDate = iwf && iwf.length > 0 ? new Date(iwf[0].date) : new Date();
    //         horsesExport.push({
    //             [`${intl.formatMessage({ id: 'name' })}`]: element.name,
    //             [`${intl.formatMessage({ id: 'mother mare' })}`]: mm.length > 0 ? mm[0].idRelatedHorse?.name : element.name,
    //             [`${intl.formatMessage({ id: 'inseminated with father' })}`]: iwf.length > 0 ? iwf[0].idRelatedHorse?.name : "---",
    //             [`${intl.formatMessage({ id: 'location' })}`]: element.location.name,
    //             [`${intl.formatMessage({ id: 'classification' })}`]: element.lastCycle && element.lastCycle.length > 0 ? element.lastCycle[0].clasificationName.value : "---",
    //             [`${intl.formatMessage({ id: 'insemination date' })}`]: iwf.length > 0 ? intl.formatDate(iwf[0].date, {year: "numeric",month: "numeric" ,day: "numeric" }) : "---",
    //             [`${intl.formatMessage({ id: 'estimated birthday' })}`]: iwf && iwf.length > 0 ? intl.formatDate(tempDate.setDate(tempDate.getDate() + ESTIMATED_BIRTHDAY_DATES), {year: "numeric",month: "numeric" ,day: "numeric" }) : "---"
    //         })
    //     });
    //     resolve(horsesExport);
    // })

    const exportData = (query) => new Promise((resolve, reject) => {
        axios.get(`${API_URL}/export/mareActivity`,{
            responseType: 'blob'
        })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', query.exportName+'.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {
            console.log(err);
        })
    })


    React.useEffect(() => {
        if(!activeList){
            setActiveList([INSEMINATED_MARE, TRANSFERRED_MARE, PREGNANT_MARE, FOAL_AT_FOOT])
        }
        if(!dataset){
            getData()
        }
    }, [activeList])

    return (
        <Root gridColumn={gridColumn}>
          { /* <Box padding="0">
            {
                    inseminatedMares || transferredMares || pregnantMares || inseminatedMares + transferredMares + pregnantMares === 0 ?
            <BoxNumber disabled  title={intl.formatMessage({ id: 'total' })} number={inseminatedMares + transferredMares + pregnantMares + foalAtFootMares} color="#674ea7" />
                      :
                      <CircularProgress color="inherit" />
                      }
          </Box>*/}
            <Box padding="0">
                {
                    inseminatedMares || transferredMares || pregnantMares || foalAtFootMares || (inseminatedMares === 0 && transferredMares === 0 && pregnantMares === 0 && foalAtFootMares === 0) ?
                    <BoxNumber activeList={activeList} id={[INSEMINATED_MARE, TRANSFERRED_MARE, PREGNANT_MARE, FOAL_AT_FOOT]}  add={addToActiveList}  title={intl.formatMessage({ id: 'total' })} number={inseminatedMares + transferredMares + pregnantMares + foalAtFootMares} />
                    :
                    <CircularProgress color="inherit" />
                }
            </Box>
            <Box padding="0">
                {
                      (inseminatedMares || transferredMares) || (inseminatedMares === 0 && transferredMares === 0)  ?
                    <BoxNumber activeList={activeList} id={[INSEMINATED_MARE, TRANSFERRED_MARE]}  add={addToActiveList}  title={intl.formatMessage({ id: 'served' })} number={inseminatedMares + transferredMares} />
                    :
                    <CircularProgress color="inherit" />
                }
            </Box>
            <Box padding="0">
                {
                    pregnantMares || pregnantMares === 0 ?
                    <BoxNumber activeList={activeList} id={[PREGNANT_MARE]} add={addToActiveList}  title={intl.formatMessage({ id: 'pregnant' })} number={pregnantMares} color={theme.palette.accent} />
                    :
                    <CircularProgress color="inherit" />
                }
            </Box>
            <Box padding="0">
                {
                    foalAtFootMares || foalAtFootMares === 0 ?
                    <BoxNumber activeList={activeList} id={[FOAL_AT_FOOT]} add={addToActiveList}  title={intl.formatMessage({ id: 'foalAtFoot' })} number={foalAtFootMares} color="#0c795b" />
                    :
                    <CircularProgress color="inherit" />
                }
            </Box>
            <Box gridColumn="1/5" gridColumnMobile="1/3">
                {
                    activeList && dataset ?
                    <Table
                        ref={tableRef}
                        columns={columnsTable}
                        actionsTable={actionsTable}
                        viewTable="list"
                        options={optionsTable}
                        disabledPlain={true}
                        disablePagination={true}
                        directData={dataset}
                        dataExport={exportData}
                    >
                    </Table>
                    :
                    <Loading color="inherit" />
                }
            </Box>
        </Root>
    );
}

export default PregnantMares;
