import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import styled from 'styled-components';

const Content = styled.div`
    position: relative;
    display: flex;
    padding: 20px;
    @media (min-width: 1024px) {
        padding: 30px;
    }
`


const Modal = ({children}) => {
    let navigate = useNavigate();
  
    const handleClose = () => {
      navigate(-1);
    }
  
    return (
      <Dialog
        aria-labelledby="label"
        open={true}
        onClose={handleClose}
      >
          <Content>
              {children}
          </Content>
      </Dialog>
    );
  }

  export default Modal;