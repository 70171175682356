import React, {useContext} from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import AdminNav from "../navbars/adminnav";
import { ThemeContext } from "../../providers/theme";

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: ${props => props.bg ? props.bg : "#EAE7E7"};
    @media (min-width: 1024px) {
        display: grid;
        flex-direction: inherit;
        grid-template-columns: min-content 1fr;    
        grid-template-rows: inherit;
    }
`

const Content = styled.div`
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    padding: 50px 10px 20px 10px;
    box-sizing: border-box;
    @media (min-width: 1024px) {
        padding: 20px 0 0 0;
        height: 100%;
        overflow-y: auto;
        grid-template-rows: 1fr;
    }
`

const Header = styled.header`
    display: flex;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.bg ? props.bg : "#FFF"};
`

const Body = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @media (min-width: 1024px) {
        ::-webkit-scrollbar {
            display: none;
        }
    }
`

const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background-color: ${(props) => props.bg ? props.bg : "#FFF"};
    a {
        cursor: pointer;
        text-decoration: none;
        color: ${(props) => props.color ? props.color : "#FFF"};
    }
`

const WebLayout = () => {

    const theme = useContext(ThemeContext);

    const [ openNav, setOpenNav ] = React.useState(false);

    const onSwitchNav = () => {
        setOpenNav(!openNav);
    }

    const closeNav = () => {
        setOpenNav(false);
    }

    return (
        <Root bg={theme.palette.base}>
            <AdminNav openNav={openNav} onSwitchNav={onSwitchNav} closeNav={closeNav} />
            <Content>
                {/* <Header bg={theme.palette.principal}>
                    
                </Header> */}
                <Body>
                    <Outlet />
                </Body>
                {/* <Footer bg={theme.palette.principal} color={theme.palette.base}>
                    <a href="https://www.molemedia.co/" target="_blank">Powered by Mole</a>
                </Footer> */}
            </Content>
        </Root>
    );
}

export default WebLayout;