import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from '../inputs/SelectInput';
import { API_URL } from '../../config';
import axios from 'axios';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const NewLocation = ({locationData, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress} = bindActionCreators(actionCreators, dispatch);

    const [ newLocation, setNewLocation ] = React.useState(locationData ? 
        {
            name: locationData.name,
            address: locationData.address,
            phoneNumber: locationData.phoneNumber,
            personCharge: locationData.personCharge ? 
            {
                label: locationData.personCharge.fullName,
                value: locationData.personCharge.id
            } : null
        }
        :
        {
            name: '',
            address: '',
            phoneNumber: '',
            personCharge: null
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLocation({
            ...newLocation,
            [name]: value
        });
    }

    const handleChangeAutocomplete = (name, value) => {
        setNewLocation({
            ...newLocation,
            [name]: value
        });
    }


    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        var formdata = new FormData();
        formdata.append("name", newLocation.name);
        formdata.append("address", newLocation.address);
        formdata.append("phoneNumber", newLocation.phoneNumber);
        formdata.append("personCharge", newLocation.personCharge?.value);
        if(locationData){
            const url = `${API_URL}/locations/${locationData.id}`;
            axios.put(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful editing' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                console.log(error);
            })
        } else {
            const url = `${API_URL}/locations`;
            axios.post(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful creation' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                console.log(error);
            })
        }
    }

    return (
        <Root onSubmit={handleAction}>
            <Title>{locationData ? translate("edit location") : translate("new location")}</Title>
            <TextField  id="name" name="name" onChange={handleChange} value={newLocation.name} label={intl.formatMessage({ id: 'name' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <TextField  id="address" name="address" onChange={handleChange} value={newLocation.address} label={intl.formatMessage({ id: 'address' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <TextField  id="phoneNumber" name="phoneNumber" onChange={handleChange} value={newLocation.phoneNumber} label={intl.formatMessage({ id: 'phone' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <SelectInput  value={newLocation.personCharge} fieldlabel="fullName" fieldvalue="id" dataurl={`${API_URL}/human/all`} label={intl.formatMessage({ id: 'personCharge' })} name="personCharge" onChange={handleChangeAutocomplete} width="100%" margin="dense" size="small" />
            <Button bg={theme.palette.accent} color={theme.palette.base}><CheckIcon /></Button>
        </Root>
    );
}

export default NewLocation;