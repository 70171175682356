import React from "react";
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    cursor: ${props => props.disabled ? "inherit" : "pointer"};
    user-select: none;
    @media (min-width: 1024px) {
        flex-direction: row;
        background-color: ${props => props.active ? "inherit" : "rgba(0,0,0,0.5)"};;
        opacity: ${props => props.active ? 1 : 0.75};
    }
`

const Title = styled.h2`
    font-size: 1rem;
    color: ${props => props.active ? props.color ? props.color : "inherit" : "inherit"};
    @media (min-width: 1024px) {
        font-size: 1.25rem;
    }
`

const Number = styled.span`
    font-size: 1.25rem;
    color: ${props => props.active ? props.color ? props.color : "inherit" : "inherit"};
    font-weight: 700;
    @media (min-width: 1024px) {
        margin-left: 5px;
    }
`

const BoxNumber = ({id, add, remove, title, number, color, disabled, activeList}) => {


  const compareArrays = (arr1, arr2) => {
    if (arr2.length > 1) {
      let i = 0;
      let contained = true;
      while (i < arr2.length && contained){
        if (!arr1.includes(parseInt(arr2[i]))) {contained = false};
        i = ++i;
      }
      return(contained);
    }
    else{
      return(arr1.includes(parseInt(arr2)));
    }
  }

    const active = activeList && compareArrays(activeList, id) ? true : false;

    const onChange = () => {
        if(id){
                add(id);
        }
    }

    return (
        <Root active={active} disabled={disabled} onClick={onChange} >
            <Title active={active} color={color}>{`${title}:`}</Title>
            <Number active={active} color={color}>{number}</Number>
        </Root>
    );
}

export default BoxNumber;
