import React, {useState} from 'react';
import styled from 'styled-components/macro';
import {mob, mobText, desk, deskText} from '../utilities';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
  
const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: ${mob(62)} ${mob(30)} ${mob(21)} ${mob(30)};
    background-color: ${props => props.theme.palette.base};
    @media (min-width: 1024px){
        padding: 0 ${desk(100)} ${desk(95.2)} ${desk(100)};   
    }
`
const Title = styled.span`
    position: relative;
    font-family: helvetica;
    font-weight: 100;
    font-size: ${mobText(16)};
    line-height: ${mobText(21)};
    color: ${props => props.theme.palette.font};
    margin-bottom: ${mob(14)};
    @media(min-width: 1024px){
        display: none;
    }
`
const ContentContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 0px;
    gap: ${mob(9)};
    width: 100%;
    height: auto;
    @media(min-width: 1024px){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: ${desk(524)};
        gap: ${desk(40)};
    }
`
const ImageBox = styled.div`
    position: relative;
    width: ${props => (props.id % 3) != 2  ? mob((428-60-9)/2) : '100%' };
    height: ${props => (props.id % 3) != 2  ? mob((428-60-9)/2) : mob(428-60) };
    background-image: url(${props => props.url});
    background-position: center;
    background-size: cover;
    @media(min-width: 1024px){
        /* width: ${props => ((props.id % 12) != 3) && ((props.id % 12) != 8) ? desk(537) : desk(1117) };
        height: ${props => ((props.id % 12) != 3) && ((props.id % 12) != 8) ? desk(537) : desk(1117) }; */
        width: auto;
        height: auto;
        grid-column: ${props => ((props.id % 12) != 3) && ((props.id % 12) != 7) ? "" : "span 2"}; 
        grid-row: ${props => ((props.id % 12) != 3) && ((props.id % 12) != 7) ? "" : "span 2"}; 
    }
`
const Back = styled.div`
    position: relative;
    color: ${props => props.theme.palette.accent};
    width: ${mob(30)};
    height: ${mob(30)};
    margin-top: ${mob(35)};
    svg{
        width: 100%;
        height: 100%;
    }
    @media(min-width: 1024px){
        width: ${desk(90)};
        height: ${desk(90)};
        margin-top: ${desk(203)};
    }
`
export default function Media() {

    const [img, setImg] = useState(null); 

    React.useEffect(() => {
        if (!img) {
            fetch('https://jsonplaceholder.typicode.com/photos')  
                .then((response) => response.json())
                .then((json) => {
                    setImg(json);
                });

        }
    }, [img])

  return (
        <Root>
            <Title>MEDIA</Title>
            <ContentContainer>
                {img && img.map((d, idx)=>{
                    if (idx < 12){
                        return(
                            <ImageBox key={idx} id={idx} url={d.url}></ImageBox>
                        )
                    }
                })}
            </ContentContainer>
            <Back><ArrowCircleLeftOutlinedIcon/></Back>
        </Root>
  )
}
