import { decodeToken } from "../../hooks/jwt";
  
const hasScope = (token, scope) => {

    const SCOPE_SEPARATOR = " ";

    const decodedToken = decodeToken(token);

    if(decodedToken && decodedToken.scopes.split(SCOPE_SEPARATOR).includes("root")){
        return true;
    }
    return decodedToken ? decodedToken.scopes.split(SCOPE_SEPARATOR).includes(scope) : false;
};

export default hasScope;