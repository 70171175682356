import React, {useContext} from 'react';
import styled from 'styled-components/macro';
import {mob, mobText, ppDesk, ppDeskText} from '../utilities'
import VermasButton from './vermasbutton';
import Circle from './Circle';
import { ThemeContext } from "../providers/theme";
import { Link } from "react-router-dom";

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: helvetica;
    color: ${props => props.theme.palette.font};
    background-color: ${props => props.theme.palette.base};
    @media(min-width: 1024px) {
        padding: ${ppDesk(71)} ${ppDesk(100)} ${ppDesk(82)} ${ppDesk(100)};
    }
`
const BoxContainer = styled.div`
   position: relative;
   box-sizing : border-box;
   display: flex;
   width: 100%;
   height: auto;
   @media(min-width: 1024px){
    justify-content: space-between;
   }
`
const Box = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    @media(min-width: 1024px){   
        width: auto;
        height: auto;
        justify-content: space-between;
    }
`
const Space = styled.div`
    display: none;
    height: ${ppDesk(75)};
    @media (min-width: 1024px){
        display: block;
    }
`
const CardsContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (min-width: 1024px){
        display: flex;
        height: auto;
        width: ${ppDesk(2*379.37+61.27)};
        row-gap: 10%;
    }
`
const Card = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(min-width: 1024px){
        width: ${ppDesk(379.37)};
        height: ${ppDesk(172.97+24.45+128.36)};
    }
`
const CardCircleContainer = styled.div`
    @media(min-width: 1024px){
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: ${ppDesk(175.28)};
        height: ${ppDesk(175.28)};
    }
`
const CardText = styled.span`
    position: relative;
    font-size: ${ppDeskText(20)};
    line-height: ${ppDeskText(23)};
    text-align: center;
    margin-top: ${ppDesk(18)};
`
const TextContainer = styled.span`
    display: none;
    position: relative;
    font-size: ${ppDeskText(20)};
    line-height: ${ppDeskText(23)};
    text-align: center;
    width: ${ppDesk(820)};
    @media (min-width: 1024px){
        display: block;
    }
`
const Img1 = styled.div`
    @media(min-width: 1024px){
        width: ${ppDesk(820)};
        height: ${ppDesk(569)};
    background-image: url(${require('../public/primerosPasosImg2.webp')});
    background-position: center;
    background-size: cover;
    }
`
const LinksImgSection = styled.div`
    position: relative;
    width: auto;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${mob(294)} ${mob(325-294)} auto;
    @media(min-width: 1024px){
        width: 100%;
        grid-template-columns: ${ppDesk(960-100)} ${ppDesk(900-(960-100))} auto;
        grid-template-rows: ${ppDesk(696)};
    }
`
const Img2 = styled.div`
    position: relative;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    height: 100%;
    width: 100%;
    background-image: url(${require('../public/cydbg.jpeg')});
    background-position: center;
    background-size: cover;
    @media(min-width: 1024px){
        grid-column: 1 / 3;
        grid-row: 1 / 2;
    }
`
const Links = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex: 1 1 0px;
    height: auto;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    z-index: 1;
    width: 100%;
    padding: 0 ${mob(34.5)} ${mob(34)} ${mob(34.5)};
    @media(min-width: 1024px){
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        flex-direction: column; 
        padding: ${ppDesk(160-75)} 0 ${ppDesk(152-82)} 0;
        justify-content: space-between;
    }
`
const LinkCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    @media(min-width: 1024px){
        height: auto;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`
const LinkCircleContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${mob(64.83)};
    height: ${mob(64.83)}; 
    margin-bottom: ${mob(7.17)};
    div > div{
        box-shadow: 0px 3px 6px #00000029;
    }
    @media (min-width: 1024px){
        width: ${ppDesk(131)};
        height: ${ppDesk(131)};
        margin: 0;
    }
`
const LinkCardContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: auto;
    height: auto;
    @media (min-width: 1024px){
        margin: 0 ${ppDesk(57)} 0 ${ppDesk(33)};
    }
`
const LinkCardTitle = styled.div`
    position: relative;
    width: auto;
    height: auto;
    font-weight: bold;
    font-size: ${mobText(14)};
    margin-bottom: ${mob(12.01)};
    @media (min-width: 1024px){
        font-size: ${ppDeskText(22)};
        line-height: ${ppDeskText(26)};
        margin-bottom: ${ppDesk(9)};
    }
`
const LinkCardText = styled.div`
    position: relative;
    display: none;
    @media (min-width: 1024px){
        display: block;
        font-size: ${ppDeskText(20)};
        line-height: ${ppDeskText(23)};
        width: ${ppDesk(501)};
    }
`
const LinkCardButton = styled.div`
    position: relative;
    width: auto;
    height: auto;
`
const TextButtonSection = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: ${mob(21)} ${mob(67)} ${mob(33)} ${mob(30)};
    @media(min-width: 1024px){
        padding: 0 0 ${ppDesk(53)} 0;
    }
`
const TextSection = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`
const TitleBox = styled.h1`
    position: relative;
    all: unset;
    font-family: helvetica;
    font-weight: bold;
    font-size: ${mobText(18)};
    line-height: ${mobText(18)};
    margin-bottom: ${mob(11)};
    @media(min-width: 1024px){
        font-size: ${ppDeskText(48)};
        line-height: ${ppDeskText(55)};
    }
`
const TextBox = styled.p`
    all: unset;
    position: relative;
    height: auto;
    width: ${mob(212)};
    font-family: helvetica;
    font-size: ${mobText(14)};
    text-align: left;
    @media(min-width: 1024px){
        width: ${ppDesk(660)};
        font-size: ${ppDeskText(24)};
        line-height: ${ppDeskText(28)};
    }
`
const VermasButtonContainer = styled.div`
    position: relative;
    margin-left: ${mob(38)};
    margin-top: ${mob(20)};
    @media(min-width: 1024px){
        display: none;
    }
`

export default function PrimerosPasos() {

  const theme = useContext(ThemeContext); 

  return (
        <Root>
                <BoxContainer>
                    <Box>
                        <CardsContainer>
                            <Card>
                                <CardCircleContainer>
                                    <Circle color={theme.palette.bgColor} type="dna"/>
                                </CardCircleContainer>
                                <CardText>La tarea inicial del Haras, es hacer una selección genética de las mejores madres, considerado que se han destacado en las grandes competencias, o que han dado productos con éstas características.</CardText>
                            </Card>
                            <Card>
                                <CardCircleContainer>
                                    <Circle color={theme.palette.bgColor} type="horse"/>
                                </CardCircleContainer>
                                <CardText>Una vez que la madre o la portadora está preñada, se la cuida con alimentación natural, con suplementos y protocolos de sanidad según indique el profesional responsable de la cría.</CardText>
                            </Card>
                            <Card>
                                <CardCircleContainer>
                                    <Circle color={theme.palette.bgColor} type="eye"/>
                                </CardCircleContainer>
                                <CardText>Cuando está por parir se la aísla en un pequeño piquete y se la observa 24 horas al día por la eventual asistencia en el parto.</CardText>
                            </Card>
                            <Card>
                                <CardCircleContainer>
                                    <Circle color={theme.palette.bgColor} type="horseshoe"/>
                                </CardCircleContainer>
                                <CardText>Apenas nacido el potrillo se le hacen las limpiezas requeridas y al día siguiente se realiza el proceso de “imprinting”</CardText>
                            </Card>
                            
                        </CardsContainer>
                        <TextContainer>Llevando a cabo este simple protocolo, familiarizando el potrillo con su cuidador, se incentiva la reacción natural que servirá para el manejo del producto en las diferentes etapas de cría hasta su doma, favoreciendo también el control veterinario.</TextContainer>
                    </Box>
                    <Box>
                        <TextButtonSection>
                            <TextSection>
                                <TitleBox>Primeros pasos</TitleBox>
                                <TextBox>Acá iría un resumen de esta sección Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</TextBox>
                            </TextSection>
                            <VermasButtonContainer>
                                <Link style={{all: "unset"}} to="primerospasos"><VermasButton/></Link>
                            </VermasButtonContainer>
                        </TextButtonSection>
                        <Img1/>
                    </Box>
                </BoxContainer>
                <Space/>
                    <Box>
                        <LinksImgSection>
                            <Img2></Img2>
                            <Links>
                                <LinkCard>
                                    <LinkCircleContainer>
                                        <Circle color={theme.palette.accent} type="cyd"/>
                                    </LinkCircleContainer>
                                    <LinkCardContent>
                                        <LinkCardTitle>CRÍA Y DOMA</LinkCardTitle>
                                        <LinkCardText>Acá iría un resumen de esta sección Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</LinkCardText>
                                    </LinkCardContent>
                                    <Link style={{all: "unset"}} to="disciplinas/CyD"><VermasButton height="25"></VermasButton></Link>
                                </LinkCard>
                                <LinkCard>
                                    <LinkCircleContainer>
                                        <Circle color={theme.palette.accent} type="edu"/>
                                    </LinkCircleContainer>
                                    <LinkCardContent>
                                        <LinkCardTitle>EDUCACIÓN</LinkCardTitle>
                                        <LinkCardText>Acá iría un resumen de esta sección Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</LinkCardText>
                                    </LinkCardContent>
                                    <Link style={{all: "unset"}} to="disciplinas/educacion"><VermasButton height="25"></VermasButton></Link>
                                </LinkCard>
                                <LinkCard>
                                    <LinkCircleContainer>
                                        <Circle color={theme.palette.accent} type="comp"/>
                                    </LinkCircleContainer>
                                    <LinkCardContent>
                                        <LinkCardTitle>COMPETENCIA</LinkCardTitle>
                                        <LinkCardText>Acá iría un resumen de esta sección Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</LinkCardText>
                                    </LinkCardContent>
                                    <Link style={{all: "unset"}} to="disciplinas/competencia"><VermasButton height="25"></VermasButton></Link>
                                </LinkCard>
                            </Links>
                        </LinksImgSection>
                    </Box>
        </Root>
  )
}
