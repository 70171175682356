import React from "react";
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import PregnantMares from "../components/reports/PregnantMares";
import { ThemeContext } from "../providers/theme";
import { useIntl } from 'react-intl';
import Competitions from "../components/competitions";


/* Styled components */
const Root = styled.div`
    height: auto;
    width: 100%;
    max-width: 1366px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin: 0 auto;
    @media (min-width: 1024px) {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 370px 830px 830px 830px 300px;
    }
`

const Box = styled.div`
    display: flex;
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    height: 100%;
    width: 100%;
    margin: 20px 0;
    border-radius: 10px;
    background-color: ${props => props.bg ? props.bg : "#FFF"};
    padding: ${props => props.padding ? props.padding : "20px"};
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    @media (min-width: 1024px) {
        grid-column: ${props => props.gridColumn ? props.gridColumn : "inherit"};
        grid-row: ${props => props.gridRow ? props.gridRow : "inherit"};
        margin: 0;
    }
`

const IframeBox = styled.div`
    position: relative;
    display: ${props => props.mobile ? 'flex' : 'none'};
    align-items: ${props => props.alignItems ? props.alignItems : "center"};
    justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
    width: 100%;
    margin: 10px 0;
    border-radius: 10px;
    background-color: ${props => props.bg ? props.bg : "#FFF"};
    padding: ${props => props.padding ? props.padding : "20px"};
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    padding: 0;
    @media (min-width: 1024px) {
        display: ${props => props.mobile ? 'none' : 'flex'};
        grid-column: ${props => props.gridColumn ? props.gridColumn : "inherit"};
        grid-row: ${props => props.gridRow ? props.gridRow : "inherit"};
        margin: 0;
    }
`

const Welcome = styled.span`
    font-size: 1.5rem;
`

export default function AdminPage()  {

    const theme = React.useContext(ThemeContext);

    let location = useLocation();

    const intl = useIntl();

    return(
        <Root>
            <Box padding="0 0" bg="transparent" gridColumn="1/5">
                <PregnantMares />
            </Box>
            <IframeBox gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="830px" 
                src="https://app.powerbi.com/view?r=eyJrIjoiMDdjZDk2ZmUtZjU1Yy00YTE4LWJmM2QtNjAwNTlhZDc4NjA5IiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <IframeBox mobile={true} gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="1275px"
                src="https://app.powerbi.com/view?r=eyJrIjoiYzY0MDE0NmItNTgwMy00Njk2LWFkM2QtZWVlOTlmZTgyOTI0IiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <IframeBox gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="830px"
                src="https://app.powerbi.com/view?r=eyJrIjoiMjY1ZDY4ZTctOTExYS00Y2Q1LWI2MzEtZjYzZGYyYmY2ODJiIiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <IframeBox mobile={true} gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="1140px"
                src="https://app.powerbi.com/view?r=eyJrIjoiNTFjOTVjNjAtNmExYy00YTE4LWIyNmQtMjU2NzEyN2UxZDZkIiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <IframeBox gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="830px"
                src="https://app.powerbi.com/view?r=eyJrIjoiMmRlYzJmYTAtYjljZi00ZDY0LTkzZmQtNWQxMWFiNjc5YWNhIiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <IframeBox mobile={true} gridColumn="1/5" bg="transparent">
                <iframe title="Tablero Haras v04" width="100%" height="970px"
                src="https://app.powerbi.com/view?r=eyJrIjoiY2I0MTdlZDUtYzM0ZC00NDA0LTg3M2EtNGYyMDIwYTRmZDQ4IiwidCI6ImI1ZmY0OGMxLTI5MWYtNDJjZS04ODViLTNhNWY4ZGY3ZWY3NyJ9"
                frameborder="0" allowFullScreen="false"></iframe>
            </IframeBox>
            <Box gridColumn="1/5">
                <Competitions />
            </Box>
        </Root>
    )
};
