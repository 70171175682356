import React from "react";
import { Outlet } from "react-router-dom";
import styled from 'styled-components';
import HorseLayoutNav from "../navbars/horseLayoutNav";
import HorseSidebar from "./HorseSidebar";
import Shortcuts from "./Shortcuts";

/* Styled components */
const SHORTCUTS_HEIGHT = "70px";

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 0 ${SHORTCUTS_HEIGHT} 0;
    transition: all 500ms ease-in-out;
    background-color: ${props => props.theme.palette.base};
    @media (min-width: 1024px) {
        flex-direction: row;
        padding: 0 20px;
    }
`

const ContentPage = styled.div`
    width: 100%;
    height: ${(props) => props.sidebar ? "calc(100% - 50px)" : "0" };;
    overflow-y: hidden;
    transition: all 500ms ease-in-out;
    @media (min-width: 1024px) {
        width: ${(props) => props.sidebar ? "calc(100% - 350px)" : "0" };
        height: 100%;
        overflow-x: hidden;
        overflow-y: inherit;
    }
`

const HorseLayout = () => {
    
    const [ sidebar, setSidebar ] = React.useState(false);

    const onSidebar = (value) => {
        if(value !== null && value !== undefined){
            setSidebar(value);
        } else {
            setSidebar(!sidebar);
        }
    }

    return (
            <Root>
                <HorseSidebar sidebar={sidebar} onSidebar={onSidebar} />
                <ContentPage sidebar={sidebar}>
                    <HorseLayoutNav>
                        <Outlet />
                    </HorseLayoutNav>
                </ContentPage>
                <Shortcuts height={SHORTCUTS_HEIGHT} onSidebar={onSidebar} />
            </Root>
    );
}

export default HorseLayout;