import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import Modal from "../modal";
import { FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { ThemeContext } from "../../providers/theme";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../providers/authentication";

const Root = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: auto;
    @media (min-width: 1024px) {
        width: 350px;
        min-height: 300px;
    }
    input:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
        transition-delay: background-color 5000s, color 5000s;
    }
`

const Title = styled.h2`
    width: 60%;
    border-bottom: 1px solid rgba(0,0,0,0.8);
    text-transform: uppercase;
    text-align: center;
    line-height: 3rem;
    margin: 0 0 30px 0;
`

const StyledButton = styled.button`
    border-radius: 47px;
    background: ${(props) => props.bg ? props.bg : '#D2A757'};
    padding: 10px 30px;
    margin: 30px 0;
    box-shadow: none;
    user-select: none;
    outline: none;
    border: none;
    cursor: pointer;
    color: white;
`

const Login = () => {

    const theme = React.useContext(ThemeContext);

    let navigate = useNavigate();

    const { onLogin } = useAuth();

    const [ formData, setFormData ] = React.useState({
        username: '',
        password: ''
    })

    const [ showPassword, setShowPassword ] = React.useState(false);

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const submit = (e) => {
        e.preventDefault();
        onLogin({userName: formData.username, password: formData.password}, () => navigate("/backoffice/dashboard"));
    }

    return (
        <Modal>
            <Root onSubmit={submit}>
                <Title>{translate("login")}</Title>
                <TextField label={translate("username")} name="username" type="text" value={formData.username} onChange={handleChange} variant="standard" margin="normal" fullWidth />
                <FormControl variant="standard" fullWidth>
                    <InputLabel htmlFor="standard-adornment-password">{translate("password")}</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        value={formData.password}
                        onChange={handleChange}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
                <StyledButton bg={theme.palette.accent}>{translate("loginbutton")}</StyledButton>
            </Root>
        </Modal>
    );
}

export default Login;