import React from "react";
import styled from 'styled-components';


/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 50px 1fr;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    @media (min-width: 1024px) {
        padding: 0 50px;
    }
`
export default function ReproductionPage()  {

    

    return(
        <Root>
            <h2>Reproducción</h2>
        </Root>
    )
};