import React from "react";
import styled from 'styled-components';
import AccordionRow from "../tables/table/AccordionRow";
import HeadAccordion from "../tables/table/HeadAccordion";
import ContentAccordion from "../tables/table/ContentAccordion";
import Appointment from "../clinichistory/Appointment";
import Edit from "@mui/icons-material/Edit";
import { useIntl } from "react-intl";
import { Tooltip } from "@mui/material";

const Title = styled.div`
width: 100%;
display: flex;
height: 100%;
align-items: center;
justify-content: flex-start;
`

const Date = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
margin: 0 10px 0 0;
`

const Name = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`

const EditIcon = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: flex-end;
width: 100%;
height: 25px;
cursor: pointer;
z-index: 99;
svg {
    font-size: 1.25rem;
}
`

const AppointmentAccordion = ({data, handleAction, refresh}) => {

    const intl = useIntl();

    const handleEdit = () => {
        handleAction(data);
    }

    return (
        <AccordionRow>
            <HeadAccordion>
                <Title>
                    <Date>{intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" })}</Date>
                    <Name>{data.name}</Name>
                </Title>
            </HeadAccordion>
            <ContentAccordion>
                    <EditIcon >
                        <Tooltip title={intl.formatMessage({ id: 'editAppointment' })}>
                            <Edit onClick={handleEdit} />
                        </Tooltip>
                    </EditIcon>
                
                <Appointment data={data} refresh={refresh} />
            </ContentAccordion>
        </AccordionRow>
    )

}

export default AppointmentAccordion;