import React from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import NoImage from "../../images/no-image.png";
import translate from "../../providers/i18n/translate";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useIntl } from "react-intl";
import { Modal } from "@mui/material";
import { API_URL } from "../../config";
import axios from "axios";
import LastClassificacion from "../classificacion/LastClassificacion";
import LastClassificacionExtended from "../classificacion/LastClassificacionExtended";
import LastTraining from "../training/LastTraining";
import LastApointment from "../clinichistory/LastApointment";
import NewHorse from "../horse/NewHorse";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import LastLocation from "../location/LastLocation";
import LastLocationExtended from "../location/LastLocationExtended";
import hasScope from "../../providers/authentication/hasScope";
import { useAuth } from "../../providers/authentication";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/* Styled components */
const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  grid-template-columns: ${(props) =>
    props.sidebar ? "inherit" : "38% 22% 40%"};
  grid-template-rows: ${(props) =>
    props.sidebar ? "inherit" : "10% 50% 21% 19%"};
  height: ${(props) => (props.sidebar ? "50px" : "100%")};
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.palette.base};
  overflow-y: auto;
  transition: all 500ms ease-in-out;
  @media (min-width: 1024px) {
    margin-left: 0.6em;
    display: ${(props) => (props.sidebar ? "flex" : "grid")};
    flex-direction: ${(props) => (props.sidebar ? "column" : "inherit")};
    width: ${(props) => (props.sidebar ? "350px" : "100%")};
    height: 100%;
  }
`;

const Title = styled.div`
  //Titulo nombre caballo
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 40px;
  text-transform: uppercase;
  order: ${(props) => (props.order ? props.order : "inherit")};
  transition: all 300ms ease-in-out;
  margin: 2px 0;
  background-color: ${(props) => props.theme.palette.accent};
  h2 {
    color: ${(props) => props.theme.palette.base};
    font-size: ${(props) => (props.sidebar ? "1.5rem" : "1rem")};
    font-weight: 900;
    text-align: center;
    justify-content: center;
  }
  span {
    color: ${(props) => props.theme.palette.base};
    font-size: 1rem;
    justify-content: center;
    margin: ${(props) => (props.sidebar ? "0 0 20px 0" : "0")};
  }
  @media (min-width: 1024px) {
    grid-column: 1/4;
    width: ${(props) => (props.sidebar ? "97%" : "38%")};
    align-items: center;
    justify-content: center;
    order: ${(props) =>
      props.sidebar ? (props.order ? props.order : "inherit") : "inherit"};
    padding: 0 60px 0 30px;
    margin: 0.5em 0em;
    padding: ${(props) => (props.sidebar ? "0px" : "0 30px 0 30px")};
  }
  @media (min-width: 1366px) {
    h2 {
      font-size: ${(props) => (props.sidebar ? "1.5rem" : "1.5rem")};
    }
    span {
      font-size: 1.5rem;
    }
  }
`;

const CenterColumn = styled.div`
  //Contenedor de la img de caballo
  position: relative;
  grid-row: 2/4;
  display: ${(props) => (props.hideMobile ? "none" : "flex")};
  width: 100%;
  height: ${(props) => (props.sidebar ? "auto" : "100%")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 300ms ease-in-out;
  order: ${(props) => (props.order ? props.order : "inherit")};
  @media (min-width: 1024px) {
    order: ${(props) =>
      props.sidebar ? (props.order ? props.order : "inherit") : "inherit"};
    width: 100%;
    height: ${(props) => (props.sidebar ? "auto" : "100%")};
    display: flex;
  }
`;

const Column = styled.div`
  //Columnas de datos
  position: relative;
  display: flex;
  flex-direction: column;
  display: ${(props) => (props.hideMobile ? "none" : "flex")};
  width: ${(props) => (props.sidebar ? "95%" : "100%")};
  height: ${(props) => (props.sidebar ? "auto" : "100%")};
  grid-template-columns: ${(props) => (props.sidebar ? "" : "1fr 1fr")};
  grid-auto-rows: max-content;
  padding: ${(props) => (props.sidebar ? "1px" : "15px 15px")};
  gap: 1px 1px;
  order: ${(props) => (props.order ? props.order : "inherit")};
  transition: all 300ms ease-in-out;
  background-color: ${(props) =>
    props.mobileStyle ? props.theme.palette.base : "transparent"};
  border-radius: ${(props) => (props.mobileStyle ? "20px" : "0")};
  @media (min-width: 1024px) {
    padding: ${(props) => (props.sidebar ? "1px" : "0 15px 0 15px")};
    grid-row: 2/3;
    display: ${(props) => (props.hideMobile ? "none" : "grid")};
    display: ${(props) => (props.sidebar ? "grid" : "flex")};
    flex-direction: column;
  }
  @media (min-width: 1366px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const ImageProfile = styled.img`
  width: ${(props) => (props.sidebar ? "100px" : "100%")};
  height: ${(props) => (props.sidebar ? "100px" : "380px")};
  max-height: 52vh;
  border-radius: ${(props) => (props.sidebar ? "50%" : "inherit")};
  object-fit: cover;
  object-position: center;
  transition: all 300ms ease-in-out;
  border: ${(props) =>
    props.sidebar
      ? "none"
      : `solid 0.1em ${(props) => props.theme.palette.secundary}`};
  @media (min-width: 1366px) {
    width: ${(props) => (props.sidebar ? "100px" : "100%")};
    height: ${(props) => (props.sidebar ? "100px" : "370px")};
  }
`;

const AccessButtons = styled.div`
  position: relative;
  display: none;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  margin: 10px 0 0 0px;
  transition: all 300ms ease-in-out;
  @media (min-width: 1024px) {
    display: ${(props) => (props.hide ? "none" : "flex")};
  }
`;

const AccessButton = styled.div`
  padding: 8px 4px;
  text-align: center;
  cursor: pointer;
  color: ${(props) => props.theme.palette.base};
  background-color: ${(props) => props.theme.palette.principal};
  border-radius: 5px;
  font-size: 0.6rem;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  font-weight: 500;
  &:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1024px) {
    width: 31%;
  }
`;

const EditAccess = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0 0 3px ${(props) => props.theme.palette.base};
  color: ${(props) => props.theme.palette.base};
  border-radius: 50%;
  text-transform: uppercase;
  margin: 0;
  transition: all 300ms ease-in-out;
  display: flex;
  &:hover {
    background-color: ${(props) => props.theme.palette.accent};
  }
  @media (min-width: 1024px) {
    display: flex;
    height: ${(props) => (props.sidebar ? "30px" : "28px")};
    width: ${(props) => (props.sidebar ? "30px" : "28px")};
  }
`;
const EditAccessArrowBack = styled(EditAccess)`
  left: 15px;
`;

const BoxTitle = styled.h2`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.accent};
  font-size: ${(props) => (props.sidebar ? "0.8em" : "0.875em")};
  width: 100%;
  transition: all 300ms ease-in-out;
  font-size: 1.2rem;
  font-weight: 800;
  margin-bottom: ${(props) => (props.sidebar ? "10px" : "0.2em")};
  margin-top: ${(props) => (props.sidebar ? "10px" : "none")};
  @media (min-width: 1024px) {
    font-weight: 800;
    font-size: 1em;
  }
  @media (min-width: 1366px) {
    font-size: 1.25em;
  }
`;
const BoxTitle2 = styled(BoxTitle)`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  align-items: flex-start;
  justify-content: center;
  @media (min-width: 1024px) {
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.accent};
    font-weight: 800;
    margin-bottom: 0;
  }
`;

const BoxSubtitle = styled.h3`
  //Titulos en negro
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-end;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.secundary};
  font-size: 1rem;
  height: 18px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-top: 0.4rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
    line-height: 1;
    margin-top: 0.2rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secundary};
  }
`;

const BoxText = styled.p`
  //textos de datos tecnicos
  width: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  color: ${(props) =>
    props.mobileStyle
      ? props.theme.palette.principal
      : props.theme.palette.accent};
  font-size: 1rem;
  height: 18px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-top: 0.1rem;
  line-height: 1;
  svg {
    color: ${(props) =>
      props.mobileStyle
        ? props.theme.palette.principal
        : props.theme.palette.accent};
    font-size: 0.8rem;
    margin: 0 0 0 3px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }
  svg:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1024px) {
    font-size: 0.9rem;
    font-weight: 600;
    svg {
      font-size: ${(props) => (props.sidebar ? "0.75rem" : "0.9em")};
    }
  }
  color: ${(props) => props.theme.palette.accent};
`;
const BoxText2 = styled(BoxText)`
  width: 100%;
  align-items: center;
  justify-content: center;
  height: auto;
  @media (min-width: 1024px) {
    font-size: 0.85rem;
    color: ${(props) => props.theme.palette.secundary};
  }
`;

const BoxParagraph = styled.p`
  //Recuadro de observaciones
  width: 98%;
  height: 100%;
  display: ${(props) => (props.hide ? "none" : "flex")};
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  color: ${(props) =>
    props.mobileStyle
      ? props.theme.palette.secundary
      : props.theme.palette.base};
  font-size: 0.85rem;
  height: auto;
  min-height: 70px;
  border: solid ${(props) => props.theme.palette.secundary} 0.1em;
  @media (min-width: 1024px) {
    color: ${(props) => props.theme.palette.secundary};
    min-height: 70px;
  }
`;
const DateCita = styled.div`
  display: ${(props) => (props.sidebar ? "none" : "flex")};
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  grid-column: 1/4;
  padding: 5px 0 0px 15px;
  order: ${(props) => (props.order ? props.order : "inherit")};
  @media (min-width: 1024px) {
    padding: 0;
    flex-direction: row;
  }
`;
const DressageStyled = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: start;
  justify-content: center;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.accent};
  font-size: 0.875rem;
  width: 100%;
  transition: all 300ms ease-in-out;
  font-weight: 400;
  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.accent};
    font-weight: 800;
  }
`;
const Observations = styled.div`
  width: 100%;
  display: ${(props) => (props.sidebar ? "none" : "flex")};
  grid-row: 3/4;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  order: ${(props) => (props.order ? props.order : "inherit")};
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  flex-direction: column;
  padding: 5px 0 0px 15px;
  margin-bottom: 10px;
`;

const HorseSidebar = ({ sidebar, onSidebar }) => {
  const { id } = useParams();

  const [openEdit, setOpenEdit] = React.useState(false);
  const { token } = useAuth();

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => {
    setOpenEdit(false);
    getData();
  };

  const navigate = useNavigate();

  const [data, setData] = React.useState(null);

  const intl = useIntl();

  const handleAccess = (e) => {
    const { to } = e.target.dataset;
    onSidebar(true);
    navigate(to);
    onSidebar(true);
  };

  const backSidebar = () => {
    if (sidebar) {
      onSidebar(false);
      navigate("");
    } else {
      navigate("/backoffice/horses");
      onSidebar(false);
    }
  };

  const getData = () => {
    axios
      .get(`${API_URL}/horses/${id}`)
      .then((horses) => {
        setData(horses.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (!data) {
      getData();
    }
  }, [data]);

  return (
    data && (
      <Root sidebar={sidebar}>
        <Title sidebar={sidebar} order={1}>
          {hasScope(token, "arrowBack") && (
            <EditAccessArrowBack
              sidebar={sidebar}
              hide={sidebar}
              onClick={backSidebar}
            >
              <ArrowBackIcon />
            </EditAccessArrowBack>
          )}
          <h2>{data.name}</h2>
          {hasScope(token, "editHorse") && (
            <EditAccess
              sidebar={sidebar}
              hide={sidebar}
              onClick={handleOpenEdit}
            >
              <EditOutlinedIcon />
            </EditAccess>
          )}
        </Title>
        <CenterColumn order={2} sidebar={sidebar} hideMobile={sidebar}>
          <ImageProfile
            sidebar={sidebar}
            src={data.imageProfile ? data.imageProfile.url : NoImage}
            alt={`image profle ${data.name}`}
          />
          <AccessButtons hide={sidebar}>
            <AccessButton data-to="clinichistory" onClick={handleAccess}>
              {translate("clinic history")}
            </AccessButton>
            <AccessButton data-to="mediacenter" onClick={handleAccess}>
              {translate("mediacenter")}
            </AccessButton>
            <AccessButton data-to="reproduction" onClick={handleAccess}>
              {translate("reproduction")}
            </AccessButton>
          </AccessButtons>
        </CenterColumn>

        <Column order={3} sidebar={sidebar} hideMobile={sidebar}>
          <BoxTitle sidebar={sidebar} gridColumn="1/3">
            {translate("technical data")}
          </BoxTitle>
          <BoxSubtitle sidebar={sidebar} order={1}>
            {translate("birthday")}
          </BoxSubtitle>
          <BoxText sidebar={sidebar} order={3}>
            {intl.formatDate(data.dateOfBirth, {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })}
          </BoxText>
          <BoxSubtitle sidebar={sidebar} order={2}>
            {translate("age")}
          </BoxSubtitle>
          <BoxText sidebar={sidebar} order={4}>
            {new Date().getFullYear() -
              new Date(data.dateOfBirth).getFullYear()}
          </BoxText>
          <BoxSubtitle sidebar={sidebar} order={5}>
            {translate("sex")}
          </BoxSubtitle>
          <BoxText sidebar={sidebar} order={7}>
            {data.sex ? translate(data.sex.value) : translate("unknown")}
          </BoxText>
          <BoxSubtitle sidebar={sidebar} order={6}>
            {translate("fur")}
          </BoxSubtitle>
          <BoxText sidebar={sidebar} order={8}>
            {data.fur ? translate(data.fur.value) : translate("unknown")}
          </BoxText>
          <BoxSubtitle sidebar={sidebar} order={9}>
            {translate("father")}
          </BoxSubtitle>
          {data.father ? (
            <BoxText sidebar={sidebar} order={11}>
              {data.father.name}
              <Link to={`/backoffice/horses/${data.father.id}`} target="_blank">
                <OpenInNewIcon />
              </Link>
            </BoxText>
          ) : (
            <BoxText sidebar={sidebar} order={11}>
              {translate("unknown")}
            </BoxText>
          )}
          <BoxSubtitle sidebar={sidebar} order={10}>
            {translate("mother")}
          </BoxSubtitle>
          {data.mother ? (
            <BoxText sidebar={sidebar} order={12}>
              {data.mother.name}
              <Link to={`/backoffice/horses/${data.mother.id}`} target="_blank">
                <OpenInNewIcon />
              </Link>
            </BoxText>
          ) : (
            <BoxText sidebar={sidebar} order={12}>
              {translate("unknown")}
            </BoxText>
          )}
        </Column>

        <Column
          hide={sidebar}
          order={4}
          sidebar={sidebar}
          hideMobile={sidebar}
          mobileStyle={true}
        >
          <BoxTitle sidebar={sidebar} gridColumn="1/3">
            {translate("general data")}{" "}
          </BoxTitle>
          {sidebar ? (
            <LastClassificacion sidebar={sidebar} id={id} />
          ) : (
            <LastClassificacionExtended
              id={id}
              clasification={data.clasification}
              order={2}
            />
          )}
          {sidebar ? (
            <LastLocation
              sidebar={sidebar}
              id={id}
              location={data.location}
              pedigree={data.pedigree}
              passport={data.passport}
              rider={data?.rider}
              owner={data?.owner}
            />
          ) : (
            <LastLocationExtended
              id={id}
              location={data.location}
              pedigree={data.pedigree}
              passport={data.passport}
              rider={data?.rider}
              owner={data?.owner}
              order={1}
            />
          )}
        </Column>

        <Observations sidebar={sidebar} gridColumn="2/4" order={5}>
          <BoxTitle gridColumn="2/4">{translate("observations")}</BoxTitle>
          <BoxParagraph
            gridColumn="2/4"
            mobileStyle={true}
            dangerouslySetInnerHTML={{ __html: data.observation }}
          />
        </Observations>

        <DateCita sidebar={sidebar} order={6}>
          <LastApointment />
          <LastTraining />
          <DressageStyled>
            <BoxTitle2>{translate("dressage start")}</BoxTitle2>
            <BoxText2 mobileStyle={true}>
              {data.startDressage
                ? intl.formatDate(data.startDressage, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                : "---"}
            </BoxText2>
          </DressageStyled>
          <DressageStyled>
            <BoxTitle2>{translate("dressage end")}</BoxTitle2>
            <BoxText2 mobileStyle={true}>
              {data.startDressage
                ? intl.formatDate(data.startDressage, {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })
                : "---"}
            </BoxText2>
          </DressageStyled>
        </DateCita>
        <Modal open={openEdit} onClose={handleCloseEdit}>
          <NewHorse horseData={data} handleClose={handleCloseEdit} />
        </Modal>
      </Root>
    )
  );
};

export default HorseSidebar;
