import React from "react";
import { Outlet } from "react-router-dom";
import styled from 'styled-components';
import ClinicHistoryNav from "../components/navbars/clinicHistoryNav";

/* Styled components */

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFF;
    border-radius: 10px;
`

const Container = styled.div`
    border: solid 3px #00271C;
    height: 100%;
`

const HorseClinicHistoryPage = () => {

    return (
        <Root>
            <ClinicHistoryNav>
                <Container>
                    <Outlet  />
                </Container>
            </ClinicHistoryNav>
        </Root>
    );
}

export default HorseClinicHistoryPage;