import React from "react";
import styled from 'styled-components';

/* Styled components */

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`

const HorsePage = () => {

    return (
        <Root>
            
        </Root>
    );
}

export default HorsePage;