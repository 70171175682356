
import React from "react";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HeadAccordion from "./HeadAccordion";
import ContentAccordion from "./ContentAccordion";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid rgba(0,0,0,0.2);
    outline: none;
    opacity: ${props => props.expand ? 1 : 0.5};
    padding: 2px 10px;
`

const Head = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    cursor: pointer;
    user-select: none;
`

const Content = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: ${props => props.expand ? "auto" : "0px"};
    overflow-y: hidden;
    transition: all 500ms ease-in-out;
    padding: 0 20px 0 20px;
    @media (min-width: 1024px) {
        padding: 0 35px 0 35px;
    }
`

const StyledIcon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    transform: ${props => props.expand ? "rotate(90deg)" : "rotate(0)"};
    transition: all 300ms ease-in-out;
    margin: 0 10px 0 0;
    svg {
        font-size: 1.25rem;
    }
`



const ContentHead = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: calc(100% - 35px);
    height: 100%;
`

const AccordionRow = ({children}) => {

    const [ expand, setExpand ] = React.useState(false);

    const handleExpand = (e) => {
        e.preventDefault();
        setExpand(!expand)
    }

    const headChildren = React.Children.map(children, (child) => {
        if(child.type === HeadAccordion){
            return React.cloneElement(child);
        }
        return null;
    })

    const contentChildren = React.Children.map(children, (child) => {
        if(child.type === ContentAccordion){
            return React.cloneElement(child);
        }
        return null;
    })

    return (
        <Root expand={expand}>
            <Head onClick={handleExpand}>
                <StyledIcon expand={expand}>
                    <ArrowForwardIosIcon />
                </StyledIcon>
                <ContentHead>
                    { headChildren }
                </ContentHead>
            </Head>
            <Content expand={expand}>
                { contentChildren }
            </Content>
        </Root>
    );
}

export default AccordionRow;