import React from "react";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import LinkIcon from '@mui/icons-material/Link';
import ReactPlayer from 'react-player';
import { Modal } from "@mui/material";
import translate from "../../providers/i18n/translate";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    border-bottom: 2px solid rgba(0,0,0,0.5);
    margin: 5px 0;
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
        border: none;
        margin: 0;
    }
`

const Data = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 30px;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    strong {
        margin-right: 5px;
    }
    @media (min-width: 1024px) {
        width: ${props => props.width ? props.width : "auto"};
        border-bottom: 1px solid rgba(0,0,0,0.1);
        strong {
            display: none;
        }
    }
`

const StyledLink = styled(LinkIcon)`
    cursor: pointer;
    user-select: none;
    &:hover {
        transform: scale(1.1);
    }
`

const ContentModal = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 100%;
    outline: none;
    @media (min-width: 1024px) {
        height: auto;
        width: auto;
    }
`

const ContentVideo = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: auto;
    @media (min-width: 1024px) {
        width: 50%;
        height: 50%;
    }
`

const Competition = ({data}) => {

    const intl = useIntl();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    return (
        data &&
        <Root>
            <Data width="45%">
                <strong>{translate('club')}:</strong>
                {data.club}
            </Data>
            <Data width="15%">
                <strong>{translate('date')}:</strong>
                {intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" })}
            </Data>
            <Data width="10%">
                <strong>{translate('jump')}:</strong>
                {data.jump}
            </Data>
            <Data width="10%">
                <strong>{translate('faults')}:</strong>
                {data.faults}
            </Data>
            <Data width="10%">
                <strong>{translate('Result')}:</strong>
                {data.result}
            </Data>
            <Data width="10%">
                <strong>{translate('Video')}:</strong>
                <StyledLink onClick={handleOpen} />
            </Data>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <ContentModal>
                    <ContentVideo>
                        <ReactPlayer url={data.url} controls muted width='100%' height='100%' />
                    </ContentVideo>
                </ContentModal>
                
            </Modal>
        </Root>
    );
}

export default Competition;