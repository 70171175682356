import React from "react";
import styled from 'styled-components';
import {Table, SearchInput, Filters, FilterGroup} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl';
import { API_URL } from "../config";
import NewLocation from "../components/location/NewLocations";
import { Modal } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    @media (min-width: 1024px) {
        padding: 0;
    }
`

const Title = styled.h2`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: ${(props) => props.color ? props.color : '#000'};
    border-bottom: 1px solid rgba(0,0,0,0.2);
`

const Content = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    padding: 20px 10px;
    border-radius: 10px;
    @media (min-width: 1024px) {
        padding: 20px;
    }
`

const NewIcon = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => props.bg ? props.bg : "7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    width: 50px;
    height: 50px;
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

export default function PlacesPage()  {

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const navigate = useNavigate();

    const intl = useIntl();

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        tableRef.current.clean();
        setOpen(false);
    }
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        tableRef.current.clean();
        setOpenEdit(false);
    } 

    const [ editLocation, setEditLocation ] = React.useState(null);
    

    const columnsTable = [
        /* { title: 'ID', field: 'id',width: "10%", order: true, hidden: false}, */
        { title: translate("name"), field: 'name',width: "25%",  hidden: false },
        { title: translate("address"), field: 'address',width: "25%", hidden: false },
        { title: translate("phone"), field: 'phoneNumber',width: "25%", hidden: false, mobile: false },
        { title: translate("personCharge"), field: 'personCharge.lastName',width: "25%", hidden: false, mobile: false },
        { title: translate("actions"), actions: true, width: "auto", hidden: false }
    ]

    const handleNewItem = (data) => {
        handleOpen();
    }
    
    const handleEditItem = (data) => {
        setEditLocation(data);
        handleOpenEdit()
    }

    const handleDeleteItem = (data) => {
        // eslint-disable-next-line no-restricted-globals
        if( confirm(intl.formatMessage({ id: 'confirm delete' })) ){
            axios.delete(`${API_URL}/locations/${data.id}`)
            .then(result => {
                tableRef.current.clean();
            })
            .catch(err => {
                console.log(err);
            })
        }
    }

    const actionsTable={
        edit: {
            icon: theme.icons.edit,
            tooltip: 'Editar',
            onClick: handleEditItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: 'Eliminar',
            onClick: handleDeleteItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        }
    }

    return(
        <Root>
            <Content>
                <Table 
                    title={intl.formatMessage({ id: 'locations' })}
                    disableTotalTitle={true}
                    ref={tableRef}
                    columns={columnsTable}
                    actionsTable={actionsTable}
                    viewTable="list"
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_URL}/locations`,{
                                params: {
                                    page: query.page - 1,
                                    limit: query.pageSize
                                }
                            })
                            .then(locations => {
                                resolve({rows: locations.data, total: locations.data ? locations.data.length : 0});
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        })
                    }
                >
                </Table>
                <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}><AddIcon /></NewIcon>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <NewLocation handleClose={handleClose} />
                </Modal>
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                >
                    <NewLocation locationData={editLocation} handleClose={handleCloseEdit} />
                </Modal>
            </Content>
        </Root>
    )
};