import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import Diagnosis from "./Diagnosis";
import Intervention from "./Intervention";
import AddIcon from '@mui/icons-material/Add';
import { Modal, Tooltip } from "@mui/material";
import { useIntl } from 'react-intl';
import NewDiagnosis from "../appointment/diagnosis/NewDiagnosis";
import NewIntervention from "../appointment/intervention/NewIntervention";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
`

const GroupData = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;
    }
`

const Professional = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    margin: 5px 0;
    h3 {
        color: ${props => props.theme.palette.details};
        text-transform: uppercase;
        font-size: 1rem;
        margin-right: 10px;
        font-weight: 400;
    }
    @media (min-width: 1024px) {
        width: 50%;
        margin: 0;
    }
`

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: ${props => props.theme.palette.accent};
    font-size: 1.15rem;
    text-transform: uppercase;
    font-weight: 400;
`

const Subtitle = styled.h3`
    width: 100%;
    color: ${props => props.theme.palette.details};
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 400;
`

const Description = styled.div`
    width: 100%;
    height: auto;
    font-size: 0.875rem;
`

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.palette.details};
    opacity: 0.8;
    margin: 10px 0;
`

const InterventionsGrid = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        flex-direction: inherit;
        align-items: inherit;
        justify-content: inherit;
    }
`

const AddButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    box-shadow: none;
    border: none;
    outline: none;
    margin: 0 10px;
    svg {
        font-size: 1rem;
    }
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
`
    


const Appointment = ({data, refresh}) => {

    const intl = useIntl();

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const [openIntervention, setOpenIntervention] = React.useState(false);
    const [openEditIntervention, setOpenEditIntervention] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        refresh()
        setOpen(false);
    }
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        refresh();
        setOpenEdit(false);
    }

    const handleOpenIntervention = () => setOpenIntervention(true);
    const handleCloseIntervention = () => {
        refresh()
        setOpenIntervention(false);
    }
    const handleOpenEditIntervention = () => setOpenEditIntervention(true);
    const handleCloseEditIntervention = () => {
        refresh();
        setOpenEditIntervention(false);   
    }

    const [ editDiagnosis, setEditDiagnosis ] = React.useState(null);

    const [ editIntervention, setEditIntervention ] = React.useState(null);

    const handleNewDiagnosis = () => {
        handleOpen();
    }
    
    const handleEditDiagnosis = (data) => {
        setEditDiagnosis(data);
        setOpenEdit(true);
    }

    const handleNewIntervention = () => {
        handleOpenIntervention();
    }
    
    const handleEditIntervention = (data) => {
        setEditIntervention(data);
        setOpenEditIntervention(true);
    }

    return (
        data ?
        <Root>
            <GroupData>
                <Professional>
                    <h3>{translate("professional")}:</h3>
                    <span>{data.veterinarian.fullName}</span>
                </Professional>
                <Professional>
                    <h3>{translate("professionalAssistant")}:</h3>
                    <span>{data.veterinarianAssistant?.fullName}</span>
                </Professional>
            </GroupData>
            <Divider />
            <Subtitle>{translate('description')}</Subtitle>
            <Description dangerouslySetInnerHTML={{ __html: data.description }} />
            <Divider />
            <Title>
                <h2>{translate("diagnoses")}</h2>
                <Tooltip title={intl.formatMessage({ id: 'addDiagnosis' })}>
                    <AddButton><AddIcon onClick={handleNewDiagnosis} /></AddButton>
                </Tooltip>
            </Title>
            
            {
                data.diagnosis && data.diagnosis.length > 0 &&
                data.diagnosis.map((diag, index) => 
                    <Diagnosis key={`diagnosis_${index}_${diag.id}`} data={diag} handleEdit={handleEditDiagnosis} refresh={refresh} />
                )
            }
            <Divider />
            <Title>
                <h2>{translate("interventions")}</h2>
                <Tooltip title={intl.formatMessage({ id: 'addIntervention' })}>
                    <AddButton onClick={handleNewIntervention}><AddIcon /></AddButton>
                </Tooltip>
            </Title>
            {
                data.interventions && data.interventions.length > 0 &&
                <InterventionsGrid>
                    {
                        data.interventions.map((inter, index) => 
                            <Intervention key={`intervention_${index}_${inter.id}`} data={inter} handleEdit={handleEditIntervention} refresh={refresh} />
                        )
                    }
                </InterventionsGrid>
            }
            <Modal
                open={open}
                onClose={handleClose}
            >
                <NewDiagnosis appointmentId={data.id} handleClose={handleClose} />
            </Modal>
            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <NewDiagnosis appointmentId={data.id} data={editDiagnosis} handleClose={handleCloseEdit} />
            </Modal>
            <Modal
                open={openIntervention}
                onClose={handleCloseIntervention}
            >
                <NewIntervention appointmentId={data.id} handleClose={handleCloseIntervention} />
            </Modal>
            <Modal
                open={openEditIntervention}
                onClose={handleCloseEditIntervention}
            >
                <NewIntervention appointmentId={data.id} data={editIntervention} handleClose={handleCloseEditIntervention} />
            </Modal>
        </Root>
        :
        <span>---</span>
    );
}

export default Appointment;