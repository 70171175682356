import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';
import { ThemeContext } from '../../../providers/theme';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { API_URL } from '../../../config';
import axios from 'axios';
import BasicEditor from '../../textEditors/basicEditor';
import FileInput from '../../inputs/fileInput';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/action-creators";
import { loadingConstants } from "../../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const NewAttachment = ({id, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress} = bindActionCreators(actionCreators, dispatch);

    const [ newAttachment, setNewAttachment ] = React.useState(
        {
            description: '',
            attachment: null
        }
    )

    const handleEditorDescription = (e, dataeditor) => {
        setNewAttachment({
            ...newAttachment,
            description: dataeditor
        })
    }

    const onChangeFile = (e) => {
        const {files} = e.target;
        if(files.length > 0){
            setNewAttachment({
                ...newAttachment,
                attachment: files
            });
        }
    }

    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        var formdata = new FormData();
        formdata.append("description", newAttachment.description);
        if(newAttachment.attachment.length > 1){
            Array.from(newAttachment.attachment).forEach(element => {
                formdata.append("attachment", element);
            });
        } else {
            formdata.append("attachment", newAttachment.attachment[0]);
        }
        const url = `${API_URL}/clinicHistory/attachment/interventions/${id}`;
        axios.post(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
        .then(response => {
            setTimeout(() => {
                loadingAction(loadingConstants.READY);
                handleClose();
            }, 3000);            
        })
        .catch(error => {
            loadingAction(loadingConstants.READY);
            alert(intl.formatMessage({ id: 'errorContact' }));
            console.log(error.response?.data?.msg);
        })
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

    return (
        <Root onSubmit={handleAction}>
            <Title>{translate("attach")}</Title>
            <BasicEditor
                name="description"
                value={newAttachment.description}
                onChange={handleEditorDescription}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: 'insertDescription' })}
            />
            <FileInput file={newAttachment.attachment} error={false} onChange={onChangeFile} multiple required />
            <Button bg={theme.palette.accent} color={theme.palette.base}><CheckIcon /></Button>
        </Root>
    );
}

export default NewAttachment;