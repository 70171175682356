import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from '../inputs/SelectInput';
import { API_URL } from '../../config';
import axios from 'axios';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';
import moment from 'moment';

require("moment/locale/es.js");

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: 100%;
    @media (min-width: 1024px) {
        width: ${props => props.width ? props.width : "inherit"};
    }
`

const ChangeLocation = ({id, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [ newLocation, setNewLocation ] = React.useState(
        {
            date: new Date(),
            location: null,
        }
    )

    const handleChangeLocationDate = (newValue) => {
        setNewLocation({
            ...newLocation,
            date: newValue
        });
    }

    const handleChangeAutocomplete = (name, value) => {
        setNewLocation({
            ...newLocation,
            [name]: value
        });
    }


    const handleAction = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        const newDate = moment(newLocation.date)
        formdata.append("location", newLocation.location?.value);
        formdata.append("date",`${newDate.format("YYYY-MM-DD")}`);
        
        const url = `${API_URL}/horses/${id}/changeLocation`;
        axios.post(url, formdata,
            {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
            }
            })
        .then(response => {
            handleClose();
        })
        .catch(error => {
            console.log(error);
        })
    }
    

    return (
        <Root>
            <Title>{translate("transferHorse")}</Title>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    renderInput={(props) => <StyledTextfield size="small" margin="dense" fullWidth {...props} />}
                    label={intl.formatMessage({ id: 'locationDate' })}
                    value={newLocation.date}
                    onChange={handleChangeLocationDate}
                    required
                />
            </LocalizationProvider>
            <SelectInput value={newLocation.location} fieldlabel="name" fieldvalue="id" dataurl={`${API_URL}/locations`} translateDisabled={true} label='location' name="location" onChange={handleChangeAutocomplete} width="100%" margin="dense" size="small" required />
            <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
}

export default ChangeLocation;