import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const Nav = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 50px;
    border-top: 1px solid ${props => props.border ? props.border : "#000"};
    border-bottom: 1px solid ${props => props.border ? props.border : "#000"};
    justify-content: flex-start;
    overflow: auto;
    /* width */
    ::-webkit-scrollbar {
    width: 5px;
    height:5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.color ? props.color : "#D1AC00"};
    border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: ${(props) => props.color ? props.color : "#D1AC00"};
    }
    @media (min-width: 1024px) {
    }
`

const Divider = styled.div`
    display: none;
    width: 1px;
    height: 95%;
    background-color: ${props => props.color ? props.color : "#000"};
    @media (min-width: 1024px) {
        display: inherit;
    }
`

const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${(props) => props.color ? props.color : "#D1AC00"};
    font-size: 1rem;
    text-transform: uppercase;
    opacity: 0.5;
    margin: 5px;
    min-width: max-content;
    &.active {
        opacity: 1;
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
        margin: 0 10px;
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
`

const ReportsNav = ({children}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root>
            <Nav>
                <Item to="" end color={theme.palette.principal}>{translate("competitions")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="education" color={theme.palette.principal}>{translate("education")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="locations" color={theme.palette.principal}>{translate("locations")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="stock" color={theme.palette.principal}>{translate("semenStock")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="recipientMares" color={theme.palette.principal}>{translate("recipientMares")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="motherMares" color={theme.palette.principal}>{translate("motherMares")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="serviceMares" color={theme.palette.principal}>{translate("inServiceMares")}</Item>
                <Divider color={theme.palette.principal} />
                <Item to="clinicHistory" color={theme.palette.principal}>{translate("clinicHistory")}</Item>
            </Nav>
            <Content>
                {children}
            </Content>
        </Root>
    );
}

export default ReportsNav;
