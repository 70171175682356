import React from "react";
import styled from "styled-components";
import { useNavigate, useMatch } from "react-router-dom";
import FeedIcon from '@mui/icons-material/Feed';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import WebhookIcon from '@mui/icons-material/Webhook';

const Root = styled.ul`
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    list-style: none;
    height: ${(props) => props.height ? props.height : "100px"};
    background-color: ${props => props.theme.palette.principal};
    @media (min-width: 1024px) {
        display: none;
    }
`

const Icon = styled.li`
    svg {
        font-size: 2rem;
        color: ${props => props.active ? props.theme.palette.accent : props.theme.palette.base};
    }
`

const Shortcuts = ({height, onSidebar}) => {

    const navigate = useNavigate();

    const handleSidebar = (e) => {
        e.preventDefault();
        const { to, sidebar } = e.currentTarget.dataset;
        onSidebar(sidebar === "0" ? false : true);
        navigate(to);
    }

    return (
        <Root height={height}>
            <Icon active={useMatch("/backoffice/horses/:id")} data-to="" data-sidebar={0} onClick={handleSidebar}><FeedIcon /></Icon>
            <Icon active={useMatch("/backoffice/horses/:id/clinichistory")} data-to="clinichistory" data-sidebar={1} onClick={handleSidebar}><LocalHospitalIcon /></Icon>
            <Icon active={useMatch("/backoffice/horses/:id/mediacenter")} data-to="mediacenter"  data-sidebar={1} onClick={handleSidebar}><VideoCameraBackIcon /></Icon>
            <Icon active={useMatch("/backoffice/horses/:id/reproduction")} data-to="reproduction" data-sidebar={1} onClick={handleSidebar}><WebhookIcon /></Icon>
        </Root>
    );
}

export default Shortcuts;