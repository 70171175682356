import React from 'react';
import styled from 'styled-components';
import { NavLink } from "react-router-dom";
import { Tooltip } from '@mui/material';

const Root = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    margin: 20px 0;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        margin: 10px 0;
    }
`

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${(props) => props.color ? props.color : "#D1AC00"};
    font-size: 1.5rem;
    text-transform: uppercase;
    span {
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
    }
    &.active {
        color: ${(props) => props.activecolor ? props.activecolor : "#FFF"};
        img {
            filter: invert(34%) sepia(19%) saturate(3188%) hue-rotate(256deg) brightness(59%) contrast(86%);
        }
    }
    img{
        width: 26px;
        height: 26px;
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
    }
`

const ItemList = ({item, color, activecolor, showname, closeNav}) => {
    return (
        <Root>
            <Tooltip title={item.name} placement="bottom">
            <StyledNavLink to={item.link} onClick={closeNav} color={color} activecolor={activecolor} showname={showname ? 1 : 0}>
                {item.icon}
                <span>{item.name}</span>
            </StyledNavLink>
            </Tooltip>
        </Root>
    );
}

export default ItemList;