import React from "react";
import styled from 'styled-components';

/* Styled components */
const Root = styled.div`
    position: relative;
    height: auto;
    width: 100%;
`

export default function SettingsPage()  {

    return(
        <Root>
            <h1>
                SETTINGS
            </h1>
        </Root>
    )
};