import React from "react";
import styled from "styled-components";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`

const WebLayout = () => {

    const FOOTER_HEIGHT = "200px";

    return (
        <Root>
            <Navbar/>
            <Outlet />
            {/* <Footer height={FOOTER_HEIGHT} /> */}
        </Root>
    );
}

export default WebLayout;