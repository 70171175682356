import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import {mob, mobText, desk, deskText} from '../utilities';
import { ThemeContext } from "../providers/theme";
/* import ImageHeader from "/comp.jpeg"; */

/* Styled components */
const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    height: 100vh;
    width: 100%;
    background: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0), #873d90), url(${require("../public/cover.jpeg")});
    background-size: cover;
    background-position: 59%;
    @media(min-width: 1024px){
        height: calc(100vh - ${desk(182.25)});
        background-position: center 30%;
    }
`


export default function Cover() {
    
    const theme = useContext(ThemeContext);

    return (
        <Root>
        </Root>
    );
}
