import { LOCALES } from "../locales";

export default {
  [LOCALES.SPANISH]: {
    hello: "Hola, {name}!",
    login: "Ingresar",
    username: "Nombre de usuario",
    password: "Contraseña",
    loginbutton: "Iniciar sesión",
    home: "Inicio",
    dashboard: "Dashboard",
    users: "Usuarios",
    horses: "Caballos",
    places: "Ubicaciones",
    reproduction: "Reproducción",
    stock: "Stock",
    calendar: "Calendario",
    settings: "Configuración",
    page: "Pag.",
    of: "de",
    show: "Mostrar",
    records: "Registros",
    search: "Buscar",
    search_help_text: "Debe ingresar al menos {nro} caracteres...",
    city: "ciudad",
    father: "Padre",
    mother: "Madre",
    location: "Ubicación",
    locations: "Ubicaciones",
    "born on": "Nacido el",
    "clinic history": "Historia clínica",
    clinicHistory: "Historia clínica",
    clinicHistoryExport: "Historia clinica",
    "technical data": "Datos técnicos",
    sex: "Sexo",
    fur: "Pelaje",
    "general data": "datos generales",
    classification: "Clasificación",
    responsible: "Responsable",
    birthday: "Fecha de nacimiento",
    age: "Edad",
    passport: "Pasaporte",
    "AAFE sheet": "Ficha AAFE",
    "latest appointment": "última cita",
    awards: "premios",
    "dressage start": "Inicio doma",
    "dressage end": "Fin doma",
    observations: "Observaciones",
    backhorses: "Volver al listado de caballos",
    backhorsedata: "Volver a los datos del caballo",
    "latest jump": "último salto",
    "latest training": "última competencia",
    appointments: "citas",
    interventions: "intervenciones",
    intervention: "intervención",
    interventionDescription: "Descripción intervención",
    treatments: "tratamientos",
    treatment: "tratamiento",
    treatmentDescription: "Descripción tratamiento",
    treatmentStart: "Inicio tratamiento",
    treatmentEnd: "Fin tratamiento",
    mediacenter: "mediacenter",
    professional: "profesional",
    diagnosis: "diagnóstico",
    diagnosisDescription: "Descripción diagnóstico",
    diagnoses: "diagnósticos",
    start: "Inicio",
    end: "Fin",
    anamnesis: "anamnesis",
    next: "Siguiente",
    previous: "Anterior",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    eventsDay: "eventos del día",
    "new event": "nuevo evento",
    "edit event": "Editar evento",
    title: "Título",
    description: "Descripción",
    event: "Evento",
    "no data": "Sin datos",
    unknown: "Desconocido",
    name: "Nombre",
    "confirm delete": "¿Está seguro/a que desea eliminar?",
    "new horse": "Nuevo caballo",
    mare: "Yegua",
    veterinarian: "Veterinario/a",
    rider: "Jinete",
    AAFE: "AAFE",
    pedigree: "Pedigree",
    "successful creation": "Creación exitosa",
    "show web": "Mostrar en web",
    "edit horse": "Editar caballo",
    "successful editing": "Edición exitosa",
    address: "Dirección",
    phone: "Teléfono",
    personCharge: "Responsable",
    actions: "Acciones",
    "edit location": "Editar ubicación",
    "new location": "Nueva ubicación",
    "new user": "Nuevo usuario",
    "edit user": "Editar usuario",
    role: "Rol",
    administrator: "Administrador/a",
    manager: "Gestor/a",
    firstName: "Nombres",
    lastName: "Apellidos",
    email: "Correo electrónico",
    userName: "Nombre de usuario",
    repassword: "Repetir contraseña",
    horse: "Caballo",
    type: "Tipo",
    registrationNumber: "Matrícula",
    "check password": "Verifique la contraseña",
    date: "Fecha",
    insertDescription: "Inserte la descripción aquí...",
    "new appointment": "Nueva cita",
    "edit appointment": "Editar cita",
    issue: "Asunto",
    veterinarianAssistant: "Veterinario/a asistente",
    errorContact: "Hubo un error. Por favor, contáctese con soporte.",
    professionalAssistant: "Prof. asistente",
    addDiagnosis: "Agregar diagnóstico",
    addIntervention: "Agregar intervención",
    editDiagnosis: "Editar diagnóstico",
    newIntervention: "Nueva intervención",
    editIntervention: "Editar intervención",
    insertAnamnesis: "Inserte la anamnesis aquí...",
    "new diagnosis": "Nuevo diagnóstico",
    "edit diagnosis": "Editar diagnóstico",
    editAppointment: "Editar cita",
    editTreatment: "Editar tratamiento",
    addTreatment: "Agregar tratamiento",
    interventionType: "Tipo de intervención",
    "attach file": "Adjuntar archivo",
    "No file selected": "Archivo no seleccionado",
    attach: "Adjuntar",
    "Replace file": "Reemplazar archivo",
    "Upload file": "Subir archivo",
    "Replace files": "Reemplazar archivos",
    "Upload files": "Subir archivos",
    "new media": "Nuevo multimedia",
    "edit media": "Edición multimedia",
    cycle: "Ciclo",
    length: "Longitud",
    stallion: "Padrillo",
    castrated: "Macho castrado",
    "Horse created": "Caballo creado",
    general: "General",
    competition: "Competición",
    competitions: "Competiciones",
    link: "Enlace",
    period: "Período",
    education: "Educación",
    educationExport: "Educacacion",
    smithy: "Herrería",
    vaccination: "Vacunación",
    deworming: "Desparasitación",
    review: "Revisión",
    playback: "Reproducción",
    radiography: "Radiografía",
    surgery: "Cirugía",
    published: "Publicado",
    draft: "Borrador",
    news: "Noticias",
    insertObservation: "Inserte la observación aquí...",
    "insert url": "Inserte el enlace al documento",
    insertComment: "Insertar comentario",
    "new classification": "Nueva clasificación",
    "edit classification": "Editar clasificación",
    "next classification": "Siguiente clasificación",
    relatedHorseId: "Caballo relacionado",
    "initial classification": "Clasificación inicial",
    view: "Ver",
    edit: "Editar",
    delete: "Eliminar",
    status: "Estado",
    active: "Activo",
    jump: "Salto",
    club: "Club",
    training: "Concurso",
    trainings: "Concursos",
    meters: "metros",
    exit: "Salir",
    "insert stock classification":
      "Necesario insertar stock por la clasificación seleccionada",
    "insert dosis classification":
      "Necesario insertar dosis por la clasificación seleccionada",
    "insert stock and dosis classification":
      "Necesario insertar stock y dosis por la clasificación seleccionada",
    classificationDate: "Fecha de clasificación",
    "change password": "Cambiar contraseña",
    oldpassword: "Contraseña anterior",
    newpassword: "Nueva contraseña",
    total: "Total",
    user: "Usuario",
    changeclassification: "Cambiar clasificación",
    mole: "Creado por Mole-Dev",
    relatedHorse: "Caballo relacionado",
    lastStatus: "Estado final",
    startDate: "Fecha inicio",
    endDate: "Fecha fin",
    process: "Proceso",
    comment: "Comentario",
    cannotdelete: "No se puede eliminar este registro",
    surgical: "Quirúrgica",
    locationDate: "Fecha de traslado",
    alazán: "Alazán",
    tordillo: "Tordillo",
    zaino: "Zaino",
    transferDate: "Fecha de traslado",
    changeLocation: "Cambiar ubicación",
    transferHorse: "Trasladar caballo",
    loading: "Cargando",
    remove: "Remover",
    change: "Cambiar",
    birthday_short: "Nacimiento",
    chape: "Herraje",
    "mother mare": "Yegua madre",
    "father stallion": "Padrillo padre",
    "inseminated with father": "Inseminada con padre",
    "insemination date": "Fecha de inseminación",
    "estimated birthday": "Estimación de parto",
    "pregnant mares": "Yeguas Gestantes",
    pregnantMares: "Yeguas preñadas",
    inseminatedMares: "Yeguas inseminadas",
    transferredMares: "Yeguas transferidas",
    foalAtFootMares: "Yeguas con potrillo al pie",
    pregnant: "Preñadas",
    inseminated: "Inseminadas",
    transferred: "Transferidas",
    foalAtFoot: "Potrillo al pie",
    foal: "Potrillo",
    served: "Servidas",
    "best case": "Mejor escenario",
    faults: "Faltas",
    result: "Resultado",
    horse_List: "Listado_caballos",
    pregnant_mares: "Yeguas_gestantes",
    fibroscopy: "Fibroscopía",
    odontology: "Odontología",
    ultrasound: "Ecografía",
    infiltration: "Infiltración",
    "platelet rich plasma": "Plasma rico en plaquetas",
    "insert numbers only": "Insertar solo números",
    "access denied to page": "Acceso a la página denegado",
    "new passport": "Nuevo pasaporte",
    passports: "Pasaportes",
    arriveDate: "Fecha inicio",
    departureDate: "Fecha fin",
    semenStock: "Stock de semen",
    appointment: "Cita",
    appointmentDescription: "Descripción Cita",
    doses: "Dosis",
    showInWeb: "Mostrar en Web",
    recipientMare: "Yegua Receptora",
    recipientMares: "Yeguas Receptoras",
    donorMare: "Yegua Donante",
    attempts: "Intentos",
    effectiveness: "Efectividad(%)",
    sons: "Hijos",
    pregnancies: "Preñeces",
    motherMares: "Yeguas Madres",
    inServiceMares: "Yeguas en servicio",
    activeMare: "Yegua Activa",
    classificationName: "Clasificación",
    inseminationDate: "Fecha Inseminación",
    estimaedBirthDate: "Fecha Estimada Parto",
    assistant: "Asistente",
    completedTest: "Prueba Completa",
    export: "Exportar",
    reports: "Informes",
    owner: "Dueño",
    NewAppointment: "Se crearon {0} nuevas citas",
  },
};
