import React, {useContext} from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import {mob, mobText, desk, deskText} from '../utilities';
import { ThemeContext } from "../providers/theme";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Circle from '../components/Circle';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const Root = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: ${props => props.theme.palette.base};
    font-family: helvetica;
`
const Clip = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: ${mob(241)};
    background-image: url(${props => props.theme.url});
    background-position: top;
    background-size: cover;
`
const PlayButton = styled.button`
    all: unset;
    position: relative;
    width: ${mob(57)};
    height: ${mob(57)};
    border-radius: 50%;
    background-color: rgba(245, 245, 245, 0.69);
    svg{
      color: white;
      width: 100%;
      height: 100%;
    }
`
const Title = styled.h1`
    all: unset;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: ${mobText(16)};
    line-height: ${mobText(18)};
    color: #00271C;
    width: 100%;
    margin-top: ${mob(26)};
    margin-bottom: ${mob(11.9)};
    span{
      position: relative;
      box-sizing: border-box;
      width: ${mob(106)};
      height: 0px;
      border: 0.5px solid ${props => props.theme.palette.bgColor};
    }
`
const IconContainer = styled.div`
    position: relative;
    width: ${mob(68)};
    height: ${mob(68)};
`
const Subtitle = styled.div`
    position: relative;
    width: ${mob(261)};
    height: auto;
    font-size: ${mobText(10)};
    line-height: ${mobText(14)};
    text-align: center;
    color: ${props => props.theme.palette.font};
    margin-top: ${mob(16.1)};
    margin-bottom: ${mob(43)};
`
const TextSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${mob(240)};
    height: auto;
    font-size: ${mobText(10)};
    line-height: ${mobText(11)};
    color: #707070;
    text-align: left;
    gap: ${mob(26)};
`
const TextBox = styled.div`
  position: relative;
  width: 100%;
`
const Back = styled.div`
    position: relative;
    color: ${props => props.theme.palette.accent};
    width: ${mob(30)};
    height: ${mob(30)};
    margin-top: ${mob(35)};
    margin-bottom: ${mob(32)};
    svg{
        width: 100%;
        height: 100%;
    }
    @media(min-width: 1024px){
        width: ${desk(90)};
        height: ${desk(90)};
        margin-top: ${desk(203)};
    }
`

export default function Disciplinas() {

    const {type} = useParams();
    let theme = useContext(ThemeContext);
    const disciplinas = {
        'CyD':{
          'clip': require("../public/cyd.jpeg"),
          'title': 'Cría y Doma',
          'icon' : 'horse',
          'subtitle' : '"quote. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."',
          'text' : ['Durante la crianza, primero al pie de la madre, el producto está siempre en libertad y en constante familizarización con quién lo cuida. Creemos firmemente que el resultado de la cría en cuanto a salud y mansedumbre, depende en primer lugar de las personas que a diario cuidan y educan a los productos.',' Cuando los éstos llegan a los 3 años se procede a trasladarlos a un sitio dedicado especialmente a la doma. Previo al inicio, se realiza un exhaustivo control de la salud del equino. En dicho lugar conocerá un box, y complementará su estadía pasando buena parte del día en potreros individuales.', 'La tarea del domador es una continuidad del manejo delicado y dócil de la cría. Dependiendo del equino, la doma no lleva mucho más de 2 meses. Una vez terminada, los productos tienen un período de “descanso” o de asimilación de la educación proporcionada. Luego de éste proceso, el producto está listo para iniciar su educación para las primeras competencias.']
        }, 
        'educacion':{
          'clip': require("../public/edu.jpeg"),
          'title': 'Educación',
          'icon' : 'educacion',
          'subtitle' : '"quote. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."',
          'text' : ['Para la educación previa a las competencias, se desarrolla un programa a medida para para equino.','El programa inicia con un "entrenamiento en el llano" o equitación sin salto, continuando luego con ejercicios de pequeñas vallas o caballetes priorizando que el caballo no se aburra ni se lesione. El manejarse en forma relajada y con tiempo, es fundamental en esta etapa.', 'El jinete debe percibir continuamente que el caballo está siempre bien para afrontar las exigencias que aumentarán muy paulatinamente.', 'El primer período de entrenamiento será de 2 o 3 meses. Cuando el equino comprende perfectamente todas las ayudas y aborda los pequeños obstáculos con naturalidad, se recurre a un período de “descanso” o asimilación de esta educación.', 'La siguiente etapa será la de retomar la educación para que el equino no solamente comprenda y acepte las ayudas, sino que hará una o dos veces por semana ejercicios y breves recorridos de práctica.']
        }, 
        'competencia':{
          'clip': require("../public/comp.jpeg"),
          'title': 'Competencia',
          'icon' : 'galardon',
          'subtitle' : '"quote. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."',
          'text' : ['A los 4 años los productos comienzan con las primeras competencias, es decir las series 1, 2 y 3.', 'La concurrencia a las competencias y el desempeño del binomio, sirven para ajustar el programa de entrenamiento del equino. Durante éste proceso, se realiza un detallado seguimiento estadístico de cada participación.', 'Las etapas ulteriores a las competencias de los 6 años son las de selección de aquellos productos que muestren potencial para ser un equino competitivo en los grandes premios internacionales.']
        }
    }
    theme = {
      ...theme,
      'url' : disciplinas[type]['clip']
    }
    
  return (
      <Root>
        <Clip><PlayButton><PlayArrowIcon/></PlayButton></Clip>
        <Title><span/>{disciplinas[type]['title']}<span/></Title>
        <IconContainer><Circle type={disciplinas[type]['icon']} color={theme.palette.accent}/></IconContainer>
        <Subtitle>{disciplinas[type]['subtitle']}</Subtitle>
        <TextSection>
          {disciplinas[type]['text'].map((d, idx) => {
            return(
              <TextBox key={idx}>{d}</TextBox>
            )
          })}
        </TextSection>
        <Back><ArrowCircleLeftOutlinedIcon/></Back>
      </Root>
  )
}
