import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import SelectInput from "../inputs/SelectInput";
import { API_URL } from "../../config";
import InputImage from "../inputs/inputImage";
import axios from "axios";
import CheckInput from "../inputs/CheckInput";
import BasicEditor from "../textEditors/basicEditor";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 70vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
    max-height: 90vh;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "space-between"};
  width: 100%;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const InputGrid = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    gap: 0 10%;
  }
`;

const ContentImageInput = styled.div`
  width: 100%;
  height: 100%;
  grid-column: 2/3;
  grid-row: 1/4;
`;

const NewHorse = ({ horseData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const dispatch = useDispatch();

  const { loadingAction, loadingProgress } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [newHorse, setNewHorse] = React.useState(
    horseData
      ? {
          name: horseData.name,
          dateOfBirth: new Date(horseData.dateOfBirth),
          locationId: horseData.location
            ? {
                label: horseData.location.name,
                value: horseData.location.id,
              }
            : null,
          locationDate: new Date(horseData.locationDate),
          sex: horseData.sex
            ? {
                label: horseData.sex.value,
                value: horseData.sex.id,
              }
            : null,
          fur: horseData.fur
            ? {
                label: horseData.fur.value,
                value: horseData.fur.id,
              }
            : null,
          fatherId:
            horseData.father && horseData.father !== "null"
              ? {
                  label: horseData.father.name,
                  value: horseData.father.id,
                }
              : null,
          motherId:
            horseData.mother && horseData.mother !== "null"
              ? {
                  label: horseData.mother.name,
                  value: horseData.mother.id,
                }
              : null,
          idVeterinarian:
            horseData.veterinarian && horseData.veterinarian !== "null"
              ? {
                  label: horseData.veterinarian.fullName,
                  value: horseData.veterinarian.id,
                }
              : null,
          riderId: horseData.rider
            ? {
                label: horseData.rider?.fullName,
                value: horseData.rider?.id,
              }
            : null,
          ownerId: horseData.owner
            ? {
                label: horseData.owner?.fullName,
                value: horseData.owner?.id,
              }
            : null,
          AAFEFataSheet: horseData.AAFEFataSheet,
          pedigree: horseData.pedigree,
          passport: horseData.passport,
          imageProfile: horseData.imageProfile,
          showInWeb: horseData.showInWeb,
          stock: horseData.stock,
          dosis: horseData.dosis,
          observation: horseData.observation,
          classificationKey: null,
          relatedHorseId: null,
          status: horseData.status,
          classificationDate: new Date(),
        }
      : {
          name: "",
          dateOfBirth: new Date(),
          locationDate: new Date(),
          locationId: null,
          sex: null,
          fur: null,
          fatherId: null,
          motherId: null,
          idVeterinarian: null,
          riderId: null,
          ownerId: null,
          AAFEFataSheet: "",
          pedigree: "",
          passport: "",
          imageProfile: null,
          showInWeb: false,
          stock: 0,
          dosis: 0,
          observation: "",
          classificationKey: null,
          relatedHorseId: null,
          status: true,
          classificationDate: new Date(),
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewHorse({
      ...newHorse,
      [name]: value,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewHorse({
      ...newHorse,
      [name]: value,
    });
  };

  const handleCheck = (name, value) => {
    setNewHorse({
      ...newHorse,
      [name]: value,
    });
  };

  const handleChangeDateOfBirth = (newValue) => {
    console.log(newValue)
    setNewHorse({
      ...newHorse,
      dateOfBirth: newValue,
    });
  };

  const handleChangeLocationDate = (newValue) => {
    setNewHorse({
      ...newHorse,
      locationDate: newValue,
    });
  };

  const handleChangeClassificationDate = (newValue) => {
    setNewHorse({
      ...newHorse,
      classificationDate: newValue,
    });
  };

  const handleEditorDescription = (e, dataeditor) => {
    setNewHorse({
      ...newHorse,
      observation: dataeditor,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    loadingAction(loadingConstants.WAIT);
    var formdata = new FormData();
    formdata.append("name", newHorse.name);
    formdata.append("dateOfBirth", newHorse.dateOfBirth);
    formdata.append("locationId", newHorse.locationId?.value);
    formdata.append("locationDate", newHorse.locationDate);
    formdata.append("sex", newHorse.sex?.value);
    formdata.append("fur", newHorse.fur?.value);
    formdata.append("fatherId", newHorse.fatherId?.value);
    formdata.append("motherId", newHorse.motherId?.value);
    formdata.append("idVeterinarian", newHorse.idVeterinarian?.value);
    formdata.append("riderId", newHorse.riderId?.value);
    formdata.append("ownerId", newHorse.ownerId?.value);
    formdata.append("AAFEFataSheet", newHorse.AAFEFataSheet);
    formdata.append("pedigree", newHorse.pedigree);
    formdata.append("passport", newHorse.passport);
    formdata.append("showInWeb", newHorse.showInWeb === true ? 1 : 0);
    formdata.append("imageProfile", newHorse.imageProfile);
    formdata.append("stock", newHorse.stock);
    formdata.append("dosis", newHorse.dosis);
    formdata.append("observation", newHorse.observation);
    formdata.append("status", newHorse.status === true ? 1 : 0);
    if (horseData) {
      const url = `${API_URL}/horses/${horseData.id}`;
      axios
        .put(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          //Listen for the onuploadprogress event
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            //Using local progress events
            if (e.lengthComputable) {
              let progress = (loaded / total) * 100;
              loadingProgress(loadingConstants.PROGRESS, progress);
            }
          },
        })
        .then((response) => {
          loadingAction(loadingConstants.READY);
          handleClose();
          /* alert(intl.formatMessage({ id: 'successful editing' })); */
        })
        .catch((error) => {
          loadingAction(loadingConstants.READY);
          console.log(error);
        });
    } else {
      formdata.append("classificationKey", newHorse.classificationKey?.value);
      formdata.append("classificationDate", newHorse.classificationDate);
      formdata.append("relatedHorseId", newHorse.relatedHorseId?.value);
      const url = `${API_URL}/horses`;
      axios
        .post(url, formdata, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          //Listen for the onuploadprogress event
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            //Using local progress events
            if (e.lengthComputable) {
              let progress = (loaded / total) * 100;
              loadingProgress(loadingConstants.PROGRESS, progress);
            }
          },
        })
        .then((response) => {
          loadingAction(loadingConstants.READY);
          handleClose();
          /* alert(intl.formatMessage({ id: 'successful creation' })); */
        })
        .catch((error) => {
          loadingAction(loadingConstants.READY);
          console.log(error);
        });
    }
  };

  const onChangeImage = (files) => {
    if (files.length > 0) {
      setNewHorse({
        ...newHorse,
        imageProfile: files[0],
      });
    }
  };

  const onResetImage = () => {
    setNewHorse({
      ...newHorse,
      imageProfile: null,
    });
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Root onSubmit={handleAction}>
      <Title>
        {horseData ? translate("edit horse") : translate("new horse")}
      </Title>
      <InputGrid>
        <TextField
          id="name"
          name="name"
          onChange={handleChange}
          value={newHorse.name}
          label={intl.formatMessage({ id: "name" })}
          variant="outlined"
          fullWidth
          margin="dense"
          size="small"
          required
        />
        <ContentImageInput>
          <InputImage
            file={newHorse.imageProfile?.url}
            onChange={onChangeImage}
            onReset={onResetImage}
          />
        </ContentImageInput>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DatePicker
            renderInput={(props) => (
              <StyledTextfield
                size="small"
                margin="dense"
                fullWidth
                {...props}
                required
              />
            )}
            label={intl.formatMessage({ id: "birthday" })}
            value={newHorse.dateOfBirth}
            onChange={handleChangeDateOfBirth}
            required
          />
        </LocalizationProvider>
        {!horseData && (
          <SelectInput
            value={newHorse.locationId}
            fieldlabel="name"
            fieldvalue="id"
            dataurl={`${API_URL}/locations`}
            label={intl.formatMessage({ id: "location" })}
            name="locationId"
            onChange={handleChangeAutocomplete}
            width="100%"
            margin="dense"
            size="small"
            required
          />
        )}
        {!horseData && (
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              renderInput={(props) => (
                <StyledTextfield
                  size="small"
                  margin="dense"
                  fullWidth
                  {...props}
                />
              )}
              label={intl.formatMessage({ id: "locationDate" })}
              value={newHorse.locationDate}
              onChange={handleChangeLocationDate}
              required
            />
          </LocalizationProvider>
        )}
      </InputGrid>
      <InputGroup>
        <SelectInput
          value={newHorse.sex}
          fieldlabel="value"
          fieldvalue="id"
          dataurl={`${API_URL}/sex`}
          label={intl.formatMessage({ id: "sex" })}
          name="sex"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
          required
        />
        <SelectInput
          value={newHorse.fur}
          fieldlabel="value"
          fieldvalue="id"
          dataurl={`${API_URL}/fur`}
          label={intl.formatMessage({ id: "fur" })}
          name="fur"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
          required
        />
      </InputGroup>
      <InputGroup>
        <SelectInput
          value={newHorse.motherId}
          fieldlabel="name"
          fieldvalue="id"
          dataurl={`${API_URL}/horses/sexcode/M`}
          label={intl.formatMessage({ id: "mother" })}
          name="motherId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
        <SelectInput
          value={newHorse.fatherId}
          fieldlabel="name"
          fieldvalue="id"
          dataurl={`${API_URL}/horses/sexcode/S`}
          label={intl.formatMessage({ id: "father" })}
          name="fatherId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
      </InputGroup>
      <InputGroup>
        <SelectInput
          value={newHorse.idVeterinarian}
          fieldlabel="fullName"
          fieldvalue="id"
          dataurl={`${API_URL}/human/veterinarian`}
          label={intl.formatMessage({ id: "veterinarian" })}
          name="idVeterinarian"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
        <SelectInput
          value={newHorse.riderId}
          fieldlabel="fullName"
          fieldvalue="id"
          dataurl={`${API_URL}/human/peoples`}
          label={intl.formatMessage({ id: "rider" })}
          name="riderId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
      </InputGroup>
      <InputGroup>
        {/* <StyledTextfield id="AAFEFataSheet" name="AAFEFataSheet" onChange={handleChange} value={newHorse.AAFEFataSheet} label={intl.formatMessage({ id: 'AAFE' })} variant="outlined" width="45%" margin="dense" size="small" helperText={intl.formatMessage({ id: 'insert url' })} /> */}
        <StyledTextfield
          id="stock"
          type="number"
          name="stock"
          onChange={handleChange}
          value={newHorse.stock}
          label={intl.formatMessage({ id: "stock" })}
          variant="outlined"
          width="45%"
          margin="dense"
          size="small"
          helperText={
            newHorse.classificationKey && newHorse.classificationKey.extra2
              ? intl.formatMessage({ id: "insert stock classification" })
              : null
          }
          required
        />
        <StyledTextfield
          id="dosis"
          type="number"
          name="dosis"
          onChange={handleChange}
          value={newHorse.dosis}
          label={intl.formatMessage({ id: "dosis" })}
          variant="outlined"
          width="45%"
          margin="dense"
          size="small"
          helperText={
            newHorse.classificationKey && newHorse.classificationKey.extra2
              ? intl.formatMessage({ id: "insert dosis classification" })
              : null
          }
          required
        />
      </InputGroup>
      <InputGroup>
        <StyledTextfield
          id="pedigree"
          name="pedigree"
          onChange={handleChange}
          value={newHorse.pedigree}
          label={intl.formatMessage({ id: "pedigree" })}
          variant="outlined"
          width="45%"
          margin="dense"
          size="small"
          helperText={intl.formatMessage({ id: "insert url" })}
        />
        <StyledTextfield
          id="passport"
          name="passport"
          onChange={handleChange}
          value={newHorse.passport}
          label={intl.formatMessage({ id: "passport" })}
          variant="outlined"
          width="45%"
          margin="dense"
          size="small"
          helperText={intl.formatMessage({ id: "insert url" })}
        />
      </InputGroup>
      {!horseData && (
        <InputGrid>
          <SelectInput
            value={newHorse.classificationKey}
            fieldlabel="value"
            fieldvalue="key"
            fieldextra1="horseRequired"
            fieldextra2="stockRequired"
            dataurl={`${API_URL}/clasificationname`}
            label={intl.formatMessage({ id: "initial classification" })}
            name="classificationKey"
            onChange={handleChangeAutocomplete}
            width="100%"
            margin="dense"
            size="small"
            required
          />
          {newHorse.classificationKey && newHorse.classificationKey.extra1 && (
            <SelectInput
              value={newHorse.relatedHorseId}
              fieldlabel="name"
              fieldvalue="id"
              dataurl={
                Array("10", "11").includes(newHorse.classificationKey.key)
                  ? `${API_URL}/horses/sexcode/S`
                  : Array("4").includes(newHorse.classificationKey.key)
                  ? `${API_URL}/horses/sexcode/M`
                  : `${API_URL}/horses/list`
              }
              label={
                Array("10", "11").includes(newHorse.classificationKey.key)
                  ? intl.formatMessage({ id: "father stallion" })
                  : Array("4").includes(newHorse.classificationKey.key)
                  ? intl.formatMessage({ id: "mother mare" })
                  : intl.formatMessage({ id: "foal" })
              }
              name="relatedHorseId"
              onChange={handleChangeAutocomplete}
              width="45%"
              margin="dense"
              size="small"
            />
          )}

          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              renderInput={(props) => (
                <StyledTextfield
                  size="small"
                  margin="dense"
                  fullWidth
                  {...props}
                />
              )}
              label={intl.formatMessage({ id: "classificationDate" })}
              value={newHorse.classificationDate}
              onChange={handleChangeClassificationDate}
              required
            />
          </LocalizationProvider>
        </InputGrid>
      )}
      <InputGroup>
        <SelectInput
          value={newHorse.ownerId}
          fieldlabel="fullName"
          fieldvalue="id"
          dataurl={`${API_URL}/human/all`}
          label={intl.formatMessage({ id: "owner" })}
          name="ownerId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
      </InputGroup>

      <BasicEditor
        name="observation"
        value={newHorse.observation}
        onChange={handleEditorDescription}
        editorLoaded={editorLoaded}
        maxContentHeight="50px"
        minContentHeight="50px"
        placeholder={intl.formatMessage({ id: "insertObservation" })}
      />
      <InputGroup justifyContent="space-evenly">
        <CheckInput
          label={intl.formatMessage({ id: "show web" })}
          checked={newHorse.showInWeb}
          onChange={handleCheck}
          name="showInWeb"
        />
        <CheckInput
          label={intl.formatMessage({ id: "active" })}
          checked={newHorse.status}
          onChange={handleCheck}
          name="status"
        />
      </InputGroup>
      <Button bg={theme.palette.accent} color={theme.palette.base}>
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewHorse;
