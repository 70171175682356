import React from "react";
import styled from 'styled-components';
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import BasicCalendar from '../components/basicCalendar';

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 0 10px;
    background-color: #FFF;
    border-radius: 10px;
    @media (min-width: 1024px) {
        padding: 0 50px;
    }
`

const Title = styled.h2`
    position: relative;
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "red"};
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 50px);
    padding: 25px 0 0 0;
`

export default function CalendarPage()  {

    const theme = React.useContext(ThemeContext);

    return(
        <Root>
            <Title color={theme.palette.accent} borderColor={theme.palette.details}>{translate("calendar")}</Title>
            <Content>
                <BasicCalendar />
            </Content>
        </Root>
    )
};