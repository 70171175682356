import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { ThemeContext } from "../../providers/theme";
import StarIcon from '@mui/icons-material/Star';
import { useIntl } from 'react-intl';
import translate from "../../providers/i18n/translate";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditReproductionEvent from "./EditReproductionEvent";
import { Modal } from "@mui/material";
import { API_URL } from "../../config";
import axios from 'axios';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

/* Styled components */
const OptionsButton = styled.div`
position: absolute;
top: 10px;
right: 10px;
display: flex;
align-items: center;
justify-content: center;
width: 20px;
height: 20px;
z-index: 99;
cursor: pointer;
svg {
    opacity: ${props => props.showMore ? 1 : 0.5};
    transition: all 300ms ease-in-out;
}

svg:hover {
    opacity: 1;
    transform: scale(1.1);
}
`

const OptionsList = styled.ul`
position: absolute;
top: 100%;
right: 0;
width: 100px;
height: ${props => props.showMore ? "auto" : "0px"};
overflow-y: hidden;
z-index: 999;
background-color: #FFF;
opacity: ${props => props.showMore ? 1 : 0};
border-radius: 10px 0 10px 10px;
box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
transition: all 300ms ease-in-out;
`

const Option = styled.li`
width: 100%;
text-align: center;
padding: 5px 10px;
transition: all 300ms ease-in-out;
color: ${props => props.color ? props.color : "#000"};
&:hover {
    background-color: ${props => props.bgAccent ? `${props.bgAccent}50` : "grey"};
}
`

const TDate = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 0.75rem;
    padding-right: 10px;
`

const TStatus = styled.h3`

`

const TRelatedHorse = styled.h4`
    font-size: 0.85rem;
    margin: 10px 0;
    svg {
        color: ${props => props.mobileStyle ? props.theme.palette.principal : props.theme.palette.accent};
        font-size: 0.85rem;
        margin: 0 0 0 5px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    }
    svg:hover {
        transform: scale(1.1);
    }
`

const TimeEvent = ({data, index, last, refresh}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [ showMore, setShowMore ] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        refresh();
        setOpen(false);
    }

    const openMore = () => {
        setShowMore(!showMore);
    }

    const closeMore = () => {
        setShowMore(false);
    }

    const handleEdit = () => {
        setOpen(true);
        closeMore();
    }

    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals
        if( confirm(intl.formatMessage({ id: 'confirm delete' })) ){
            axios.delete(`${API_URL}/historyclassification/${data.id}`)
            .then(result => {
                refresh();
                closeMore();
            })
            .catch(err => {
                console.log(err);
            })
        }
        
    }

    return (
        <React.Fragment>
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentStyle={{ background: theme.palette.principal, color: '#fff' }}
                    contentArrowStyle={{ borderRight: `7px solid  ${theme.palette.principal}` }}
                    iconStyle={{ background: theme.palette.accent, color: '#fff' }}
                    icon={<StarIcon />}
                    position={index % 2 == 0 ? "left" : "right"}
                >
                    <OptionsButton showMore={showMore}>
                        <MoreVertIcon onClick={openMore} />
                        <OptionsList showMore={showMore}>
                            <Option onClick={handleEdit} color={theme.palette.principal} bgAccent={theme.palette.accent}>Editar</Option>
                            {
                                last &&
                                <Option onClick={handleDelete} color={theme.palette.principal} bgAccent={theme.palette.accent}>Eliminar</Option>
                            }
                        </OptionsList>
                    </OptionsButton>
                    <TDate>{ intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" }) }</TDate>
                    <TStatus>{data.clasificationName.value}</TStatus>
                    {
                        data.idRelatedHorse && 
                        <TRelatedHorse>{`${intl.formatMessage({ id: 'relatedHorse' })}: ${data.idRelatedHorse.name}`}<Link to={`/backoffice/horses/${data.idRelatedHorse.id}`} target="_blank"><OpenInNewIcon /></Link></TRelatedHorse>
                    }
                    <p dangerouslySetInnerHTML={{ __html: data.comment ? intl.formatMessage({ id: data.comment }) : '' }}  />
                </VerticalTimelineElement>
            </VerticalTimeline>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <EditReproductionEvent data={data} handleClose={handleClose} />
            </Modal>
        </React.Fragment>
    );
}

export default TimeEvent;