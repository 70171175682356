import React from "react";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const SwitchFilter = ({filter, value, onChange}) => {

    const handleChange = (e) => {
        const newValue = e.target.checked ? "true" : "false";
        onChange(filter.name, newValue);
    }

    return (
        <FormControlLabel 
            label={filter.label}
            control={<Switch size="small" checked={value === "true"} onChange={handleChange} />}  
        />
    )
}

export default SwitchFilter;