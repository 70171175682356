import React from "react";
import styled from 'styled-components';
import AccordionRow from "../tables/table/AccordionRow";
import HeadAccordion from "../tables/table/HeadAccordion";
import ContentAccordion from "../tables/table/ContentAccordion";
import translate from "../../providers/i18n/translate";
import Competition from "./Competition";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const Title = styled.div`
width: 100%;
display: flex;
height: 100%;
align-items: center;
justify-content: flex-start;
`

const Date = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
margin: 0 10px 0 0;
`

const Name = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100%;
`

const EditIcon = styled.div`
position: relative;
display: flex;
align-items: center;
justify-content: flex-end;
width: 100%;
height: 25px;
cursor: pointer;
z-index: 99;
svg {
    font-size: 1.25rem;
}
`

const Headers = styled.div`
    position: relative;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid #000;
    @media (min-width: 1024px) {
        display: flex;
    }
`

const Field = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: ${props => props.width ? props.width : "auto"};
    height: 30px;
    font-weight: 700;
`

const StyledDoubleArrowIcon = styled(DoubleArrowIcon)`
    margin: 0 5px;
`


const CompetitionAccordion = ({data}) => {

    return (
        <AccordionRow>
            <HeadAccordion>
                <Title>
                    <Name>{`${data.horseRider?.firstName} ${data.horseRider?.lastName}`}</Name>
                    <StyledDoubleArrowIcon />
                    <Name>{data.horse?.name}</Name>
                </Title>
            </HeadAccordion>
            <ContentAccordion>
                <Headers>
                    <Field width="45%">{translate('club')}</Field>
                    <Field width="15%">{translate('date')}</Field>
                    <Field width="10%">{translate('jump')}</Field>
                    <Field width="10%">{translate('faults')}</Field>
                    <Field width="10%">{translate('result')}</Field>
                    <Field width="10%">{translate('video')}</Field>
                </Headers>
                {
                    data.competitions && data.competitions.map((comp, index) =>
                        <Competition key={`competition_${index}`} data={comp} />   
                    )
                }
            </ContentAccordion>
        </AccordionRow>
    )

}

export default CompetitionAccordion;