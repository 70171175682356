import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { API_URL } from '../../config';
import axios from 'axios';
import BasicEditor from '../textEditors/basicEditor';
import SelectInput from '../inputs/SelectInput';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextField } from '@mui/material';

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 600px;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: ${props => props.width ? props.width : "inherit"};
`

const EditReproductionEvent = ({id, data, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const [ newClassification, setNewClassification ] = React.useState(
        {
            relatedHorseId: null,
            comment: data.comment,
            date: new Date(data.date)
        }
    )

    const handleEditorComment = (e, dataeditor) => {
        setNewClassification({
            ...newClassification,
            comment: dataeditor
        })
    }

    const handleChangeDate = (newValue) => {
        setNewClassification({
            ...newClassification,
            date: newValue
        });
    }


    const handleAction = (e) => {
        e.preventDefault();
        var formdata = new FormData();
        /* formdata.append("relatedHorseId", newClassification.relatedHorseId?.value); */
        formdata.append("comment", newClassification.comment);
        formdata.append("date", newClassification.date);
        const url = `${API_URL}/historyclassification/${data.id}`;
        axios.put(url, formdata,
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
            }
            })
        .then(response => {
            handleClose();
        })
        .catch(error => {
            console.log(error);
        })
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

    return (
        <Root>
            <Title>{translate("edit classification")}</Title>
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    renderInput={(props) => <StyledTextfield size="small" margin="dense" fullWidth {...props} />}
                    label={intl.formatMessage({ id: 'date' })}
                    value={newClassification.date}
                    onChange={handleChangeDate}
                    required
                />
            </LocalizationProvider>
            <BasicEditor
                name="comment"
                value={newClassification.comment}
                onChange={handleEditorComment}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: 'insertComment' })}
            />
            <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
}

export default EditReproductionEvent;