import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Root = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  .ck-editor__editable {
    max-height: ${(props) =>
      props.maxContentHeight ? props.maxContentHeight : "200px"};
    min-height: ${(props) =>
      props.minContentHeight ? props.minContentHeight : "200px"};
  }
`;

function BasicEditor({
  onChange,
  editorLoaded,
  name,
  value,
  minContentHeight,
  maxContentHeight,
  placeholder,
  readOnly,
}) {
  const editorRef = useRef();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  const editorConfig = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "fontColor",
        "fontSize",
        "|",
        "alignment",
        "bulletedList",
        "numberedList",
        "|",
        "link",
        "blockQuote",
        "undo",
        "redo",
        "removeFormat",
      ],
    },
    placeholder: placeholder ? placeholder : "Ingrese un texto...",
    isReadOnly: true,
    disabled: true,
  };

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("ckeditor5-build-full"),
    };
  }, []);

  return (
    <Root
      minContentHeight={minContentHeight}
      maxContentHeight={maxContentHeight}
    >
      {editorLoaded ? (
        <CKEditor
          type=""
          name={name}
          editor={ClassicEditor}
          data={value}
          config={editorConfig}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(event, data);
          }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </Root>
  );
}

export default BasicEditor;
