import React from "react";
import styled from 'styled-components';
import { Link, useNavigate, useParams } from "react-router-dom";
import translate from "../../providers/i18n/translate";
import { API_URL } from "../../config";
import axios from 'axios';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import NewClassification from "./NewClassification";
import { Modal } from "@mui/material";

const Root = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`
const BoxText = styled.p`
    width: ${props => props.sidebar ? "100%" : "100%"};
    display: ${(props) => props.hide ? "none" : "flex"};
    align-items: flex-start;
    justify-content: flex-start;
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : "inherit"};
    color: ${props => props.mobileStyle ? props.theme.palette.principal : props.theme.palette.accent};
    font-size: 1rem;
    height: 18px;
    font-weight: 600;
    transition: all 300ms ease-in-out;
    margin-top: 0.1rem;
    line-height: 1;
    order: ${(props) => props.sidebar ? props.order : "inherit"};
    svg {
        color: ${props => props.mobileStyle ? props.theme.palette.principal : props.theme.palette.accent};
        font-size: 1rem;
        margin: 0 0 0 3px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    }
    svg:hover {
        transform: scale(1.1);
    }
    @media (min-width: 1024px) {
        svg {
            font-size: ${props => props.sidebar ? "0.75rem" : "0.9em" };
        }
        }
        font-size: 0.9rem;
        color: ${props => props.theme.palette.accent};
`
const ContainerChangeLocation = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap:1em;
`

const BoxContainer= styled.div`
    width: 50%;
`

const BoxSubtitle = styled.h3`
    width: 100%;
    display: ${(props) => props.hide ? "none" : "flex"};
    align-items: flex-end;
    justify-content: flex-start;
    grid-column: ${(props) => props.gridColumn ? props.gridColumn : "inherit"};
    text-transform: capitalize;
    color: ${props => props.theme.palette.secundary};
    font-size: 1rem;
    height: 18px;
    font-weight: 600;
    transition: all 300ms ease-in-out;
    @media (min-width: 1024px) {
        font-size: 0.9rem;
        line-height: 1;
        margin-top: 0.3rem;
    }
`

const ChangeButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: ${props => props.sidebar ? "0.65rem" : "0.7rem"};
    border-radius: 5px;
    background-color: ${props => props.theme.palette.principal};
    width: ${props => props.sidebar ? "120px" : "175px"};
    cursor: pointer;
    text-transform: uppercase;
    transition: all 300ms ease-in-out;
    color: ${props => props.theme.palette.base};
    border: 1px solid ${props => props.theme.palette.principal};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    box-shadow: none;
    outline: none;
    margin: 0;
    padding: 3px 2px;
    svg {
        font-size: 1rem;
    }
    &:hover {
        color: ${props => props.theme.palette.base};
        background-color: ${props => props.theme.palette.accent};
        border: 1px solid ${props => props.theme.palette.accent};
        a{
            color: ${props => props.theme.palette.base};
        }
    }
    a{
        text-decoration: none;
        color: ${props => props.theme.palette.accent};
    }
    @media (min-width: 1024px) {
        height: 30px;
    }
`
const LastClassificacion = ({id, sidebar}) => {

    const [ classification, setClassification ] = React.useState(null);

    const [ openNewClassification, setOpenNewClassification ] = React.useState(false);

    const handleChangeClassification = () => {
        setOpenNewClassification(true);
    }

    const handleCloseNewClassification = () => {
        getClassification();
        setOpenNewClassification(false);
    }

    const getClassification = () => {
        axios.get(`${API_URL}/historyclassification/lastbyhorse/${id}`)
        .then(result => {
            setClassification(result.data);
        })
        .catch(err => {
            console.log(err);
        })
    }

    React.useEffect(() => {
        if(!classification){
            getClassification();
        }
    },[classification])

    return (
        classification &&
        <React.Fragment>
            <ContainerChangeLocation>
                <BoxContainer>
                    <BoxSubtitle sidebar={sidebar}>{translate("classification")}</BoxSubtitle>
                    <BoxText sidebar={sidebar}>{classification.clasificationName.value}</BoxText>
                </BoxContainer>
                <ChangeButton sidebar={sidebar} onClick={handleChangeClassification}>{translate('changeclassification')}</ChangeButton>
            </ContainerChangeLocation>
            
            <Modal
                open={openNewClassification}
                onClose={handleCloseNewClassification}
            >
                <NewClassification id={id} actualStatus={classification.clasificationName} handleClose={handleCloseNewClassification} />
            </Modal>
        </React.Fragment>
    );
}

export default LastClassificacion;
