import React from "react";
import { Outlet } from "react-router-dom";
import styled from 'styled-components';
import ReportsNav from "../components/navbars/reportsNav";

/* Styled components */

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFF;
    border-radius: 10px;
`

const ReportsPage = () => {

    return (
        <Root>
            <ReportsNav>
                <Outlet/>
            </ReportsNav>
        </Root>
    );
}

export default ReportsPage;