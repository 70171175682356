import React from "react";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";
import Row from "./Row";
import Cell from "./Cell";
import { useIntl } from 'react-intl';


const Root = styled.tbody`
    width: 100%;
    height: 100%;
`

const Actions = styled.div`
    display: flex;
    height: 100%;
`

const Action = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 2px;
    cursor: pointer;
    color: ${(props) => props.color ? props.color : "#000"};
    ${(props) => props.center ? 'margin: auto; padding-left: 10px;' : ''};
    &:hover {
        transform: scale(1.1);
        color: ${(props) => props.hoverColor ? props.hoverColor : "#707070"};;
    }
`


const Body = ({ rowsTable, rawData, columnsTable, actions }) => {

    const intl = useIntl();

    const handleView = (e) => {
        e.preventDefault();
        const { index } = e.currentTarget.dataset;
        actions.view.onClick(rowsTable[index]);
    }

    const handleEdit = (e) => {
        e.preventDefault();
        const { index } = e.currentTarget.dataset;
        actions.edit.onClick(rawData[index]);
    }

    const handleDelete = (e) => {
        e.preventDefault();
        const { index } = e.currentTarget.dataset;
        actions.delete.onClick(rowsTable[index]);
    }

    let ConvertStringToHTML = function (str) {
        let parser = new DOMParser();
        let doc = parser.parseFromString(str, 'text/html');
        return (doc.body.innerText);
    }

    const dateFormat = function (date) {
        return new Date(date).getUTCDate() + "/" + (new Date(date).getUTCMonth() + 1) + "/" + new Date(date).getUTCFullYear();
    }

    return (
        <Root>
            {
                rowsTable && rawData && rowsTable.map((rt, index) =>
                    <Row key={`row_${index}`}>
                        {
                            columnsTable && columnsTable.map((c, indexc) =>
                                !c.actions &&
                                <Cell key={`cell_${indexc}`} width={c.width} fixedWidth={c.fixedWidth} hidden={c.hidden} hiddenMobile={(c.mobile !== null && c.mobile !== undefined) ? !c.mobile : false} align={c.cellAlign} color={c.cellColor}>
                                    {c.render ? rawData[index] && c.render(rawData[index]) : c.type === "date" ? dateFormat(rt[c.field]) : c.html && rt[c.field] ? ConvertStringToHTML(rt[c.field]) : (c.decimalPlaces && !isNaN(rt[c.field]) && !isNaN(parseFloat(rt[c.field]))) ? parseFloat(rt[c.field]).toFixed(c.decimalPlaces) : c.translate ? intl.formatMessage({ id: rt[c.field] }) : rt[c.field]}
                                </Cell>
                            )
                        }
                        {
                            <Cell width="auto" align="center" hidden={columnsTable.find(col => col.actions).hidden} hiddenMobile={columnsTable.find(col => col.actions).hiddenMobile}>
                                <Actions>
                                    {
                                        actions.view && !actions.view.hidden &&
                                        <Action
                                            color={actions.view.color}
                                            hoverColor={actions.view.hoverColor}
                                            data-index={index}
                                            onClick={handleView}
                                        >
                                            {actions.view.icon}
                                        </Action>
                                    }
                                    {
                                        actions.edit && !actions.edit.hidden &&
                                        <Action
                                            color={actions.edit.color}
                                            hoverColor={actions.edit.hoverColor}
                                            data-index={index}
                                            onClick={handleEdit}
                                        >
                                            {actions.edit.icon}
                                        </Action>
                                    }
                                    {
                                        actions.delete && !actions.delete.hidden &&
                                        <Action
                                            color={actions.delete.color}
                                            hoverColor={actions.delete.hoverColor}
                                            data-index={index}
                                            onClick={handleDelete}
                                        >
                                            {actions.delete.icon}
                                        </Action>
                                    }
                                    {
                                        Object.keys(actions).filter(a => a !== "view" && a !== "edit" && a !== "delete").map((freeaction, indexAc) =>
                                            <Action
                                                color={actions[freeaction].color}
                                                center={actions[freeaction].center}
                                                hoverColor={actions[freeaction].hoverColor}
                                                data-index={index}
                                                data-action={actions[freeaction].onClick}
                                                onClick={() => actions[freeaction].onClick(rowsTable[index])}
                                            >
                                                {actions[freeaction].icon}
                                            </Action>
                                        )
                                    }
                                </Actions>
                            </Cell>
                        }
                    </Row>
                )
            }
        </Root>
    );
}

export default Body;
