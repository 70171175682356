import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { API_URL } from "../../config";
import axios from "axios";
import { Modal } from "@mui/material";
import { useIntl } from "react-intl";
import ChangeLocation from "./ChangeLocation";

const BoxSubtitle = styled.h3`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.secundary};
  font-size: 0.8rem;
  height: 18px;
  font-weight: 400;
  transition: all 300ms ease-in-out;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  margin-top: 9px;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.secundary};
  }
`;

const BoxText = styled.p`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  color: ${(props) =>
    props.mobileStyle
      ? props.theme.palette.principal
      : props.theme.palette.accent};
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-top: 0.1rem;
  line-height: 1;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  svg {
    color: ${(props) =>
      props.mobileStyle
        ? props.theme.palette.principal
        : props.theme.palette.accent};
    font-size: 0.8rem;
    margin: 0 0 0 3px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }
  svg:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1024px) {
    svg {
      font-size: ${(props) => (props.sidebar ? "0.75rem" : "0.9em")};
    }
  }
  color: ${(props) => props.theme.palette.accent};
`;

const ChangeButton = styled.button`
  position: relative;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.sidebar ? "0.65rem" : "0.65rem")};
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.principal};
  width: ${(props) => (props.sidebar ? "150px" : "150px")};
  cursor: pointer;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  color: ${(props) => props.theme.palette.base};
  border: 1px solid ${(props) => props.theme.palette.principal};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: none;
  outline: none;
  padding: 3px 2px;
  margin-top: 8px;
  svg {
    font-size: 1rem;
  }
  &:hover {
    color: ${(props) => props.theme.palette.base};
    background-color: ${(props) => props.theme.palette.accent};
    border: 1px solid ${(props) => props.theme.palette.accent};
    a {
      color: ${(props) => props.theme.palette.base};
    }
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.base};
  }
  @media (min-width: 1024px) {
    height: 30px;
  }
`;

const LastLocation = ({ id, sidebar, passport, pedigree, rider, owner }) => {
  const intl = useIntl();

  const [openNewLocation, setOpenNewLocation] = React.useState(false);
  const [location, setLocation] = React.useState(null);

  const handleChangeLocation = () => {
    setOpenNewLocation(true);
  };

  const handleCloseNewLocation = () => {
    getLocation();
    setOpenNewLocation(false);
  };

  const getLocation = () => {
    axios
      .get(`${API_URL}/horses/${id}/lastLocation`)
      .then((result) => {
        setLocation(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (!location) {
      getLocation();
    }
  }, [location]);

  console.log(passport, pedigree);
  return (
    location && (
      <React.Fragment>
        <BoxSubtitle sidebar={sidebar} order={1}>
          {translate("location")}
        </BoxSubtitle>
        <BoxText sidebar={sidebar} order={3}>
          {location?.location?.name}
        </BoxText>
        <BoxSubtitle sidebar={sidebar} order={2}>
          {translate("transferDate")}
        </BoxSubtitle>
        <BoxText sidebar={sidebar} order={4}>
          {intl.formatDate(new Date(`${location.date}:00:00:00`), {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          })}
        </BoxText>
        <BoxSubtitle sidebar={sidebar} order={6}>
          {translate("personCharge")}
        </BoxSubtitle>

        <BoxText
          sidebar={sidebar}
          order={8}
        >{`${location?.location?.personCharge?.firstName} ${location?.location?.personCharge?.lastName}`}</BoxText>
        <ChangeButton
          sidebar={sidebar}
          order={14}
          onClick={handleChangeLocation}
        >
          {translate("changeLocation")}
        </ChangeButton>

        {passport !== "" ? (
          <ChangeButton sidebar={sidebar} order={16}>
            <a href={passport} target="_blank">
              {translate("passport")}
            </a>
          </ChangeButton>
        ) : null}

        {pedigree !== "" ? (
          <ChangeButton sidebar={sidebar} order={15}>
            <a href={pedigree} target="_blank">
              {translate("pedigree")}
            </a>
          </ChangeButton>
        ) : null}

        <BoxSubtitle sidebar={sidebar} order={9}>
          {translate("rider")}
        </BoxSubtitle>
        {rider?.fullName ? (
          <BoxText sidebar={sidebar} order={11}>
            {rider.fullName}
          </BoxText>
        ) : null}

        <BoxSubtitle sidebar={sidebar} order={10}>
          {translate("owner")}
        </BoxSubtitle>
        {rider?.fullName ? (
          <BoxText sidebar={sidebar} order={12}>
            {owner?.fullName}
          </BoxText>
        ) : null}

        <Modal open={openNewLocation} onClose={handleCloseNewLocation}>
          <ChangeLocation id={id} handleClose={handleCloseNewLocation} />
        </Modal>
      </React.Fragment>
    )
  );
};

export default LastLocation;
