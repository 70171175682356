import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 95%;
    height: auto;
    box-shadow: 0 0 2px rgba(0,0,0,0.5);
    border-radius: 8px;
    padding: 5px;
    margin: 5px 0;
`

const EventDate = styled.span`
    font-size: 0.875rem;
    font-weight: 300;
    color: ${props => props.color ? props.color : "#000"};
`

const Description = styled.div`
    width: 100%;
    height: 100%;
    grid-column: 1/3;
    padding: 0 0 0 5px;
`

const Event = ({event}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    return (
        <Root borderColor={theme.palette.principal}>
            <EventDate color={theme.palette.details}>
                <span>{`${intl.formatTime(event.start)} - ${intl.formatTime(event.end)}`}</span>
            </EventDate>
            <Description>{event.title}</Description>
        </Root>
    );
}

export default Event;