import React from "react";
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import translate from "../../../providers/i18n/translate";
import { ThemeContext } from "../../../providers/theme";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const Nav = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 40px;
    border-top: 1px solid ${props => props.border ? props.border : "#000"};
    border-bottom: 1px solid ${props => props.border ? props.border : "#000"};
    background-color: ${props => props.theme.palette.principal};
    @media (min-width: 1024px) {
        justify-content: flex-start;
    }
`

const Divider = styled.div`
    display: none;
    width: 1px;
    height: 95%;
    background-color: ${props => props.color ? props.color : "#000"};
    @media (min-width: 1024px) {
        display: inherit;
    }
`

const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${(props) => props.color ? props.color : "#D1AC00"};
    font-size: 1rem;
    text-transform: uppercase;
    opacity: 0.5;
    margin: 0;
    &.active {
        opacity: 1;
    }
    @media (min-width: 1024px) {
        font-size: 1rem;
        margin: 0 20px;
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 30px);
    overflow-y: auto;
`

const MediaCenterNav = ({children}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root>
            <Nav>
                <Item to="" end color={theme.palette.base}>{translate("general")}</Item>
                <Divider color={theme.palette.base} />
                <Item to="education" end color={theme.palette.base}>{translate("education")}</Item>
                <Divider color={theme.palette.base} />
                <Item to="training" color={theme.palette.base}>{translate("trainings")}</Item>
            </Nav>
            <Content>
                {children}
            </Content>
        </Root>
    );
}

export default MediaCenterNav;