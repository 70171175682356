import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { API_URL } from "../../config";
import axios from "axios";
import { Modal, colors } from "@mui/material";
import { useIntl } from "react-intl";
import ChangeLocation from "./ChangeLocation";

const Root = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
`;

const Classification = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  color: ${(props) => props.theme.palette.secundary};
  font-size: 0.8rem;
`;
const ContainerChangeLocation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1em;
`;

const BoxContainer = styled.div`
  width: 50%;
`;
const BoxSubtitle = styled.h3`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-end;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.secundary};
  font-size: 0.8rem;
  height: 18px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  @media (min-width: 1024px) {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 0.3rem;
    color: ${(props) => props.theme.palette.secundary};
  }
`;

const BoxText = styled.p`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  color: ${(props) =>
    props.mobileStyle
      ? props.theme.palette.principal
      : props.theme.palette.accent};
  font-size: 1rem;
  height: 18px;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-top: 0.1rem;
  line-height: 1;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  svg {
    color: ${(props) =>
      props.mobileStyle
        ? props.theme.palette.principal
        : props.theme.palette.accent};
    font-size: 0.8rem;
    margin: 0 0 0 3px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }
  svg:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1024px) {
    svg {
      font-size: ${(props) => (props.sidebar ? "0.75rem" : "0.9em")};
    }
  }
  font-size: 0.9rem;
  color: ${(props) => props.theme.palette.accent};
`;
const ChangeButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: ${(props) => (props.sidebar ? "0.65rem" : "0.7rem")};
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.principal};
  width: ${(props) => (props.sidebar ? "120px" : "175px")};
  cursor: pointer;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  color: ${(props) => props.theme.palette.base};
  border: 1px solid ${(props) => props.theme.palette.principal};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: none;
  outline: none;
  padding: 3px 2px;
  svg {
    font-size: 1rem;
  }
  &:hover {
    color: ${(props) => props.theme.palette.base};
    background-color: ${(props) => props.theme.palette.accent};
    border: 1px solid ${(props) => props.theme.palette.accent};
    a {
      color: ${(props) => props.theme.palette.base};
    }
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.base};
  }
  @media (min-width: 1024px) {
    height: 30px;
  }
`;

const LastLocation = ({ id, sidebar, pedigree, passport, rider, owner }) => {
  const intl = useIntl();

  const [openNewLocation, setOpenNewLocation] = React.useState(false);
  const [location, setLocation] = React.useState(null);

  const handleChangeLocation = () => {
    setOpenNewLocation(true);
  };

  const handleCloseNewLocation = () => {
    getLocation();
    setOpenNewLocation(false);
  };

  const getLocation = () => {
    axios
      .get(`${API_URL}/horses/${id}/lastLocation`)
      .then((result) => {
        setLocation(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (!location) {
      getLocation();
    }
  }, [location]);

  return (
    location && (
      <Root>
        <ContainerChangeLocation>
          <BoxContainer>
            <BoxSubtitle sidebar={sidebar}>{translate("location")}</BoxSubtitle>
            <BoxText sidebar={sidebar}>{location?.location?.name}</BoxText>
          </BoxContainer>
          <ChangeButton sidebar={sidebar} onClick={handleChangeLocation}>
            {translate("changeLocation")}
          </ChangeButton>
        </ContainerChangeLocation>
        <ContainerChangeLocation>
          <BoxContainer>
            <BoxSubtitle sidebar={sidebar}>
              {translate("transferDate")}
            </BoxSubtitle>
            <BoxText sidebar={sidebar}>
              {intl.formatDate(new Date(`${location.date}:00:00:00`), {
                year: "numeric",
                month: "numeric",
                day: "numeric",
              })}
            </BoxText>
          </BoxContainer>

          {pedigree ? (
            <ChangeButton sidebar={sidebar}>
              <a href={pedigree} rel="noreferrer" target="_blank">
                {translate("pedigree")}
              </a>
            </ChangeButton>
          ) : null}
        </ContainerChangeLocation>

        <ContainerChangeLocation>
          <BoxContainer>
            <BoxSubtitle sidebar={sidebar}>
              {translate("personCharge")}
            </BoxSubtitle>
            <BoxText
              sidebar={sidebar}
            >{`${location?.location?.personCharge?.firstName} ${location?.location?.personCharge?.lastName}`}</BoxText>
          </BoxContainer>

          {passport ? (
            <ChangeButton sidebar={sidebar}>
              <a href={passport} rel="noreferrer" target="_blank">
                {translate("passport")}
              </a>
            </ChangeButton>
          ) : null}
        </ContainerChangeLocation>

        <ContainerChangeLocation>
          <BoxContainer>
            <BoxSubtitle sidebar={sidebar}>{translate("rider")}</BoxSubtitle>
            <BoxText sidebar={sidebar}>{rider?.fullName}</BoxText>
          </BoxContainer>
        </ContainerChangeLocation>

        <ContainerChangeLocation>
          <BoxContainer>
            <BoxSubtitle sidebar={sidebar}>{translate("owner")}</BoxSubtitle>
            <BoxText sidebar={sidebar}>{owner?.fullName}</BoxText>
          </BoxContainer>
        </ContainerChangeLocation>

        <Modal open={openNewLocation} onClose={handleCloseNewLocation}>
          <ChangeLocation id={id} handleClose={handleCloseNewLocation} />
        </Modal>
      </Root>
    )
  );
};

export default LastLocation;
