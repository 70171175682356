import Edit from "@mui/icons-material/Edit";
import { Modal, Tooltip } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import Treatment from "./Treatment";
import AddIcon from '@mui/icons-material/Add';
import NewTreatment from "../appointment/diagnosis/treatments/NewTreatment";

/* Styled components */

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0 10px 0;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    padding: 10px;
    border-radius: 8px;
`

const Content = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0 0 0 10px;
    @media (min-width: 1024px) {
        padding: 0 0 0 20px;
    }
`

const DiagnosisGrid = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px 0;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 5px;    
        flex-direction: inherit;
        align-items: inherit;
        justify-content: inherit;
    }
`

const TreatmentsGrid = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
        flex-direction: inherit;
        align-items: inherit;
        justify-content: inherit;
    }
`

const Title = styled.h3`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    color: ${props => props.theme.palette.accent};
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 10px 0 0 0;
    @media (min-width: 1024px) {
        order: inherit;
        margin: 5px 0 0 0;
    }
`

const Name = styled.p`
    width: 100%;
    font-size: 0.875rem;
    color: ${props => props.theme.palette.details};
    text-transform: uppercase;
`

const Anamnesis = styled.p`
    width: 100%;
    font-size: 0.85rem;
    order: ${props => props.order ? props.order : "inherit"};
    @media (min-width: 1024px) {
        order: inherit;
    }
`

const Description = styled.p`
    width: 100%;
    font-size: 0.85rem;
    order: ${props => props.order ? props.order : "inherit"};
    @media (min-width: 1024px) {
        order: inherit;
    }
`
const EditIcon = styled.div`
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 99;
    svg {
        font-size: 1.25rem;
    }
`

const AddButton = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    box-shadow: none;
    border: none;
    outline: none;
    margin: 0 10px;
    svg {
        font-size: 0.75rem;
    }
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
`


const Diagnosis = ({data, handleEdit, refresh}) => {

    const intl = useIntl();

    const handleEditDiagnosis = () => {
        handleEdit(data);
    }

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        refresh()
        setOpen(false);
    }
    const handleCloseEdit = () => {
        refresh();
        setOpenEdit(false);
        
    }

    const [ editTreatment, setEditTreatment ] = React.useState(null);

    const handleNewTreatment = () => {
        handleOpen();
    }
    
    const handleEditTreatment = (data) => {
        setEditTreatment(data);
        setOpenEdit(true);
    }

    return (
        <Root>
            <Tooltip title={intl.formatMessage({ id: 'editDiagnosis' })}>
                <EditIcon ><Edit onClick={handleEditDiagnosis} /></EditIcon>
            </Tooltip>
            <Name>{data.name}</Name>
            <Content>
                <DiagnosisGrid>
                    <Title order={0}>{translate("anamnesis")}</Title>
                    <Title order={2}>{translate("observations")}</Title>
                    <Anamnesis  order={1} dangerouslySetInnerHTML={{ __html: data.anamnesis }} />
                    <Description order={3} dangerouslySetInnerHTML={{ __html: data.description }} />
                </DiagnosisGrid>
                <Title>
                    {translate("treatments")}
                    <Tooltip title={intl.formatMessage({ id: 'addTreatment' })}>
                        <AddButton><AddIcon onClick={handleNewTreatment} /></AddButton>
                    </Tooltip>
                </Title>
                <TreatmentsGrid>
                    {
                        data.treatments && data.treatments.length > 0 && data.treatments.map((treat, index) =>
                            <Treatment key={`treatment_${index}_${treat.id}`} data={treat} handleEdit={handleEditTreatment} />
                        )
                    }
                </TreatmentsGrid>
            </Content>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <NewTreatment diagnosisId={data.id} handleClose={handleClose} />
            </Modal>
            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <NewTreatment diagnosisId={data.id} data={editTreatment} handleClose={handleCloseEdit} />
            </Modal>
        </Root>
    );
}

export default Diagnosis;