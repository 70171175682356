import React from "react";
import styled, {css} from 'styled-components';
import {Table, SearchInput, Filters, FilterGroup} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from 'axios';
import { useNavigate, Link, useParams } from "react-router-dom";
import { useIntl } from 'react-intl';
import { API_URL } from "../config";
import NewLocation from "../components/location/NewLocations";
import { Modal } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import SwitchFilter from "../components/tables/table/filters/SwitchFilter";
import CheckFilter from "../components/tables/table/filters/CheckFilter";
import DateFilter from "../components/tables/table/filters/DateFilter";

/* Styled components */

const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    padding: 0;
    @media (min-width: 1024px) {
        padding: 0;
    }
`

const Title = styled.h2`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    color: ${(props) => props.color ? props.color : '#000'};
    border-bottom: 1px solid rgba(0,0,0,0.2);
`

const Content = styled.div`
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 10px;
    @media (min-width: 1024px) {
        padding: 20px;
    }
`

const NewIcon = styled.div`
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => props.bg ? props.bg : "7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    width: 50px;
    height: 50px;
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

export default function InServiceMaresReportPage()  {

    const theme = React.useContext(ThemeContext);

    const tableRef = React.useRef();

    const navigate = useNavigate();

    const intl = useIntl();

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        tableRef.current.clean();
        setOpen(false);
    }
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        tableRef.current.clean();
        setOpenEdit(false);
    }

    const [ editLocation, setEditLocation ] = React.useState(null);

    const [ search, setSearch ] = React.useState('');

    const filters = [
        {
            name: "mareId",
            label: intl.formatMessage({id: 'mother mare'}),
            type: "check",
            src: `${API_URL}/horses/sexcode/M`
        },
        {
            name: "motherId",
            label: intl.formatMessage({id: 'donorMare'}),
            type: "check",
            src: `${API_URL}/horses/sexcode/M`
        },
        {
            name: "fatherId",
            label: intl.formatMessage({id: 'stallion'}),
            type: "check",
            src: `${API_URL}/horses/sexcode/S`
        },
        {
            name: "locationId",
            label: intl.formatMessage({id: 'location'}),
            type: "check",
            src: `${API_URL}/locations`
        },
        {
            name: "inseminationDate",
            label: intl.formatMessage({id: 'inseminationDate'}),
            type: "date"
        }
    ]

    const defaultSelectedFilters = [
        {
            name: "mareId",
            values: [],
            condition: "equal",
            type: "number",
            search: ''
        },
        {
            name: "motherId",
            values: [],
            condition: "equal",
            type: "number",
            search: ''

        },
        {
            name: "fatherId",
            values: [],
            condition: "equal",
            type: "number",
            search: ''

        },
        {
            name: "locationId",
            values: [],
            condition: "equal",
            type: "number"
        },
        {
            name: "inseminationDate",
            values: [],
            condition: "between",
            type: "date"
        }
    ]

    const columnsTable = [
        { title: translate("activeMare"), field: 'mareName', width: "15%", order: true, hidden: false },
        { title: translate("mother"), field: 'motherName', width: "15%", order: true, hidden: false },
        { title: translate("stallion"), field: 'fatherName', width: "15%", order: true, hidden: false },
        { title: translate("location"), field: 'locationName',width: "20%", order: true, hidden: false },
        { title: translate("classificationName"), field: 'clasificationName',width: "15%", order: true, hidden: false },
        { title: translate("inseminationDate"), type: "date", field: 'inseminationDate',width: "10%", order: true, cellAlign: 'center', hidden: false },
        { title: translate("estimaedBirthDate"), type: "date", field: 'estimationBirthDate',width: "10%", order: true, cellAlign: 'center', hidden: false },
        { title: translate("link"), actions: true, width: "auto", align: 'center', hidden: true }
    ]


    const handleEditItem = (data) => {
        setEditLocation(data);
        handleOpenEdit()
    }

    const onApplyFilter = () => {
        tableRef.current.clean();
    }

    const onClearFilter = () => {
        setSelectedFilters(defaultSelectedFilters);
    }

    const [ selectedFilters, setSelectedFilters ] = React.useState(defaultSelectedFilters)

  const handleFilterSearch = (name, value) => {
      const oldFilters = selectedFilters;
      const newFilters = selectedFilters.filter(f => f.name !== name);
      const filterChange = oldFilters.find(f => f.name === name);
      filterChange["search"] = value;
      newFilters.push(filterChange);
      setSelectedFilters(newFilters);
  }

    const onSearchChange = (value) => {
        setSearch(value);
    }

    const handleSearch = () => {
        tableRef.current.clean();
    }

    const handleLink = (data) => {
        window.open(data.url, "_blank")
    }

    const handleCheckFilter = (name, value) => {
        const olds = selectedFilters;
        const news = selectedFilters.filter(f => f.name !== name);
        const filterChange = olds.find(f => f.name === name);
        if(filterChange.values.includes(parseInt(value))){
            const newArray = filterChange.values.filter(val => val !== parseInt(value));
            filterChange["values"] = newArray;
            news.push(filterChange);
            setSelectedFilters(news);
        } else {
            const uptArray = filterChange.values;
            uptArray.push(parseInt(value));
            filterChange["values"] = uptArray;
            news.push(filterChange);
            setSelectedFilters(news);
        }
    }

    const handleChangeSwitch = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = [value];
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const handleDateChange = (name, value) => {
        const oldFilters = selectedFilters;
        const newFilters = selectedFilters.filter(f => f.name !== name);
        const filterChange = oldFilters.find(f => f.name === name);
        filterChange["values"] = value;
        newFilters.push(filterChange);
        setSelectedFilters(newFilters);
    }

    const actionsTable={
        edit: {
            icon: theme.icons.edit,
            tooltip: 'Editar',
            onClick: handleEditItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: true
        },
    }

    const options = {
        exportButton: true,
        exportName: intl.formatMessage({ id: 'inServiceMares' }),
        directExport: true,
        pagination: true
    }

    return(
        <Root>
            <Content>
                    <Table
                    disableTotalTitle={true}
                    ref={tableRef}
                    columns={columnsTable}
                    actionsTable={actionsTable}
                    viewTable="list"
                    options={options}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            let filterList = selectedFilters.filter(fil => fil.values.length > 0);
                            if (filterList.length === 0){
                                filterList = null;
                            }
                            else {
                                filterList = JSON.stringify(filterList);
                            }
                            axios.get(`${API_URL}/report/inServiceMares`,{
                                params: {
                                    page: query.page - 1,
                                    limit: query.pageSize,
                                    search: search,
                                    order: query.order,
                                    filter: filterList
                                }
                            })
                            .then(education => {
                                resolve({rows: education.data.rows, total: education.data ? education.data.total : 0});
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        })
                    }
                    dataExport={
                        (query) => new Promise((resolve, reject) => {
                            let filterList = selectedFilters.filter(fil => fil.values.length > 0);
                            if (filterList.length === 0){
                                filterList = null;
                            }
                            else {
                                filterList = JSON.stringify(filterList);
                            }
                            axios.get(`${API_URL}/export/inServiceMares`,{
                                params: {
                                    search: search,
                                    order: query.order,
                                    filter: filterList
                                },
                                responseType: 'blob'
                            })
                            .then(response => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', query.exportName+'.xlsx'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        })
                    }
                >
                <SearchInput search={search} onChange={onSearchChange} onSearch={handleSearch} />
                <Filters clear={onClearFilter} action={onApplyFilter}>
                    {
                        filters && filters.map((fil, index) =>
                            <FilterGroup onChange={handleFilterSearch} name={fil.name && fil.name} search={fil.name && selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search} key={`filter_group_${index}`} title={fil.type === "switch" ? null : fil.label}>
                                {
                                    fil.type === "switch" ?
                                    <SwitchFilter filter={fil} value={selectedFilters.find(ft => ft.name === fil.name).values[0]} onChange={handleChangeSwitch} />
                                    :
                                    fil.type === "date" ?
                                    <DateFilter filter={fil} values={selectedFilters.find(ft => ft.name === fil.name).values} onChange={handleDateChange} />
                                    :
                                    <CheckFilter filter={fil} values={selectedFilters.find(ft => ft.name === fil.name).values} search={selectedFilters.find(ft => ft.name === fil.name).search && selectedFilters.find(ft => ft.name === fil.name).search } handleChange={handleCheckFilter} />
                                }
                            </FilterGroup>
                        )

                    }
                </Filters>
                </Table>
                <Modal
                    open={open}
                    onClose={handleClose}
                >
                    <NewLocation handleClose={handleClose} />
                </Modal>
                <Modal
                    open={openEdit}
                    onClose={handleCloseEdit}
                >
                    <NewLocation locationData={editLocation} handleClose={handleCloseEdit} />
                </Modal>
            </Content>
        </Root>
    )
};
