import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { I18nProvider, LOCALES } from "./providers/i18n";
import { ThemeProvider, THEMES } from "./providers/theme";
import AuthProvider from "./providers/authentication";
import AdminLayout from "./components/layouts/AdminLayout";
import AdminPage from "./pages/AdminPage";
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import GlobalStyle from "./globalStyles";
import Disciplinas from "./pages/Disciplinas";
import WebLayout from "./components/layouts/WebLayout";
import Login from "./components/login";
import React from "react";
import UsersPage from "./pages/UsersPage";
import HorsesPage from "./pages/HorsesPage";
import CalendarPage from "./pages/CalendarPage";
import ReportsPage from "./pages/ReportsPage";
import SettingsPage from "./pages/SettingsPage";
import ReproductionPage from "./pages/ReproductionPage";
import PlacesPage from "./pages/PlacesPage";
import HorseLayout from "./components/layouts/HorseLayout";
import HorsePage from "./pages/HorsePage";
import HorseClinicHistoryPage from "./pages/HorseClinicHistoryPage";
import HorseMediaCenterPage from "./pages/HorseMediaCenterPage";
import HorseReproductionPage from "./pages/HorseReproductionPage";
import AppointmentsPage from "./pages/AppointmentsPage";
import TreatmentsPage from "./pages/TreatmentsPage";
import InterventionsPage from "./pages/InterventionsPage";
import EducationPage from "./pages/EducationPage";
import TrainingPage from "./pages/TrainingPage";
import Media from './pages/Media';
import Caballos from './pages/Caballos'
import GeneralMediaPage from "./pages/GeneralMediaPage";
import Protected from "./providers/authentication/protected";
import MessageBar from "./components/messageBar";
import Loading from "./components/loading";
import {useSelector} from "react-redux";
import HorseLoading from "./components/loading/horseLoading";
import Private from "./providers/authentication/private";
import CompetitionsReportPage from "./pages/CompetitionsRportPage";
import EducationReportPage from "./pages/EducationRportPage";
import LocationsReportPage from "./pages/LocationsRportPage";
import SemenStockReportPage from "./pages/SemenStockRportPage";
import ClinicHistoryReportPage from "./pages/ClinicHistoryRportPage";
import RecipientMaresReportPage from "./pages/RecipientMaresRportPage";
import MotherMaresReportPage from "./pages/MotherMaresRportPage";
import InServiceMaresReportPage from "./pages/InServiceMaresReportPage";
import { ThemeProvider as ThemeStyledProvider } from 'styled-components';

function App() {
  let location = useLocation();

  // The `backgroundLocation` state is the location that we were at when one of
  // the gallery links was clicked. If it's there, use it as the location for
  // the <Routes> so we show the gallery in the background, behind the modal.
  let state = location.state;

  const {open, progress} = useSelector(state => state.loading);

  return (
    <React.Fragment>
      <GlobalStyle/>
      <ThemeProvider theme={THEMES.default}>    
      <ThemeStyledProvider theme={THEMES.default} >
        <I18nProvider
          locale={LOCALES.SPANISH}>
          <AuthProvider>
            <MessageBar/>
            {
              open &&
              <HorseLoading progress={progress} />
            }
            <Routes location={state && state.backgroundLocation || location}>
              <Route path="/" element={<WebLayout/>}>
                <Route index element={<HomePage/>}/>
                <Route path="media" element={<Media/>}/>
                <Route path="/disciplinas/:type" element={<Disciplinas />} />
                <Route path="/caballos" element={<Caballos/>}/>
              </Route>
              <Route path="test" element={<Loading />} />
              <Route path="*" element={<NotFoundPage/>}/>

              <Route exact path="/backoffice" element={<Protected><AdminLayout /></Protected>}>
                <Route path="/backoffice/dashboard" element={<AdminPage/>}/>
                <Route path="/backoffice/users" element={<Private scope="users"><UsersPage/></Private>}/>
                <Route path="/backoffice/horses" element={<HorsesPage/>}/>
                <Route path="/backoffice/horses/:id" element={<HorseLayout/>}>
                  <Route index element={<HorsePage/>}/>
                  <Route path="/backoffice/horses/:id/clinichistory" element={<HorseClinicHistoryPage/>}>
                    <Route  index  element={<AppointmentsPage/>} />
                    <Route path="/backoffice/horses/:id/clinichistory/treatments" element={<TreatmentsPage/>} />
                    <Route path="/backoffice/horses/:id/clinichistory/interventions" element={<InterventionsPage/>} />
                  </Route>
                  <Route path="/backoffice/horses/:id/mediacenter" element={<HorseMediaCenterPage/>}>
                    <Route  index  element={<GeneralMediaPage/>} />
                    <Route path="/backoffice/horses/:id/mediacenter/education"  element={<EducationPage/>} />
                    <Route path="/backoffice/horses/:id/mediacenter/training" element={<TrainingPage/>} />
                  </Route>
                  <Route path="/backoffice/horses/:id/reproduction" element={<HorseReproductionPage/>} />
                </Route>
                <Route path="/backoffice/places" element={<Private scope="locations"><PlacesPage/></Private>}/>
                <Route path="/backoffice/reproduction" element={<ReproductionPage/>}/>
                <Route path="/backoffice/calendar" element={<CalendarPage/>}/>
                {/* <Route path="/backoffice/reports/:tab" element={<ReportsPage/>}/> */}
                <Route path="/backoffice/reports" element={<ReportsPage/>}>
                  <Route index element={<CompetitionsReportPage/>}/>
                  <Route path="/backoffice/reports/education" element={<EducationReportPage/>}/>
                  <Route path="/backoffice/reports/locations" element={<LocationsReportPage/>}/>
                  <Route path="/backoffice/reports/stock" element={<SemenStockReportPage/>}/>
                  <Route path="/backoffice/reports/clinicHistory" element={<ClinicHistoryReportPage/>}/>
                  <Route path="/backoffice/reports/recipientMares" element={<RecipientMaresReportPage/>}/>
                  <Route path="/backoffice/reports/motherMares" element={<MotherMaresReportPage/>}/>
                  <Route path="/backoffice/reports/serviceMares" element={<InServiceMaresReportPage/>}/>
                </Route>
                <Route path="/backoffice/settings" element={<SettingsPage/>}/>
              </Route>
            </Routes>
            {state && state.backgroundLocation && (
              <Routes>
                <Route path="/login" element={<Login />} />
              </Routes>
            )}
          </AuthProvider>
        </I18nProvider>
        </ThemeStyledProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
