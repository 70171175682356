import React from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {Table} from "../components/tables/table";
import translate from "../providers/i18n/translate";
import { ThemeContext } from "../providers/theme";
import axios from 'axios';
import { useIntl } from 'react-intl';
import { API_URL } from "../config";
import Timeline from "../components/timeline";
import { useParams } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Modal } from "@mui/material";
import EditReproductionEvent from "../components/timeline/EditReproductionEvent";


/* Styled components */
const Root = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    height: 100%;
    width: 100%;
    padding: 0 20px;
    border:  solid 3px #00271C;
    @media (min-width: 1024px) {
        padding: 0px;
    }
`

const ContentTable = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
`

const ContentTimeline = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px 0;
    overflow-y: auto;
`

const RelatedHorse = styled.div`
    svg {
        color: ${props => props.mobileStyle ? props.theme.palette.principal : props.theme.palette.accent};
        font-size: 0.85rem;
        margin: 0 0 0 5px;
        transition: all 300ms ease-in-out;
        cursor: pointer;
    }
    svg:hover {
        transform: scale(1.1);
    }
`

const HorseReproductionPage = () => {

    const theme = React.useContext(ThemeContext);

    const { id } = useParams();

    const tableRef = React.useRef();

    const intl = useIntl();    

    const [open, setOpen] = React.useState(false);

    const [ editClassification, setEditClassification ] = React.useState(null);

    const columnsTable = [
        { title: '#', field: 'cycle',width: "5%", hidden: false},
        { title: translate("date"), field: 'date', type: "date", width: "10%", hidden: false},
        { title: translate("classification"), field: 'clasificationName.value', mobile: true, width: "25%",  hidden: false },
        { title: translate("relatedHorse"), field: 'idRelatedHorse.name', mobile: false, width: "30%",  hidden: false, 
            render: (data) => 
            <RelatedHorse>{data.idRelatedHorse?.name}
                {
                    data.idRelatedHorse?.id &&
                    <Link to={`/backoffice/horses/${data.idRelatedHorse?.id}`} target="_blank"><OpenInNewIcon /></Link>
                }
            </RelatedHorse> 
        },
        { title: translate("comment"), field: 'comment', mobile: false, width: "30%",  hidden: false, render: (data) => <div dangerouslySetInnerHTML={{ __html: data.comment }} /> },
        { title: translate("actions"), actions: true, mobile: false, width: "auto", hidden: false, hiddenMobile: true }
    ]

    const handleEditItem = (data) => {
        setEditClassification(data);
        setOpen(true);
    }

    const handleClose = () => {
        refresh();
        setOpen(false);
    }

    const handleDeleteItem = (data) => {
        if(data.delete){
            // eslint-disable-next-line no-restricted-globals
            if( confirm(intl.formatMessage({ id: 'confirm delete' })) ){
                axios.delete(`${API_URL}/historyclassification/${data.id}`)
                .then(result => {
                    refresh();
                })
                .catch(err => {
                    console.log(err);
                })
            }
        } else {
            alert(intl.formatMessage({ id: 'cannotdelete' }));
        }
    }

    const refresh = () => {
        tableRef.current.clean();
    }

    
    const actionsTable={
        edit: {
            icon: theme.icons.edit,
            tooltip: intl.formatMessage({ id: 'edit' }),
            onClick: handleEditItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
        delete: {
            icon: theme.icons.delete,
            tooltip: intl.formatMessage({ id: 'delete' }),
            onClick: handleDeleteItem,
            color: theme.palette.principal,
            hoverColor: theme.palette.accent,
            hidden: false
        },
    }

    const optionsTable = {
        pagination: false,
        backgroundHeaderColor: theme.palette.principal,
        headerColor: theme.palette.base,
    }

    return (
        <Root>
            <ContentTable>
                <Table 
                    ref={tableRef}
                    columns={columnsTable}
                    actionsTable={actionsTable}
                    viewTable="list"
                    options={optionsTable}
                    disabledPlain={true}
                    data={
                        (query) => new Promise((resolve, reject) => {
                            axios.get(`${API_URL}/historyclassification`)
                            .then(result => {
                                const horseHistory = result.data.filter(h => parseInt(h.idHorse.id) === parseInt(id));
                                const historyRows = horseHistory.reverse();
                                historyRows[0].delete = true;
                                resolve({rows: historyRows});
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        })
                    }
                >
                </Table>
            </ContentTable>
            {/* <ContentTimeline>
                {
                    selectedRow && 
                    <Timeline data={selectedRow} refresh={refresh} />
                }
            </ContentTimeline> */}
            <Modal
                open={open}
                onClose={handleClose}
            >
                <EditReproductionEvent data={editClassification} handleClose={handleClose} />
            </Modal>
        </Root>
    );
}

export default HorseReproductionPage;

const historyClassificationsExample = [
    {
        name: "1er servicio",
        data: [
            {
                id: 1,
                name: "Apta Servicio Vacía",
                date: new Date("2021-12-20")
            },
            {
                id: 2,
                name: "Apta Servicio Donante",
                date: new Date("2022-02-10")
            }
        ]
    }
]