import React, { useState } from 'react';
import styled, {keyframes, css} from 'styled-components/macro';
import arrow from '../public/arrow.svg';
import {mob, mobText, carDesk, carDeskText} from '../utilities';
import VermasButton from './vermasbutton';
import Circle from './Circle';
import {useSwipeable} from 'react-swipeable';
import { ThemeContext } from "../providers/theme";
import { Link } from "react-router-dom";


/* Styled components */

// mobile keyframes
const rightToCenter = keyframes `
    from {
        right: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
    to {
        right: 0vw;
        width: ${mob(224)};
        height: ${mob(334)};
        margin-top: 0;
    }
`
const leftToCenter = keyframes `
    from {
        left: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
    to {
        left: 0vw;
        width: ${mob(224)};
        height: ${mob(334)};
        margin-top: 0;
    }
`
const centerToLeft = keyframes `
    from {
        left: 0vw;
        width: ${mob(224)};
        height: ${mob(334)};
        margin-top: 0;
    }
    
    to {
        left: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
`
const centerToRight = keyframes `
    from {
        right: 0vw;
        width: ${mob(224)};
        height: ${mob(334)};
        margin-top: 0;
    }
    to {
        right: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
`
const leftToRight = keyframes `
    from {
        left: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
        }
    to {
        left: ${mob(224+23-3.28)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
` 
const rightToLeft = keyframes `
    from {
        right: ${mob(-102-82.82)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
    to {
        right: ${mob(224+19.72)};
        width: ${mob(82.28+82.82)};
        height: ${mob(247.64)};
        margin-top: ${mob(60.56-12)};
    }
`
// carDesktop keyframes
const centerLeftToCenterRight = keyframes` 
    from {
        left: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
    }
    to {
        left: ${carDesk(550+42)};
        width: ${carDesk(550)};
        height: ${carDesk(825)};
    }
`
const centerRightToCenterLeft = keyframes`
    from {
        right: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
    }
    to {
        right: ${carDesk(550+42)};
        width: ${carDesk(550)};
        height: ${carDesk(825)};
    }
`
const centerLeftToLeft = keyframes`
    from {
        left: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
        margin-top: 0vw;
    }
    to {
        left: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
`
const centerRightToRight = keyframes`
    from {
        right: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
        margin-top: 0vw;
    }
    to {
        right: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
`
const leftToCenterLeft = keyframes`
    from {
        left: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
    to {
        left: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
        margin-top: 0vw;
    }
`
const rightToCenterRight = keyframes`
    from {
        right: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
    to {
        right: 0vw;
        width: ${carDesk(550)};
        height: ${carDesk(825)};
        margin-top: 0vw;
    }
`
const carDeskLeftToRight = keyframes`
    from {
        left: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
    to {
        left: ${carDesk(2*550+2*42)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
`
const carDeskRightToLeft = keyframes`
    from {
        left: ${carDesk(2*550+2*42)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
    to {
        left: ${carDesk(-390)};
        width: ${carDesk(347)};
        height: ${carDesk(526)};
        margin-top: ${carDesk(172-41)};
    }
`

const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    height: auto;
    width: 100%;
`
const TitleContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(min-width: 1024px){
        
        margin: ${carDesk(74)} 0 ${carDesk(42)} 0; 
    }
`
const BoxContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    height: auto;
    width: 100%; 
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
`
const TitleBox = styled.h1`
    width: 33.01vw;
    height: 3.27vw;
    font-family: helvetica;
    font-weight: bold;
    font-size: calc(1rem + (2.78vw - 16px));
    line-height: 3.27vw;
    text-align: center;
    color: ${props => props.fontColor};
    margin: 3.27vw 0 2.8vw 0;
    @media(min-width: 1024px){
        width: ${carDesk(570)};
        height: ${carDesk(56)};
        font-size: ${carDeskText(50)};
        line-height: ${carDeskText(58)};
        margin: 0; 
    }

`
const Line = styled.div`
    width: 24.77vw;
    height: 0px;
    border: 0.5px solid ${props => props.fontColor};
    color: ${props => props.fontColor};
    margin: ${props => props.position == 'right' ? '0 6.89vw 0 2.22vw' : '0 2.22vw 0 6.89vw'};
`
const ButtonBox = styled.button`
    all: unset;
    display: flex;
    align-items: flex-start;
    justify-content: ${props => props.value == 'left' ? 'flex-start' : 'flex-end'};
    height: ${mob(66.5+249)};
    width: ${mob(82)};
    z-index: ${props => props.objectNum+1};
    position: relative;
    box-sizing: border-box;
    img {
        ${(props) => props.value == 'left' ? `
        -webkit-transform:rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        ` : ''}
    }
    @media (min-width: 1024px){
        width: ${carDesk(347)};
        height: ${carDesk(172+526)};
        img{
            width: ${carDesk(34)};
            height: ${carDesk(56.98)};
        }
    }
`
const CarouselBox = styled.div`
    position: relative;
    box-sizing: border-box;
    width: ${mob(224)};
    height: ${mob(334)};
    @media (min-width: 1024px){
        width: ${carDesk(2*550+42)};
        height: ${carDesk(825)};
    }
`
const StaticCarouselCard = styled.div`
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border: 0.75px solid #56876D;
    background-color: white; 
    border-radius: 3px;
    z-index: -1;
    opacity: 0;
    ::before{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        transition: background-color 0.3s linear;
        background-color: rgba(2,39,28,0.0);
        content: " ";
    }
    @media (max-width: 1023px){
        ${props => {
            if (props.position == "previous" && props.active < 2){
                return(
                    css`      
                        opacity: 1;
                        left: ${mob(-102-82.82)};
                        width: ${mob(82.28+82.82)};
                        height: ${mob(247.64)};
                        margin-top: ${mob(60.56-12)};
                    `
                )
            }
            if (props.position == "next" && props.active > (props.objectNum - 3)){
                return(
                    css`
                        opacity: 1;
                        right: ${mob(-102-82.82)};
                        width: ${mob(82.28+82.82)};
                        height: ${mob(247.64)};
                        margin-top: ${mob(60.56-12)};
                    `
                )
            }
        }}

        ${props => { 
            if (props.active == 0  && props.dir == "right" && props.position == "previous"){
                        return(
                            css`
                                animation: ${centerToLeft} 0.3s ease-in 1;
                                z-index: ${props => props.objectNum};
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
                    }
            if (props.active == (props.objectNum - 1)  && props.dir == "left" && props.position == "next"){
                        return(
                            css`
                                animation: ${centerToRight} 0.3s ease-in 1;
                                z-index: ${props => props.objectNum};
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
            }
            if (props.active == 0  && props.dir == "left" && props.position == "previous"){
                        return(
                            css`
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
            }
            if (props.active == (props.objectNum - 1)  && props.dir == "right" && props.position == "next"){
                        return(
                            css`
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
            }

        }}
        ${props => {
            if ((props.active == 0 && props.position == "next") || (props.active == (props.objectNum - 1) && props.position == "previous")){
                return(
                    css`
                        ${CardName}{
                            font-size: ${mobText(25)};
                        }
                        ${CardBirth}{
                            font-size: ${mobText(14)};
                        }
                        ${CardParent}{
                            font-size: ${mobText(14)};
                        }
                        ${CardParentName}{
                            font-size: ${mobText(15)};
                        }
                    `
                )
            }
        }}
    }
    @media (min-width: 1024px){
        ${props => {
            if (props.position == "previous" && props.active < 2){
                return(
                    css`      
                        opacity: 1;
                        left: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)};
                    `
                )
            } 
            if (props.position == "next" && props.active > (props.objectNum - 4)){
                return(
                    css`
                        opacity: 1;
                        right: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)};
                    `
                )
            }
        }}

        ${props => { 
            if (props.active == 0  && props.dir == "right" && props.position == "previous"){
                        return(
                            css`
                                animation: ${centerLeftToLeft} 0.3s ease-in 1;
                                z-index: ${props => props.objectNum}; 
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
                    }
            if (props.active == (props.objectNum - 1) && props.dir == "left" && props.position == "next"){
                return(
                    css`
                        animation: ${centerLeftToCenterRight} 0.3s ease-in 1;
                        margin-top: 0;
                        z-index: ${props => props.objectNum};
                        ${CardName}{
                            font-size: ${carDeskText(53)};
                        }
                        ${CardBirth}{
                            font-size: ${carDeskText(23)};
                        }
                        ${CardParent}{
                            font-size: ${carDeskText(19)};
                        }
                        ${CardParentName}{
                            font-size: ${carDeskText(23)};
                        }
                    `
                )
            }
            if (props.active == (props.objectNum - 1) && props.dir == "right" && props.position == "next"){
                return(
                    css`
                        z-index: ${props => props.objectNum};
                        animation: ${rightToCenterRight} 0.3s ease-in 1;
                        ${CardName}{
                            font-size: ${carDeskText(53)};
                        }
                        ${CardBirth}{
                            font-size: ${carDeskText(23)};
                        }
                        ${CardParent}{
                            font-size: ${carDeskText(19)};
                        }
                        ${CardParentName}{
                            font-size: ${carDeskText(23)};
                        }

                    `
                )
            }
            if (props.active == (props.objectNum - 2) && props.dir == "left" && props.position == "next"){
                return (
                    css`
                        animation: ${centerRightToRight} 0.3s ease-in 1; 
                        z-index: ${props => props.objectNum}; 
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                    `
                )
            }
            if (props.active == 0  && props.dir == "left" && props.position == "previous"){
                        return(
                            css`
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
                    }
            if (props.active == (props.objectNum - 2)  && props.dir == "right" && props.position == "next"){
                        return(
                            css`
                                ::before{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    left: 0;
                                    bottom: 0;
                                    background-color: rgba(2,39,28,0.7);
                                    content: " ";
                                }
                            `
                        )
                    }
        }}
        ${props => {
            if ((props.active == 0 && props.position == "next") || (props.active == (props.objectNum - 1) && props.position == "previous")){
                return(
                    css`
                        ${CardName}{
                            font-size: ${carDeskText(53)};
                        }
                        ${CardBirth}{
                            font-size: ${carDeskText(23)};
                        }
                        ${CardParent}{
                            font-size: ${carDeskText(19)};
                        }
                        ${CardParentName}{
                            font-size: ${carDeskText(23)};
                        }
                    `
                )
            }
        }}
        
        animation-fill-mode: forwards;
    }
`
const CarouselCard = styled.div`
    box-sizing: border-box;
    position: absolute;
    border: 0.75px solid #56876D;
    background-color: white;
    border-radius: 3px;
    z-index: -1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    animation-fill-mode: forwards;
    ::before{
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 1;
        transition: background-color 0.3s linear;
        background-color: rgba(2,39,28,0.7);
        content: " ";
    }
    @media (max-width: 1023px){
        ${props => {
            if (props.active == props.id){
                return(
                    css`
                        z-index: ${props.objectNum};
                    `
                )
            }
        }}
        ${props =>{
            if (props.id == props.active && props.dir == "right") {
                return(
                    css`
                        animation: ${rightToCenter} 0.3s ease-in 1;
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                `)
            }
            if (props.id == (props.active - 1) && props.dir == "right"){
                return(
                    css`
                        animation: ${centerToLeft} 0.3s ease-in 1;
                    `
                )
            }
            if (props.id == props.active && props.dir == "left"){
                return(
                    css`
                    animation: ${leftToCenter} 0.3s ease-in 1; 
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                    `
                )
            }
            if (props.id == (props.active + 1) && props.dir == "left"){
                return(
                    css`
                        animation: ${centerToRight} 0.3s ease-in 1;
                    `
                )
            }
            
        }}
        ${props => {
            if (props.active == 0  && props.dir == "right" && props.id > props.active){
                        return(
                            css`
                                animation: ${leftToRight} 0.3s linear 1;
                                z-index: ${props.objectNum - props.id};
                            `
                        )
                    }
            else if (props.id > props.active){

                return(css`
                        right: ${mob(-102-82.82)};
                        width: ${mob(82.28+82.82)};
                        height: ${mob(247.64)};
                        margin-top: ${mob(60.56-12)};
                    z-index: ${props.objectNum - props.id};
                `)
                
            }
            if (props.active == (props.objectNum - 1)  && props.dir == "left" && props.id < props.active){
                        return(
                            css`
                                animation: ${rightToLeft} 0.3s linear 1;
                                z-index: ${props.objectNum};
                            `
                        )
                    }
            
            else if (props.id < props.active){
                return(css` 
                        left: ${mob(-102-82.82)};
                        width: ${mob(82.28+82.82)};
                        height: ${mob(247.64)};
                        margin-top: ${mob(60.56-12)};
                        z-index: ${props.id};
                `)
                
            }
        }}
        animation-fill-mode: forwards;
        }
    @media (min-width: 1024px){
        ${props => {
            if (props.active == props.id || props.id == (props.active + 1)){
                return(
                    css`
                        z-index: ${props.objectNum};
                    `
                )
            }
        }}
        ${props =>{
            if (props.id == props.active && props.dir == "right") {
                return(
                    css`
                        animation: ${centerRightToCenterLeft} 0.3s ease-in 1;
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                `)
            }
            if (props.id == (props.active + 1) && props.dir == "right"){
                return(
                    css`
                        animation: ${rightToCenterRight} 0.3s ease-in 1;
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                    `
                )
            }
            if (props.id == (props.active - 1) && props.dir == "right"){
                return(
                    css`
                        animation: ${centerLeftToLeft} 0.3s ease-in 1; 
                    `
                )
            }
            if (props.id == props.active && props.dir == "left"){
                return(
                    css`
                    animation: ${leftToCenterLeft} 0.3s ease-in 1; 
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                    `
                )
            }
            if (props.id == (props.active + 1) && props.dir == "left"){
                return(
                    css`
                        animation: ${centerLeftToCenterRight} 0.3s ease-in 1;
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                    `
                )
            }
            if (props.id == (props.active + 2) && props.dir == "left"){
                return(
                    css`
                        animation: ${centerRightToRight} 0.3s ease-in 1; 
                    `
                )
            }
            if (props.id == 0 && props.active == (props.objectNum - 1)){
                return(
                    css`
                        ::before{
                            position: absolute;
                            top: 0;
                            right: 0;
                            left: 0;
                            bottom: 0;
                            background-color: rgba(2,39,28,0.0);
                            content: " ";
                        }
                    `
                )
            }
            
    }}
        ${props => {
            if (props.active == 0  && props.dir == "right" && props.id > props.active + 1){
                        return(
                            css`
                                /* right: ${carDesk(-390)};
                                width: ${carDesk(347)};
                                height: ${carDesk(526)};
                                margin-top: ${carDesk(172-41)}; */
                                animation: ${carDeskLeftToRight} 0.5s linear 1;
                                z-index: ${props.objectNum - props.id};
                            `
                        )
                    }
            else if (props.id > (props.active + 1)){

                return(css`
                        right: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)};
                        z-index: ${props.objectNum - props.id};
                `)
                
            }
            if (props.active == (props.objectNum - 2) && props.dir == "left" && props.id == 2){
                return(
                    css`
                        animation: ${carDeskRightToLeft} 0.5s linear 1;
                        z-index: ${props.objectNum - props.id};
                    `
                )
            }
            
            else if (props.id < props.active){
                return(css` 
                        left: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)};
                        z-index: ${props.id};
                `)
                
            }
        }}
        animation-fill-mode: forwards;
        ${props => {
            if (props.active == (props.objectNum - 1) && props.id == 1 ){
                return(
                    css`  
                        left: unset;
                        right: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)}; 
                    `
                )
            }
        }}
        ${props => {

            if (props.active == (props.objectNum - 1)  && props.dir == "left" && props.id == 1){
                                    return(
                                        css`
                                            animation: ${centerRightToRight} 0.3s linear 1;
                                            z-index: ${props.objectNum};
                                        `
                                    )
                                }
            if (props.active == (props.objectNum -1) && props.dir == "left" && props.id == 2){
                return(
                    css`                 
                        left: unset;
                        right: ${carDesk(-390)};
                        width: ${carDesk(347)};
                        height: ${carDesk(526)};
                        margin-top: ${carDesk(172-41)}; 
                    `
                )
            }
        }}
        ${props => {
            if (props.active == (props.objectNum - 1) && props.id == 0){
                return(
                    css` 
                        opacity: 0;
                        ${CardName}{
                            font-size: ${carDeskText(53)};
                        }
                        ${CardBirth}{
                            font-size: ${carDeskText(23)};
                        }
                        ${CardParent}{
                            font-size: ${carDeskText(19)};
                        }
                        ${CardParentName}{
                            font-size: ${carDeskText(23)};
                        }
                    `
                )
            }
        }}
    }


`
const CardImg = styled.div`
    position: relative;
    width: 100%;
    height: 48.20%;
    display: grid;
    grid-template-columns: 41.74% 16.52% 41.74%;
    grid-template-rows: 77.02% 13.04% 9.94%;
    img{
        grid-column: 1 / 4;
        grid-row: 1 / 3;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    @media (min-width: 1024px){
        height: 48.43%;
        grid-template-columns: 1fr 16.7% 1fr;
        grid-template-rows: 77% 13.5% 9.5%;
    }
`
const CircleContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    grid-column: 2 / 3;
    grid-row: 2 / 4;
    width: 100%;
    height: 100%;
`
const CardName = styled.span`
    font-family: helvetica;
    color: ${props => props.fontColor} ;
    text-transform: uppercase;
    transition: font-size .3s ease-in;
    font-size: ${props => props.id || props.id == 0 ? (props.active == props.id ? mobText(25) : mobText(13) ) : mobText(13)};
    margin-top: 4.61%;
    @media (min-width: 1024px){
        font-size: ${props => props.id || props.id == 0 ? (props.active == props.id || props.id == (props.active + 1) ? carDeskText(53) : carDeskText(35)) : carDeskText(35)};
        margin-top: 6.17%;
    }
`
const CardBirth = styled.span`
    font-family: helvetica;
    color: #56876D;
    color: ${props => props.fontColor};
    transition: font-size .3s ease-in;
    font-size: ${props => props.id || props.id == 0 ? (props.active == props.id ? mobText(14) : mobText(8) ) : mobText(8)};
    margin-bottom: 3.47%;
    @media (min-width: 1024px){
        font-size: ${props => props.id || props.id == 0 ? (props.active == props.id || props.id == (props.active + 1) ? carDeskText(23) : carDeskText(13)) : carDeskText(13)};
        margin-top: 0.91%;
        margin-bottom: 8.53%;
    }
`
const CardParent = styled.span`
    font-family: helvetica;
    color: ${props => props.fontColor};
    transition: font-size .3s ease-in;
    font-size: ${props => props.id || props.id == 0 ? (props.active == props.id ? mobText(14) : mobText(13) ) : mobText(13)};
    margin-bottom: 1.79%;
    @media (min-width: 1024px){
        font-size: ${props => props.id || props.id == 0 ? (props.active == props.id || props.id == (props.active + 1) ? carDeskText(19) : carDeskText(10)) : carDeskText(10)};
        margin-bottom: 2.72%;
    }
`
const CardParentName = styled.span`
    font-family: helvetica;
    color: ${props => props.fontColor};
    transition: font-size .3s ease-in;
    font-size: ${props => props.id || props.id == 0 ? (props.active == props.id ? mobText(15) : mobText(8) ) : mobText(8)}; 
    margin-bottom: 4.91%;
    @media (min-width: 1024px){
        font-size: ${props => props.id || props.id == 0 ? (props.active == props.id || props.id == (props.active + 1) ? carDeskText(23) : carDeskText(13)) : carDeskText(13)};
        margin-bottom: 8.35%;
    }
`
const StyledVermasButton = styled(VermasButton)`
    margin-top: ${mob(27)};
    margin-bottom: ${mob(18)};
    @media (min-width: 1024px){
        margin-top: ${carDesk(52.79)};
        margin-bottom: ${carDesk(53.21)};
    }
`

const img = [{'id': 1, 'name':'rr kuka du lys', 'url':require("../public/KUKA DU LYS.jpeg"), 'birth':'Nacida el 28-11-2018', 'father':'CORNET DU LYS', 'mother':'QUINTA 27'},{'id': 2, 'name':'rr zaropek', 'url':require('../public/ZAROPEK.jpeg'), 'birth':'Nacido el 25-8-2018', 'father':'CASALL ASK', 'mother':'ROYAL CROISSANT'},{'id': 3,'name':'rr rey canuto', 'url':require('../public/REY CANUTO.jpeg'), 'birth':'Nacido el 26-12-2018', 'father':'CORNET DU LYS', 'mother':'ORIANA'},{'id': 4, 'name': 'rr copito', 'url':require('../public/COPITO.jpeg'), 'birth':'Nacido el 4-1- 2019', 'father':'CON AIR', 'mother':'FETZI'},{'id': 5, 'name': 'rr fortuna', 'url':require('../public/FORTUNA.jpeg'), 'birth':'Nacida el 8-2-2019', 'father':'CON AIR', 'mother':'ORIANA'}]

export default function Carousel() {

    const theme = React.useContext(ThemeContext);

    const handlers = useSwipeable({
        onSwipedLeft: () => {handleClick("right")},
        onSwipedRight: () => {handleClick("left")},
        delta: 20
    });
    
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [active, setActive] = useState(0);
    const [dir, setDir] = useState('right');

    const handleClick = async (value) => {
        if (active < (img.length - 1) && value == 'right') {
            setActive(active + 1);
            setDir('right');
        }
        if (active > 0 && value == 'left') {
            setActive(active - 1);
            setDir('left');
        }
        if (active == 0 && value == 'left') {
            setActive(img.length - 1);
            setDir('left');
        }
        if (active == img.length -1 && value == 'right'){
            setActive(0);
            setDir('right');
        }
        setButtonDisabled(true);
        await new Promise(resolve => setTimeout(resolve, 300));
        setButtonDisabled(false);

    }

    return (
        <Root>
            <TitleContainer>
                <Line fontColor={theme.palette.font} position="left"/>
                <TitleBox fontColor={theme.palette.font}>NUESTROS CABALLOS</TitleBox>
                <Line fontColor={theme.palette.font} position="right"/>
            </TitleContainer>
            <BoxContainer {...handlers}>
                <ButtonBox value="left" onClick={e => handleClick(e.currentTarget.value)} disabled={buttonDisabled} objectNum={img.length}><img src={arrow} alt='button' /></ButtonBox>
                <CarouselBox>
                    <StaticCarouselCard position="previous" active={active} dir={dir} objectNum={img.length}>
                        <CardImg><img src={img[img.length-1].url}/><CircleContainer><Circle type="comp" color={theme.palette.accent}/></CircleContainer></CardImg>
                        <CardName fontColor={theme.palette.accent}>{img[img.length-1].name}</CardName>
                        <CardBirth fontColor={theme.palette.font}>{img[img.length-1].birth}</CardBirth>
                        <CardParent fontColor={theme.palette.font}>Padre</CardParent>
                        <CardParentName fontColor={theme.palette.accent}>{img[img.length-1].father}</CardParentName>
                        <CardParent fontColor={theme.palette.font}>Madre</CardParent>
                        <CardParentName fontColor={theme.palette.accent}>{img[img.length-1].mother}</CardParentName>
                    </StaticCarouselCard>
                    {img.map((d, idx) => {
                            return (<CarouselCard key={idx} id={idx} active={active} dir={dir} objectNum={img.length}>
                                        <CardImg><img src={d.url}/><CircleContainer><Circle type="comp" color={theme.palette.accent}/></CircleContainer></CardImg>
                                        <CardName fontColor={theme.palette.accent} active={active} id={idx}>{d.name}</CardName>
                                        <CardBirth active={active} id={idx}>{d.birth}</CardBirth>
                                        <CardParent fontColor={theme.palette.font} active={active} id={idx}>Padre</CardParent>
                                        <CardParentName fontColor={theme.palette.accent} active={active} id={idx}>{d.father}</CardParentName>
                                        <CardParent fontColor={theme.palette.font} active={active} id={idx}>Madre</CardParent>
                                        <CardParentName fontColor={theme.palette.accent} active={active} id={idx}>{d.mother}</CardParentName>
                                    </CarouselCard>)
                    })}
                    <StaticCarouselCard position="next" active={active} dir={dir} objectNum={img.length}>
                        <CardImg><img src={img[0].url}/><CircleContainer><Circle type="comp" color={theme.palette.accent}/></CircleContainer></CardImg>
                        <CardName fontColor={theme.palette.accent}>{img[0].name}</CardName>
                        <CardBirth>{img[0].birth}</CardBirth>
                        <CardParent fontColor={theme.palette.font}>Padre</CardParent>
                        <CardParentName fontColor={theme.palette.accent}>{img[0].father}</CardParentName>
                        <CardParent fontColor={theme.palette.font} >Madre</CardParent>
                        <CardParentName fontColor={theme.palette.accent}>{img[0].mother}</CardParentName>
                    </StaticCarouselCard>
                </CarouselBox>
                <ButtonBox value="right" onClick={e => handleClick(e.currentTarget.value)} disabled={buttonDisabled} objectNum={img.length}><img src={arrow} alt='button' /></ButtonBox>
            </BoxContainer>
            <BoxContainer>
                <Link style={{all: "unset"}} to="caballos"><StyledVermasButton/></Link>
            </BoxContainer>
        </Root>
  );
}
