import React from "react";
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import translate from "../../providers/i18n/translate";
import { API_URL } from "../../config";
import axios from "axios";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import NewClassification from "./NewClassification";
import { Modal } from "@mui/material";

const BoxText = styled.p`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-start;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  color: ${(props) =>
    props.mobileStyle
      ? props.theme.palette.principal
      : props.theme.palette.accent};
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  margin-top: 0.1rem;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  svg {
    color: ${(props) =>
      props.mobileStyle
        ? props.theme.palette.principal
        : props.theme.palette.accent};
    font-size: 0.8rem;
    margin: 0 0 0 3px;
    transition: all 300ms ease-in-out;
    cursor: pointer;
  }
  svg:hover {
    transform: scale(1.1);
  }
  @media (min-width: 1024px) {
    svg {
      font-size: ${(props) => (props.sidebar ? "0.75rem" : "0.9em")};
    }
  }
  color: ${(props) => props.theme.palette.accent};
`;
const BoxSubtitle = styled.h3`
  width: ${(props) => (props.sidebar ? "100%" : "100%")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  align-items: flex-end;
  justify-content: flex-start;
  grid-column: ${(props) => (props.gridColumn ? props.gridColumn : "inherit")};
  text-transform: capitalize;
  color: ${(props) => props.theme.palette.secundary};
  font-size: 0.8rem;
  font-weight: 600;
  transition: all 300ms ease-in-out;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  margin-top: 0.2rem;
  line-height: 1;
  @media (min-width: 1024px) {
    font-size: 0.9rem;
    font-weight: 700;
    color: ${(props) => props.theme.palette.secundary};
  }
`;

const ChangeButton = styled.button`
  position: relative;
  order: ${(props) => (props.sidebar ? props.order : "inherit")};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => (props.sidebar ? "0.65rem" : "0.65rem")};
  border-radius: 5px;
  background-color: ${(props) => props.theme.palette.principal};
  width: ${(props) => (props.sidebar ? "150px" : "150px")};
  cursor: pointer;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  color: ${(props) => props.theme.palette.base};
  border: 1px solid ${(props) => props.theme.palette.principal};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  box-shadow: none;
  outline: none;
  margin: 0;
  padding: 3px 2px;
  margin-top: 8px;
  svg {
    font-size: 1rem;
  }
  &:hover {
    color: ${(props) => props.theme.palette.base};
    background-color: ${(props) => props.theme.palette.accent};
    border: 1px solid ${(props) => props.theme.palette.accent};
    a {
      color: ${(props) => props.theme.palette.base};
    }
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.accent};
  }
  @media (min-width: 1024px) {
    height: 30px;
  }
`;
const LastClassificacion = ({ id, sidebar }) => {
  const [classification, setClassification] = React.useState(null);

  const [openNewClassification, setOpenNewClassification] =
    React.useState(false);

  const handleChangeClassification = () => {
    setOpenNewClassification(true);
  };

  const handleCloseNewClassification = () => {
    getClassification();
    setOpenNewClassification(false);
  };

  const getClassification = () => {
    axios
      .get(`${API_URL}/historyclassification/lastbyhorse/${id}`)
      .then((result) => {
        setClassification(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (!classification) {
      getClassification();
    }
  }, [classification]);

  return (
    classification && (
      <React.Fragment>
        <BoxSubtitle sidebar={sidebar} order={5}>
          {translate("classification")}
        </BoxSubtitle>
        <BoxText sidebar={sidebar} order={7}>
          {classification.clasificationName.value}
        </BoxText>
        <ChangeButton
          sidebar={sidebar}
          order={13}
          onClick={handleChangeClassification}
        >
          {translate("changeclassification")}
        </ChangeButton>

        <Modal
          open={openNewClassification}
          onClose={handleCloseNewClassification}
        >
          <NewClassification
            id={id}
            actualStatus={classification.clasificationName}
            handleClose={handleCloseNewClassification}
          />
        </Modal>
      </React.Fragment>
    )
  );
};

export default LastClassificacion;
