import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';

/* Styled components */
const Root = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    height: 400px;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: ${props => props.width ? props.width : "inherit"};
`

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`

const NewEvent = ({event, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [ newEvent, setNewEvent ] = React.useState({
        title: event ? event.title : '',
        start: event ? event.start : new Date(),
        end: event ? event.end : new Date(),
        isAllDay: event ? event.isAllDay : false,
        description: event ? event.description : '',
        type: event ? event.type : null,
        idHorse: event ? event.idHorse : null
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewEvent({
            ...newEvent,
            [name]: value
        });
    }

    const handleChangeDateStart = (newValue) => {
        setNewEvent({
            ...newEvent,
            start: newValue
        });
    }

    const handleChangeDateEnd = (newValue) => {
        setNewEvent({
            ...newEvent,
            end: newValue
        });
    }

    const handleAction = (e) => {
        e.preventDefault();
        handleClose();
    }

    return (
        <Root>
            <Title>{translate("event")}</Title>
            <InputGroup>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'start' })}
                        value={newEvent.start}
                        onChange={handleChangeDateStart}
                        disabled
                    />
                    <DateTimePicker
                        renderInput={(props) => <StyledTextfield width="45%" {...props} />}
                        label={intl.formatMessage({ id: 'end' })}
                        value={newEvent.end}
                        onChange={handleChangeDateEnd}
                        disabled
                    />
                </LocalizationProvider>
            </InputGroup>
            <TextField disabled id="title" name="title" handleChange={handleChange} value={newEvent.title} label={intl.formatMessage({ id: 'title' })} variant="outlined" fullWidth margin="dense" size="small" />
            <TextField disabled id="description" name="description" handleChange={handleChange} value={newEvent.description} label={intl.formatMessage({ id: 'description' })} variant="outlined" fullWidth margin="dense" size="small" />
            <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}><CheckIcon /></Button>
        </Root>
    );
}

export default NewEvent;