import React from "react";
import styled from "styled-components";
import {ThemeContext} from '../../../providers/theme';
import translate from '../../../providers/i18n/translate';
import ItemList from "./ItemList";
import GridViewIcon from '@mui/icons-material/GridView';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaceIcon from '@mui/icons-material/Place';
import WebhookIcon from '@mui/icons-material/Webhook';
import LogoutIcon from '@mui/icons-material/Logout';
import { Tooltip } from "@mui/material";
import { useIntl } from 'react-intl';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../providers/authentication";
import HexagonIcon from '@mui/icons-material/Hexagon';
import Private from "../../../providers/authentication/private";
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import HorseIcon from "../../../public/iconCaballoBlanco.png";

const menuList = [
    {
        name: translate("dashboard"),
        link: "/backoffice/dashboard",
        icon: <GridViewIcon />,
    },
    {
        name: translate("users"),
        link: "/backoffice/users",
        icon: <AccountCircleOutlinedIcon />,
        scope: "users",
        private: true
    },
    {
        name: translate("horses"),
        link: "/backoffice/horses",
        icon: <img src={HorseIcon}/>
    },
    {
        name: translate("places"),
        link: "/backoffice/places",
        icon: <PlaceIcon />,
        scope: "locations",
        private: true
    },
    {
        name: translate("calendar"),
        link: "/backoffice/calendar",
        icon: <CalendarTodayIcon />
    },
    {
        name: translate("reports"),
        link: "/backoffice/reports",
        icon: <AssessmentOutlinedIcon />,
        scope: "reports",
        private: true
    },
]

const Root = styled.aside`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${(props) => props.openNav ? "100%" : "50px"};
    display: grid;
    grid-template-rows: 50px 1fr 50px 50px;
    overflow-y: hidden;
    background-color: ${(props) => props.bg ? props.bg : "#000"};
    transition: height 500ms ease-in-out;
    z-index: 9999;
    @media (min-width: 1024px) {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: ${(props) => props.openNav ? props.openWidth : props.closeWidth};
        height: 100%;
        top: inherit;
        left: inherit;
        z-index: inherit;
        overflow-x: hidden;
        overflow-y: inherit;
        grid-template-rows: inherit;
        transition: width 500ms ease-in-out;
        border-right: 1px solid ${(props) => props.border ? props.border : "transparent"};
    }
`

const Switch = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: ${(props) => props.color ? props.color : "#FFF"};
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1);
        }
    }
`

const MenuList = styled.ul`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    @media (min-width: 1024px) {
        display: flex;
        padding: 0;
    }
`

const Logout = styled.div`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    svg {
        transition: all 200ms ease-in-out;
        color: ${(props) => props.color ? props.color : "#FFF"};
    }
    span {
        color: ${(props) => props.color ? props.color : "#FFF"};
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1);
        }
    }
`

const Mole = styled.a`
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: flex-start;
    padding: 0 12.5px;
    cursor: pointer;
    user-select: none;
    transition: all 200ms ease-in-out;
    text-decoration: none;
    svg {
        transform: rotate(90deg);
        transition: all 200ms ease-in-out;
        color: ${(props) => props.color ? props.color : "#FFF"};
    }
    span {
        color: ${(props) => props.color ? props.color : "#FFF"};
        text-transform: uppercase;
        transition: all 300ms ease-in-out;
        opacity: ${(props) => props.showname ? 1 : 0};
        margin: 0 0 0 10px;
        font-size: 0.6rem;
    }
    @media (min-width: 1024px) {
        svg:hover {
            transform: scale(1.1) rotate(270deg);
            color: #00ffbb;
        }
    }
`



const AdminNav = ({openNav, onSwitchNav, closeNav}) => {

    const intl = useIntl();

    let navigate = useNavigate();

    const { onLogout } = useAuth();

    const OPEN_NAV_WIDTH = "200px";
    const CLOSE_NAV_WIDTH = "50px";

    const theme = React.useContext(ThemeContext);

    const logout = () => {
        onLogout(() => navigate("/"));
    }
    

    return (
        <Root openNav={openNav} openWidth={OPEN_NAV_WIDTH} closeWidth={CLOSE_NAV_WIDTH} bg={theme.palette.principal} border={theme.palette.accent}>
            <Switch openNav={openNav}>
                {
                    openNav ?
                    <ArrowBackIcon onClick={onSwitchNav} />
                    :
                    <MenuIcon onClick={onSwitchNav} />
                }
            </Switch>
            <MenuList openNav={false}>
                {
                    menuList && menuList.map((item, index) =>
                        {
                            return item.private ? 
                            <Private scope={item.scope} nullReturn key={`item_${index}_${item.name}`}>
                                <ItemList item={item} color={theme.palette.base} closeNav={closeNav} activecolor={theme.palette.accent} showname={openNav ? 1 : 0} />
                            </Private>
                            :
                            <ItemList key={`item_${index}_${item.name}`} item={item} color={theme.palette.base} closeNav={closeNav} activecolor={theme.palette.accent} showname={openNav ? 1 : 0} />
                        }
                    )
                }
            </MenuList>
            <Tooltip title={intl.formatMessage({ id: 'exit' })} placement={openNav ? "left" : "right"}>
                <Logout onClick={logout} showname={openNav ? 1 : 0}>
                    <LogoutIcon />
                    <span>{translate("exit")}</span>
                </Logout>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'mole' })} placement={openNav ? "top" : "right"}>
                <Mole href="https://molemedia.co" target="_blank" showname={openNav ? 1 : 0}>
                    <HexagonIcon />
                    <span>Mole</span>
                </Mole>
            </Tooltip>
        </Root>
    );
}

export default AdminNav;