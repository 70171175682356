import React from "react";
import styled from 'styled-components';
import { Table } from "../components/tables/table";
import axios from "axios";
import { useIntl } from 'react-intl';
import { useParams } from "react-router-dom";
import TreatmentAccordion from "../components/appointment/diagnosis/treatments/TreatmentAccordion";
import { API_URL } from "../config";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`



const TreatmentPage = () => {

    const tableRef = React.useRef();

    const intl = useIntl();
    
    const { id } = useParams();

    const refresh = () => {
        tableRef.current.clean();
    } 
    

    return (
        <Root>
            <Table 
                ref={tableRef}
                viewTable="accordion"
                accordion={(data) => <TreatmentAccordion data={data} refresh={refresh} />}
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_URL}/clinicHistory/appointments/horse/${id}`,{
                            params: {
                                page: query.page,
                                limit: query.pageSize,
                                order: query.order
                            }
                        })
                        .then(appointments => {
                            const treatments = [];
                            appointments.data.rows.forEach(app => {
                                app.diagnosis.forEach(diag => {
                                    diag.treatments.forEach(treat => {
                                        treatments.push({
                                            ...treat,
                                            diagnosisId: diag.id
                                        });
                                    });
                                });
                            });
                            resolve({rows: treatments})
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
                }
            >
            </Table>
        </Root>
    );
}

export default TreatmentPage;

const treatmentsExample = [
    {
        id: 1,
        name: "Treatment 1",
        description: "Treatment description",
        startDate: "2022-02-01",
        endDate: "2022-03-01"
    },
    {
        id: 2,
        name: "Treatment 2",
        description: "Treatment description 2",
        startDate: "2022-03-01",
        endDate: "2022-04-01"
    }
]