import React from "react";
import axios from "axios";
import { useIntl } from 'react-intl';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { MobileDateRangePicker } from "@mui/lab";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import styled from "styled-components";
import { ThemeContext } from "../../../../providers/theme";

const StyledDatePicker = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
`

const StyledTextField = styled(TextField)`
    
`

const StyledDateRangePicker = styled(MobileDateRangePicker)`
    width: 100%;
`

const StyledButton = styled.button`
    grid-column: 1/3;
    align-self: center;
    color: ${(props) => props.color ? props.color : "blue"};
    background: ${(props) => props.background ? props.background : "blue"};
    border: none;
    outline: none;
    border-radius: 8px;
    margin: 5px 10px 5px 0;
    cursor: pointer;
    width: fit-content;
    padding: 5px 10px;
    justify-self: flex-end;
    font-size: 1rem;
`

const DateFilter = ({filter, values, onChange}) => {

    const intl = useIntl();
    const [value, setValue] = React.useState([null, null]);
    const theme = React.useContext(ThemeContext);

    const clear = () => {
        handleDateChange([null, null])
        if (filter.type === "date") {
            onChange(filter.name, [])
        }
        else{
            onChange(filter.start, [])
            onChange(filter.end, [])
        }
    }

    const handleDateChange = (newValue) => {
        setValue(newValue);
        if (filter.type == "date") {
            value[0] != null && value[1] != null &&
                onChange(filter.name, value)
        }
        else{
            if (value[0] != null && value[1] != null) {
                onChange(filter.start, [value[0]])
                onChange(filter.end, [value[1]])
            }
        }
    }

    React.useEffect(() => {
        values.length === 0 && value && setValue([null, null])
    }, [values])

    return (
        <LocalizationProvider dateAdapter={DateAdapter} localeText={{ start: 'Desde', end: 'Hasta' }}>
            <StyledDateRangePicker
                label="Rango Fechas"
                toolbarTitle="Seleccionar rango fechas"
                startText="Desde"
                endText="Hasta"
                value={value}
                onChange={handleDateChange}
                renderInput={(startProps, endProps) => (
                    <StyledDatePicker>
                        <StyledTextField variant="standard" size="small" {...startProps} label="Desde" />
                        <StyledTextField variant="standard" size="small" {...endProps} label="Hasta"/>
                        <StyledButton color={theme.palette.base} background={theme.palette.principal} onClick={clear}>Limpiar</StyledButton>
                    </StyledDatePicker>
                )}
            />
        </LocalizationProvider> 
    );
}

export default DateFilter;