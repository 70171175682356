import React from "react";
import styled from 'styled-components';
import translate from "../../providers/i18n/translate";
import { useIntl } from 'react-intl';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { Modal, Tooltip } from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import NewAttachment from "../appointment/intervention/NewAttachment";

/* Styled components */

const Root = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.5);
    padding: 10px;
    gap: 2px;
    margin: 5px 0;
`

const InterventionDate = styled.span`
    text-align: right;
    font-size: 0.875rem;
    color: ${props => props.theme.palette.details};
`

const Description = styled.p`
    grid-column: 1/3;
    font-size: 0.65rem;
    font-weight: 400;
    margin-bottom: 5px;
`

const AttachDescription = styled.p`
    width: 100%;
    font-size: 0.65rem;
    font-weight: 300;
    text-align: center;
`

const InterventionType = styled.span`

`

const Attachments = styled.div`
    position: relative;
    grid-column: 1/3;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
`

const Attachment = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-decoration: none;
    color: ${props => props.theme.palette.details};
    transition: all 300ms ease-in-out;
    svg {
        font-size: 2rem;
    }
    &:hover {
        color: ${props => props.theme.palette.accent};
        transform: scale(1.1);
    }
    span {
        font-size: 0.65rem;
    }
`

const ActionsIcon = styled.div`
    position: relative;
    grid-column: 1/3;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 20px;
    cursor: pointer;
    z-index: 99;
    svg {
        font-size: 1rem;
        margin: 0 0 0 10px;
    }
`

const Intervention = ({data, handleEdit, refresh}) => {

    const intl = useIntl();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        refresh()
        setOpen(false);
    }

    const handleEditIntervention = () => {
        handleEdit(data);
    }

    const handleNewAttachment = () => {
        handleOpen();
    }

    React.useEffect(() => {

    }, [JSON.stringify(data)])
    
    return (
        <Root>
            <ActionsIcon >
                <Tooltip title={intl.formatMessage({ id: 'attach file' })}>
                    <AttachFileIcon onClick={handleNewAttachment} />
                </Tooltip>
                <Tooltip title={intl.formatMessage({ id: 'editIntervention' })}>
                    <Edit onClick={handleEditIntervention} />
                </Tooltip>
            </ActionsIcon>
            <InterventionType>{translate(data.type.value)}</InterventionType>
            <InterventionDate>{intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" })}</InterventionDate>
            <Description dangerouslySetInnerHTML={{ __html: data.description }} />
            {
                data.attachment && data.attachment.length > 0 &&
                <Attachments>
                    {
                        data.attachment.map((attachment, index) => 
                        <Tooltip title={attachment.nameFile}>
                            <Attachment key={`attachment_${index}`} href={attachment.url} target="_blank">
                                <FilePresentIcon />
                                <AttachDescription dangerouslySetInnerHTML={{ __html: attachment.description }} />
                            </Attachment>
                        </Tooltip>
                        )
                    }
                </Attachments>
            }
            <Modal
                open={open}
                onClose={handleClose}
            >
                <NewAttachment id={data.id} handleClose={handleClose} />
            </Modal>
        </Root>
    );
}

export default Intervention;