import React from "react";
import styled from 'styled-components';
import { Table } from "../components/tables/table";
import AccordionRow from "../components/tables/table/AccordionRow";
import axios from "axios";

import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import { useIntl } from 'react-intl';
import NewAppointment from "../components/appointment/NewAppointment";
import { Modal, Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import AppointmentAccordion from "../components/appointment/AppointmentAccordion";

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
`

const ExportIcon = styled.a`
    position: fixed;
    right: 25px;
    bottom: 210px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 55px;
        bottom: 110px;
    }
`

const NewIcon = styled.div`
    position: fixed;
    right: 20px;
    bottom: 150px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: ${props => props.theme.palette.accent};
    color: ${props => props.theme.palette.base};
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    &:hover {
        transform: scale(1.1);
    }
    z-index: 99;
    @media (min-width: 1024px) {
        right: 50px;
        bottom: 50px;
    }
`

const AppointmentsPage = () => {

    const intl = useIntl();

    const tableRef = React.useRef();

    const { id } = useParams();

    const [open, setOpen] = React.useState(false);
    const [openEdit, setOpenEdit] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        tableRef.current.clean();
        setOpen(false);
    }
    const handleOpenEdit = () => setOpenEdit(true);
    const handleCloseEdit = () => {
        tableRef.current.clean();
        setOpenEdit(false);
        
    }

    const [ editAppointment, setEditAppointment ] = React.useState(null);

    const handleNewItem = () => {
        handleOpen();
    }

    const handleExport = () => {
        ;
    }
    
    const handleEditItem = (data) => {
        setEditAppointment(data);
        setOpenEdit(true);
    }

    const refresh = () => {
        tableRef.current.clean();
    }
    
    

    return (
        <Root>
            <Table 
                ref={tableRef}
                viewTable="accordion"
                accordion={(dataAccordion) => <AppointmentAccordion data={dataAccordion} handleAction={handleEditItem} refresh={refresh} />}
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_URL}/clinicHistory/appointments/horse/${id}`,{
                            params: {
                                page: query.page,
                                limit: query.pageSize,
                                order: query.order
                            }
                        })
                        .then(appointments => {
                            resolve(appointments.data)
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
                }
            >
            </Table>
            <Tooltip title={intl.formatMessage({ id: 'export' })}>
                <ExportIcon href={`${API_URL}/export/clinicHistoryPDF/${id}`}><DownloadIcon /></ExportIcon>
            </Tooltip>
            <Tooltip title={intl.formatMessage({ id: 'new appointment' })}>
                <NewIcon onClick={handleNewItem}><AddIcon /></NewIcon>
            </Tooltip>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <NewAppointment horseId={id} handleClose={handleClose} />
            </Modal>
            <Modal
                open={openEdit}
                onClose={handleCloseEdit}
            >
                <NewAppointment horseId={id} data={editAppointment} handleClose={handleCloseEdit} />
            </Modal>
        </Root>
    );
}

export default AppointmentsPage;