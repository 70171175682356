import { Navigate } from 'react-router-dom';
import { useAuth } from '.';
  
  
const Protected = ({ children, scopes, access }) => {
    const { token } = useAuth();

    if (!token) {
        return <Navigate to="/" replace />;
    }

    return children;
};


export default Protected;