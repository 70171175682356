import React from "react";
import { Outlet, useParams, useMatch } from "react-router-dom";
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import MediaCenterNav from "../components/navbars/mediaCenterNav";
import AddIcon from '@mui/icons-material/Add';
import { Modal, Tooltip } from "@mui/material";
import NewMedia from "../components/mediacenter/NewMedia";
import { API_URL } from "../config";
import axios from 'axios';

/* Styled components */

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
`



const HorseMediaCenterPage = () => {

    return (
        <MediaCenterNav>
            <Outlet />
        </MediaCenterNav>
    );
}

export default HorseMediaCenterPage;