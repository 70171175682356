import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import SelectInput from "../inputs/SelectInput";
import { API_URL } from "../../config";
import axios from "axios";
import BasicEditor from "../textEditors/basicEditor";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";
import AutocompleteInput from "../inputs/AutocompleteInput";

/* Styled components */
const Root = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const TitleIntervention = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 10px 0 5px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

const NewBatchAppointment = ({ horseId, data, handleClose, horseData }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const dispatch = useDispatch();

  const { loadingAction, loadingProgress } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const [newAppointment, setNewAppointment] = React.useState(
    data
      ? {
          name: data.name,
          info: data.description,
          date: new Date(data.date),
          veterinarianId: {
            label: data.veterinarian.fullName,
            value: data.veterinarian.id,
          },
          veterinarianAssistantId: data.veterinarianAssistant
            ? {
                label: data.veterinarianAssistant?.fullName,
                value: data.veterinarianAssistant?.id,
              }
            : null,

          horseId:
            horseData.horse && horseData.horse !== "null"
              ? {
                  label: horseData.horse?.name,
                  value: horseData.horse?.id,
                }
              : null,
        }
      : {
          name: "",
          info: "",
          date: new Date(),
          veterinarianId: null,
          veterinarianAssistantId: null,
          motherId: null,
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewAppointment({
      ...newAppointment,
      [name]: value,
    });
  };

  const handleChangeDate = (newValue) => {
    setNewAppointment({
      ...newAppointment,
      date: newValue,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setNewAppointment({
      ...newAppointment,
      info: dataeditor,
    });
  };

  const [newIntervention, setNewIntervention] = React.useState(
    data
      ? {
          description: data.description,
          typeId: data.type
            ? {
                label: data.type.value,
                value: data.type.id,
              }
            : null,
        }
      : {
          description: "",
          typeId: null,
        }
  );

  const handleEditorDescription = (e, dataeditor) => {
    setNewIntervention({
      ...newIntervention,
      description: dataeditor,
    });
  };

  const handleChangeAutocompleteIntervention = (name, value) => {
    setNewIntervention({
      ...newIntervention,
      [name]: value,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    loadingAction(loadingConstants.WAIT);
    var formdata = new FormData();
    const horses = newAppointment.horseId?.map((option) => option.value);
    formdata.append("horses", `[${horses}]`);
    formdata.append("name", newAppointment.name);
    formdata.append("info", newAppointment.info ?? "");
    const date = newAppointment.date;
    formdata.append("date", date);

    formdata.append(
      "veterinarianId",
      parseInt(newAppointment.veterinarianId?.value)
    );
    if (newAppointment.veterinarianAssistantId) {
      formdata.append(
        "veterinarianAssistantId",
        newAppointment.veterinarianAssistantId?.value
      );
    }
    if (newIntervention.typeId) {
      formdata.append(
        "interventionType",
        parseInt(newIntervention.typeId?.value)
      );
      formdata.append("interventionInfo", newIntervention.description ?? "");
    }

    const url = `${API_URL}/clinicHistory/appointments/batch`;
    axios
      .post(url, formdata, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        loadingAction(loadingConstants.READY);
        handleClose();
        console.log(response);
        alert(
          intl
            .formatMessage({ id: "NewAppointment" })
            .replace("{0}", response?.data?.added)
        );
      })
      .catch((error) => {
        loadingAction(loadingConstants.READY);
        alert(intl.formatMessage({ id: "errorContact" }));
        console.log(error.response?.data?.msg);
      });
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Root onSubmit={handleAction}>
      <Title>
        {data ? translate("edit appointment") : translate("new appointment")}
      </Title>
      <TextField
        id="name"
        name="name"
        onChange={handleChange}
        value={newAppointment.name}
        label={intl.formatMessage({ id: "issue" })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          renderInput={(props) => (
            <StyledTextfield size="small" margin="dense" fullWidth {...props} />
          )}
          label={intl.formatMessage({ id: "date" })}
          value={newAppointment.date}
          onChange={handleChangeDate}
          required
        />
      </LocalizationProvider>
      <InputGroup>
        <SelectInput
          value={newAppointment.veterinarianId}
          fieldlabel="fullName"
          fieldvalue="id"
          dataurl={`${API_URL}/human/veterinarian`}
          label={intl.formatMessage({ id: "veterinarian" })}
          name="veterinarianId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
          required
        />
        <SelectInput
          value={newAppointment.veterinarianAssistantId}
          fieldlabel="fullName"
          fieldvalue="id"
          dataurl={`${API_URL}/human/veterinarian`}
          label={intl.formatMessage({ id: "veterinarianAssistant" })}
          name="veterinarianAssistantId"
          onChange={handleChangeAutocomplete}
          width="45%"
          margin="dense"
          size="small"
        />
      </InputGroup>
      <InputGroup>
        <AutocompleteInput
          value={newAppointment.horseId}
          fieldlabel="name"
          fieldvalue="id"
          dataurl={`${API_URL}/horses/list`}
          label={intl.formatMessage({ id: "horse" })}
          name="horseId"
          onChange={handleChangeAutocomplete}
          width="100%"
          margin="dense"
          size="small"
          required={!newAppointment.horseId}
        />
      </InputGroup>
      <BasicEditor
        name="info"
        value={newAppointment.info}
        onChange={handleEditor}
        editorLoaded={editorLoaded}
        maxContentHeight="100px"
        minContentHeight="100px"
        placeholder={intl.formatMessage({ id: "insertDescription" })}
      />

      <TitleIntervention>
        {data ? translate("editIntervention") : translate("newIntervention")}
      </TitleIntervention>
      <SelectInput
        value={newIntervention.typeId}
        fieldlabel="value"
        fieldvalue="id"
        dataurl={`${API_URL}/interventionType`}
        label={intl.formatMessage({ id: "interventionType" })}
        name="typeId"
        onChange={handleChangeAutocompleteIntervention}
        width="100%"
        margin="dense"
        size="small"
      />
      <BasicEditor
        name="description"
        value={newIntervention.description}
        onChange={handleEditorDescription}
        editorLoaded={editorLoaded}
        maxContentHeight="100px"
        minContentHeight="100px"
        placeholder={intl.formatMessage({ id: "insertDescription" })}
      />
      <Button bg={theme.palette.accent} color={theme.palette.base}>
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewBatchAppointment;
