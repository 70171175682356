import React from "react";
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0,0,0,0.5);
    background-color: #FFF;
    padding: 10px;
    margin: 5px 0;
    max-height: 300px;
    overflow: auto;
    h5 {
        margin: 5px 0 10px 0;
        font-size: 1rem;
        width: 100%;
        border-bottom: 1px solid rgba(0,0,0,0.5);
        text-transform: uppercase;
    }
`

const Title = styled.h5`
`
const Search = styled.input`
  all: unset;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  border: 1px solid grey;
`

const Options = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    max-height: 250px;
    overflow-y: auto;
    width: 100%;
`

const FilterGroup = ({title, name, search, children, onChange}) => {

    const intl = useIntl();

    const [searchValue, setSearchValue] = React.useState();

    const handleSearchChange = (e) => {
        //setSearchValue(e.target.value);
        onChange(name, e.target.value);
    }

search != null && searchValue != search && setSearchValue(search);

    return (
        <Root>
            {
                title && <Title>{title}</Title>
            }
            {
                search != null && <Search type='text' placeholder={intl.formatMessage({id: 'search'})} value={searchValue} onChange={handleSearchChange}/>
            }

            <Options>
                {children}
            </Options>
        </Root>
    );
}

export default FilterGroup;
