import React, { useState} from 'react';
import styled from 'styled-components/macro';
import {mob, mobText, desk, deskText} from '../utilities';
import homeIco from '../public/home.svg'
import {useSwipeable} from 'react-swipeable';
import { Link, NavLink, useLocation } from "react-router-dom";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

// styled components
const Root = styled.div`
    position: relative;    
    box-sizing: border-box;
    height: auto;
    width: 100%;
    top: 0;
    z-index: 100;
    @media (min-width: 1024px){
        height: ${desk(182.25)};
    }
`
const Nav = styled.nav`
    position: fixed;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: ${mob(35)};
    padding: 0 ${mob(30)} 0 ${mob(30)};
    background-color: rgba(255,255,255,0.65);
    @media (min-width: 1024px){
        /* padding: ${desk(100)} ${desk(100)} ${desk(33)} ${desk(100)}; */
        background-color: rgba(255,255,255,0.65);
        height: ${desk(182.25)};
    }
`
const Hamburger = styled.div`
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: ${mob(17.78)};
    height: ${mob(12.5)};
    input{
        margin: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    span{
        position: relative;
        width: 100%;
        height: ${mob(2)};
        background-color: #D1AC00;
        transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
        opacity 0.55s ease;
        z-index: 1;
    }
    span:nth-of-type(1){
        transform-origin: 0% 0%;
    }
    span:nth-of-type(3){
        transform-origin: 0% 100%;
    }
    input:checked ~ span:nth-of-type(1){
        transform: rotate(45deg) translate(-0.23364485981vw, -0.23364485981vw);
    }
    input:checked ~ span:nth-of-type(2){
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
    }
    input:checked ~ span:nth-of-type(3){
        transform: rotate(-45deg) translate(0vw);
    }
    input:checked ~ ul{
        transform: none;
        box-shadow: 0 0 10px #85888C; 
    }
    @media (min-width: 1024px){
        width: 100%;
        height: 100%;
        span{
            display: none;
        }
        input{
            display: none;
        }
    }
`
const Menu = styled.ul`
    all: unset;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 4.86vh;
    padding-bottom: 21.64vh;
    padding-left: ${mob(30)};
    /* width: ${mob(383-30)}; */
    width: 100vw;
    height: 100vh;
    /* box-shadow: 0 0 10px #85888C; */
    background-color: #00271C;
    transform: translate(-100%, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    box-shadow 0.5s ease;
    li{
        all: unset;
        font-family: helvetica;
        font-weight: 100;
        font-size: ${mobText(30)};
        color: white;
        a{
            all: unset;
        }
    }
    @media (min-width: 1024px){
        width: 100%;
        height: 100%;
        padding: 0;
        transform: none;
        position: relative;
        flex-direction: row;
        align-items: center;
        background-color: unset;
        li{
            font-size: ${deskText(27)};
            color: #211F20;
            cursor: pointer;
            transition: text-shadow 0.3s ease;
            :hover{
                text-shadow: 1px 1px 2px grey;
            }
            a {
                all: unset;
            }
        }
        .active{
            color: ${props => props.theme.palette.font};
        }
    }
`

const Home = styled.div`
    position: relative;
    box-sizing: border-box;
    width: ${mob(15)};
    height: ${mob(18.17)};
    
    img{
        height: 100%;
        width: 100%;
    }
    a{
        all: unset;
        position: absolute;
        width: ${mob(15)};
        height: ${mob(18.17)};
    }
    @media (min-width: 1024px){
        display: none;
    }
`
const SwipeZone = styled.div`
    position: absolute;
    height: 100vh;
    width: 100%;
    @media (min-width: 1024px){
        display: none;
    }
`
const UserContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${mob(44.6)};
    width: ${mob(29.73)};
    margin: 1em;
    color: ${props => props.theme.palette.accent};
    border: solid 3px ${props => props.theme.palette.accent};
    border-radius: 3em;
    padding:0.3em;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.4);
    
    svg {
        color: ${props => props.theme.palette.accent};
        width: 100%;
        height: 100%;
    }
    @media (min-width: 1024px){
        width: ${desk(60)};
        height: ${desk(60)};
    }
`
const LogoContainer = styled.div`
    position: relative;
    box-sizing: border-box;
    display: none;
    @media (min-width: 1024px){
        display: block;
        width: auto;
        height: ${desk(100)};
        border-radius: 50%;
        transition: opacity 0.3s ease; 
        :hover{
            opacity: 0.9;
        }
        img{
            height: 100%;
        }
    }
`

export default function Navbar() {

    const [checkStatus, setCheckStatus] = useState(false);

    let location = useLocation();

    const handlers = useSwipeable({
        onSwipedLeft: () => {setCheckStatus(false)},
        onSwipedRight: () => {setCheckStatus(true)},
        delta: 20
    });
    /* const { token } = useAuth(); */

    const token = JSON.parse(localStorage.getItem('token'));

    return (
            <Root>
                <Nav>
                    <Hamburger {...handlers}><input type="checkbox" checked={checkStatus} onClick={e => {setCheckStatus(!checkStatus)}}/><span/><span/><span/>
                        <SwipeZone/>
                        <Menu>
                            <LogoContainer><NavLink to="/"><img src={require("../public/Logo-GFV-Ecuestre---color.png")}/></NavLink></LogoContainer>
                            {/* <li>Nosotros</li>
                            <li><NavLink onClick={e => {setCheckStatus(false)}} to="caballos">Nuestros Caballos</NavLink></li> */}
                            {/* <li>Primeros Pasos</li> */}
                            {/* <li><NavLink onClick={e => {setCheckStatus(false)}} to="disciplinas/CyD">Cría y Doma</NavLink></li>
                            <li><NavLink onClick={e => {setCheckStatus(false)}} to="disciplinas/educacion">Educación</NavLink></li>
                            <li><NavLink onClick={e => {setCheckStatus(false)}} to="disciplinas/competencia">Competencia</NavLink></li>
                            <li><NavLink onClick={e => {setCheckStatus(false)}} to="media">Media</NavLink></li> */}
                            {
                                token ?
                                <UserContainer><Link to="/backoffice/dashboard"><PersonOutlineIcon/></Link></UserContainer>    
                                :
                                <UserContainer><Link to="/login" state={{ backgroundLocation: location }}><PersonOutlineIcon/></Link></UserContainer>
                            }
                        </Menu>
                    </Hamburger>
                    <Home><Link onClick={e => {setCheckStatus(false)}} to="/"/><object type="image/svg+xml" data={homeIco}/></Home>
                </Nav>
            </Root>
    )
}
