import {horsesConstants} from "../reducers/horses/horsesConstants";

export const depositMoney = (amount) => {
    return (dispatch) => {
        dispatch({
            type: "deposit",
            payload: amount
        });
    }
}


export const withdrawMoney = (amount) => {
    console.log(amount, "Lol")
    return (dispatch) => {
        dispatch({
            type: "withdraw",
            payload: amount
        });
    }
}

export const alertMessage = (msg, type) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "ALERT_CLEAR",
            message: msg
        });
    }
}

export const loadingAction = (type) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "LOADING_ERROR"
        });
    }
}

export const loadingProgress = (type, progress) => {
    return (dispatch) => {
        dispatch({
            type: type ? type : "LOADING_PROGRESS",
            progress: progress
        });
    }
}

export const changeHorsesSearch = (search) => {
    return (dispatch) => {
        dispatch({
            type: horsesConstants.CHANGE_SEARCH,
            payload: search
        });
    }
}

export const clearHorsesSearch = () => {
    return (dispatch) => {
        dispatch({
            type: horsesConstants.CLEAR_SEARCH
        });
    }
}