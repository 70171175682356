import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import SelectInput from '../inputs/SelectInput';
import { API_URL } from '../../config';
import axios from 'axios';
import BasicEditor from '../textEditors/basicEditor';
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

/* Styled components */
const Root = styled.form`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    background-color: #FFF;
    border-radius: 8px;
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    @media (min-width: 1024px) {
        width: 600px;
    }
`

const Title = styled.h2`
    width: 100%;
    border-bottom: 1px solid ${props => props.borderColor ? props.borderColor : "#000"};
    text-transform: uppercase;
    color: ${props => props.color ? props.color : "#000"};
    margin: 0 0 10px 0;
`

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: ${props => props.bg ? props.bg : "#7A7A7A"};
    color: ${props => props.color ? props.color : "#FFF"};
    border: none;
    outline: none;
    cursor: pointer;
    margin: 10px 0;
    transition: all 300ms ease-in-out;
    &:hover {
        transform: scale(1.1);
    }
`

const StyledTextfield = styled(TextField)`
    width: 100%;
    @media (min-width: 1024px) {
        width: ${props => props.width ? props.width : "inherit"};
    }
`

const InputGroup = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    flex-direction: column;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
`

const NewAppointment = ({horseId, data, handleClose}) => {

    const theme = React.useContext(ThemeContext);

    const intl = useIntl();

    const [editorLoaded, setEditorLoaded] = React.useState(false);

    const dispatch = useDispatch();

    const {loadingAction, loadingProgress} = bindActionCreators(actionCreators, dispatch);

    const [ newAppointment, setNewAppointment ] = React.useState(data ? 
        {
            name: data.name,
            info: data.description,
            date: new Date(data.date),
            veterinarianId: {
                label: data.veterinarian.fullName,
                value: data.veterinarian.id
            },
            veterinarianAssistantId: data.veterinarianAssistant ? {
                label: data.veterinarianAssistant?.fullName,
                value: data.veterinarianAssistant?.id
            } : null
        }
        :
        {
            name: '',
            info: '',
            date: new Date(),
            veterinarianId: null,
            veterinarianAssistantId: null
        }
    )

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewAppointment({
            ...newAppointment,
            [name]: value
        });
    }

    const handleChangeAutocomplete = (name, value) => {
        setNewAppointment({
            ...newAppointment,
            [name]: value
        });
    }

    const handleChangeDate = (newValue) => {
        setNewAppointment({
            ...newAppointment,
            date: newValue
        });
    }

    const handleEditor = (e, dataeditor) => {
        setNewAppointment({
            ...newAppointment,
            info: dataeditor
        })
    }

    const handleAction = (e) => {
        e.preventDefault();
        loadingAction(loadingConstants.WAIT);
        var formdata = new FormData();
        formdata.append("horseId", parseInt(horseId));
        if(newAppointment){
            formdata.append("name", newAppointment?.name);
            formdata.append("info", newAppointment?.info);
            formdata.append("date", newAppointment?.date);
            formdata.append("veterinarianId", parseInt(newAppointment.veterinarianId?.value));
            formdata.append("veterinarianAssistantId", newAppointment.veterinarianAssistantId?.value);
        }
        if(data){
            const url = `${API_URL}/clinicHistory/appointments/${data.id}`;
            axios.put(url, formdata,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                },
                //Listen for the onuploadprogress event
                onUploadProgress: e => {
                    const {loaded, total} = e;
                    //Using local progress events
                    if (e.lengthComputable) {
                        let progress = loaded / total * 100;
                        loadingProgress(loadingConstants.PROGRESS, progress);
                    }
                }
            })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful editing' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                alert(intl.formatMessage({ id: 'errorContact' }));
                console.log(error.response.data.msg);
            })
        } else {
            const url = `${API_URL}/clinicHistory/appointments`;
            axios.post(url, formdata,
                {
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data'
                }
                })
            .then(response => {
                loadingAction(loadingConstants.READY);
                handleClose();
                /* alert(intl.formatMessage({ id: 'successful creation' })); */
            })
            .catch(error => {
                loadingAction(loadingConstants.READY);
                alert(intl.formatMessage({ id: 'errorContact' }));
                console.log(error.response?.data?.msg);
            })
        }
    }

    React.useEffect(() => {
        setEditorLoaded(true);
    }, [])

    return (
        <Root onSubmit={handleAction}>
            <Title>{data ? translate("edit appointment") : translate("new appointment")}</Title>
            <TextField id="name" name="name" onChange={handleChange} value={newAppointment.name} label={intl.formatMessage({ id: 'issue' })} variant="outlined" fullWidth margin="dense" size="small" required />
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DatePicker
                    renderInput={(props) => <StyledTextfield size="small" margin="dense" fullWidth {...props} />}
                    label={intl.formatMessage({ id: 'date' })}
                    value={newAppointment.date}
                    onChange={handleChangeDate}
                    required
                />
            </LocalizationProvider>
            <InputGroup>
                <SelectInput value={newAppointment.veterinarianId} fieldlabel="fullName" fieldvalue="id" dataurl={`${API_URL}/human/veterinarian`} label={intl.formatMessage({ id: 'veterinarian' })} name="veterinarianId" onChange={handleChangeAutocomplete} width="45%" margin="dense" size="small" required />
                <SelectInput value={newAppointment.veterinarianAssistantId} fieldlabel="fullName" fieldvalue="id" dataurl={`${API_URL}/human/veterinarian`} label={intl.formatMessage({ id: 'veterinarianAssistant' })} name="veterinarianAssistantId" onChange={handleChangeAutocomplete} width="45%" margin="dense" size="small" />
            </InputGroup>
            <BasicEditor
                name="info"
                value={newAppointment.info}
                onChange={handleEditor}
                editorLoaded={editorLoaded}
                maxContentHeight="100px"
                minContentHeight="100px"
                placeholder={intl.formatMessage({ id: 'insertDescription' })}
            />
            <Button bg={theme.palette.accent} color={theme.palette.base}><CheckIcon /></Button>
        </Root>
    );
}

export default NewAppointment;