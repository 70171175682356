import React from 'react';
import styled, {css} from 'styled-components/macro';
import { desk } from '../utilities.js'

// styled components
const Root = styled.div`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`
const ImgContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: ${props => props.color } ;
    img{
        filter: brightness(0) invert(1);
    }
    @media(min-width: 1024px){
        img{
            ${props => {
                switch (props.type) {
                    case "dna":
                        return(
                            css`
                                width: 130%;
                                height: 130%;
                                transform: rotate(23deg) translate(-3%, -1%);
                            `
                        )
                    break;
                    case "horse":
                        return(
                            css`
                                width: 180%;
                                height: 180%;
                                transform: translate(0.4%, -1%);
                            `
                        )
                    break;
                    case "eye":
                        return(
                            css`
                                width: 180%;
                                height: 180%;
                            `
                        )
                    break;
                    case "horseshoe":
                        return(
                            css`
                                width: 180%;
                                height: 180%;
                                transform: translate(0%);
                            `
                        )
                    break;
                    case "cyd":
                        return(
                            css`
                                width: 150%; 
                                height: 150%;
                                transform: translate(-3%, -3%);
                            `
                        )
                    break;
                    case "edu":
                        return(
                            css`
                                width: 70%;
                                height: 70%;
                                transform: translate(0%, 0%);
                            `
                        )
                    break;
                    case "comp":
                        return(
                            css`
                                width: 140%;
                                height: 140%;
                                transform: translate(-1%, 0%);
                            `
                        )
                    break;
                }
            }}
        }
    }
`

export default function Circle(props) {

    let svg;

    switch (props.type) {
        case "comp":
            svg = require("../public/comp.svg").default;
            break;
        case "cyd":
            svg = require("../public/cyd.svg").default;
            break;
        case "edu":
            svg = require("../public/edu.svg").default;
            break;
        case "dna":
            svg = require("../public/dna.svg").default;
            break;
        case "horse":
            svg = require("../public/horse.svg").default;
            break;
        case "eye":
            svg = require("../public/eye.svg").default;
            break;
        case "horseshoe":
            svg = require("../public/horseshoe.svg").default;
            break;
    }

    return (
        <Root>
            <ImgContainer color={props.color} type={props.type}><img src={svg}/></ImgContainer>
        </Root>
    )
}
