import React from "react";
import styled from "styled-components";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import translate from "../../providers/i18n/translate";
import { useIntl } from "react-intl";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

/* Styled components */

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const SelectInput = ({
  label,
  name,
  value,
  onChange,
  width,
  size,
  margin,
  fieldlabel,
  fieldvalue,
  fieldextra1,
  fieldextra2,
  dataurl,
  required,
  disabled,
  subfield,
  withCredentials,
  defaultValue,
  translateDisabled
}) => {
  const [options, setOptions] = React.useState(null);

  const [search, setSearch] = React.useState("");

  const intl = useIntl();

  const handleChange = (event, newValue) => {
    onChange(name, newValue);
  };

  const foundDefaultValue = options?.find(
    (option) => option.label === defaultValue
  );
  React.useEffect(() => {
    if (!options) {
      axios
        .get(dataurl, { withCredentials: withCredentials ? true : false })
        .then((result) => {
          const dataRows = subfield ? result.data[subfield] : result.data;
          const opts = dataRows.map((opt) => {
            return {
              label: fieldlabel ? opt[fieldlabel] : null,
              value: fieldvalue ? opt[fieldvalue] : null,
              extra1: fieldextra1 ? opt[fieldextra1] : null,
              extra2: fieldextra2 ? opt[fieldextra2] : null,
              key: opt["key"],
            };
          });
          setOptions(opts);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [options]);

  return options ? (
    <StyledAutocomplete
      value={defaultValue && value === null ? foundDefaultValue : value}
      onChange={handleChange}
      inputValue={search}
      onInputChange={(event, newInputValue) => {
        setSearch(newInputValue);
      }}
      width={width}
      size={size}
      disabled={disabled}
      id={`id_autocomplete_${name}`}
      options={options}
      getOptionLabel={(option) =>( translateDisabled ? option.label :
        intl.formatMessage({ id: option.label, defaultMessage: option.label })
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate(label)}
          variant="outlined"
          required={required}
          margin={margin}
          disabled={disabled}
        />
      )}
    />
  ) : (
    <Box sx={{ width: "45%" }}>
      <Skeleton variant="h2" />
    </Box>
  );
};

export default SelectInput;
