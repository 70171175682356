import React from "react";

const ContentAccordion = ({children}) => {
    return (
        <React.Fragment>
            { children }
        </React.Fragment>
    );
}

export default ContentAccordion;