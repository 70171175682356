import React, { useContext } from "react";
import styled from "styled-components/macro";
import { mob, mobText, desk, deskText } from "../utilities";
import { ThemeContext } from "../providers/theme";
import Circle from "../components/Circle";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { Link } from "react-router-dom";

const Root = styled.div`
  position: relative;
  box-sizing: border-box;
  font-family: helvetica;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  position: relative;
  font-size: ${mobText(16)};
  line-height: ${mobText(21)};
  font-weight: 100;
  color: ${(props) => props.theme.palette.font};
`;
const CardContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: ${mob(265)};
  gap: ${mob(15)};
  height: auto;
  width: 100%;
  padding: ${mob(17)} ${mob(30)} ${mob(21)} ${mob(30)};
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: ${desk(815)};
    gap: ${desk(50)};
  }
`;
const Card = styled.div`
  position: relative;
  box-sizing: border-box;
  border: 0.75px solid #56876d;
  background-color: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: auto;
  height: auto;
`;
const CardImg = styled.div`
  position: relative;
  width: 100%;
  height: 48.2%;
  display: grid;
  grid-template-columns: 41.74% 16.52% 41.74%;
  grid-template-rows: 77.02% 13.04% 9.94%;
  img {
    grid-column: 1 / 4;
    grid-row: 1 / 3;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 1024px) {
    height: 48.43%;
    grid-template-columns: 1fr 16.7% 1fr;
    grid-template-rows: 77% 13.5% 9.5%;
  }
`;
const CircleContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  grid-column: 2 / 3;
  grid-row: 2 / 4;
  width: 100%;
  height: 100%;
`;
const CardName = styled.span`
  color: ${(props) => props.theme.palette.accent};
  font-size: ${mobText(18)};
  line-height: ${mobText(21)};
  margin-top: ${mob(10)};
  @media (min-width: 1024px) {
    font-size: ${deskText(53)};
    margin-top: 6.17%;
  }
`;
const CardBirth = styled.span`
  color: ${(props) => props.theme.palette.font};
  font-size: ${mobText(7)};
  line-height: ${mobText(7)};
  margin-bottom: ${mob(16)};
  @media (min-width: 1024px) {
    font-size: ${deskText(23)};
    margin-top: 0.91%;
    margin-bottom: 8.53%;
  }
`;
const CardParent = styled.span`
  color: ${(props) => props.theme.palette.font};
  font-size: ${mobText(6)};
  line-height: ${mobText(6)};
  margin-bottom: ${mob(6)};
  @media (min-width: 1024px) {
    font-size: ${deskText(19)};
    margin-bottom: 2.72%;
  }
`;
const CardParentName = styled.span`
  color: ${(props) => props.theme.palette.accent};
  font-size: ${mobText(7)};
  line-height: ${mobText(7)};
  margin-bottom: ${mob(16)};
  @media (min-width: 1024px) {
    font-size: ${deskText(23)};
    margin-bottom: 8.35%;
  }
`;
const Back = styled.div`
  position: relative;
  color: ${(props) => props.theme.palette.accent};
  width: ${mob(30)};
  height: ${mob(30)};
  margin-top: ${mob(35)};
  margin-bottom: ${mob(32)};
  svg {
    width: 100%;
    height: 100%;
  }
  @media (min-width: 1024px) {
    width: ${desk(90)};
    height: ${desk(90)};
    margin-top: ${desk(203)};
  }
`;

export default function Caballos() {
  const theme = useContext(ThemeContext);
  const img = [
    {
      id: 1,
      name: "rr kuka du lys",
      url: require("../public/KUKA DU LYS.jpeg"),
      birth: "Nacida el 28-11-2018",
      father: "CORNET DU LYS",
      mother: "QUINTA 27",
    },
    {
      id: 2,
      name: "rr zaropek",
      url: require("../public/ZAROPEK.jpeg"),
      birth: "Nacido el 25-8-2018",
      father: "CASALL ASK",
      mother: "ROYAL CROISSANT",
    },
    {
      id: 3,
      name: "rr rey canuto",
      url: require("../public/REY CANUTO.jpeg"),
      birth: "Nacido el 26-12-2018",
      father: "CORNET DU LYS",
      mother: "ORIANA",
    },
    {
      id: 4,
      name: "rr copito",
      url: require("../public/COPITO.jpeg"),
      birth: "Nacido el 4-1- 2019",
      father: "CON AIR",
      mother: "FETZI",
    },
    {
      id: 5,
      name: "rr fortuna",
      url: require("../public/FORTUNA.jpeg"),
      birth: "Nacida el 8-2-2019",
      father: "CON AIR",
      mother: "ORIANA",
    },
  ];

  return (
    <Root>
      <Title>Nuestros Caballos</Title>
      <CardContainer>
        {img.map((d, idx) => {
          return (
            <Card key={idx} id={idx}>
              <CardImg>
                <img src={d.url} />
                <CircleContainer>
                  <Circle type="galardon" color={theme.palette.accent} />
                </CircleContainer>
              </CardImg>
              <CardName fontColor={theme.palette.accent} id={idx}>
                {d.name}
              </CardName>
              <CardBirth id={idx}>{d.birth}</CardBirth>
              <CardParent fontColor={theme.palette.font} id={idx}>
                Padre
              </CardParent>
              <CardParentName fontColor={theme.palette.accent} id={idx}>
                {d.father}
              </CardParentName>
              <CardParent fontColor={theme.palette.font} id={idx}>
                Madre
              </CardParent>
              <CardParentName fontColor={theme.palette.accent} id={idx}>
                {d.mother}
              </CardParentName>
            </Card>
          );
        })}
      </CardContainer>
      <Link to="/">
        <Back>
          <ArrowCircleLeftOutlinedIcon />
        </Back>
      </Link>
    </Root>
  );
}
