import React from "react";
import styled from 'styled-components';
import { Table } from "../tables/table";
import axios from "axios";
import { API_URL } from "../../config";
import CompetitionAccordion from "./CompetitionAccordion";
import { useIntl } from 'react-intl';

const Root = styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    @media (min-width: 1024px) {
        height: 100%;
    }
`

const Competitions = () => {

    const intl = useIntl();

    const tableRef = React.useRef();

    const options = {
        pagination: false
    }

    return (
        <Root>
            <Table 
                title={intl.formatMessage({ id: 'trainings' })}
                disableTotalTitle
                ref={tableRef}
                options={options}
                viewTable="accordion"
                disablePagination
                accordion={(dataAccordion) => <CompetitionAccordion data={dataAccordion} />}
                data={
                    (query) => new Promise((resolve, reject) => {
                        axios.get(`${API_URL}/mediacenter/competitions`)
                        .then(competitions => {
                            resolve({rows: competitions.data.data, total: competitions.data.data.length})
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    })
                }
            >
            </Table>
        </Root>
    );
}

export default Competitions;