function mob(val){
    val = val * 100 / 428
    return(val.toString()+'vw')
}
function mobText(val){
    val = mob(val)
    return( "calc(1rem + ("+val+" - 16px));")
}
function desk(val){
    val = val * 100 / 1920
    return(val.toString()+'vw')
}
function deskText(val){
    val = desk(val)
    return("calc(1rem + ("+val+" - 16px));")
}
function carDesk(val){
    val = val * 100 / 2900
    return(val.toString()+'vw')
}
function carDeskText(val){
    val = carDesk(val)
    return("calc(1rem + ("+val+" - 16px));")
}
function ppDesk(val){
    val = val * 100 / 2300
    return(val.toString()+'vw')
}
function ppDeskText(val){
    val = ppDesk(val)
    return("calc(1rem + ("+val+" - 16px));")
}
function hsjdmDesk(val){
    val = val * 100 / 2450
    return(val.toString()+'vw')
}
function hsjdmDeskText(val){
    val = hsjdmDesk(val)
    return("calc(1rem + ("+val+" - 16px));")
}
function msDesk(val){
    val = val * 100 / 4900
    return(val.toString()+'vw')
}
function msDeskText(val){
    val = msDesk(val)
    return("calc(1rem + ("+val+" - 16px));")
}
function footDesk(val){
    val = val * 100 / 2500
    return(val.toString()+'vw')
}
function footDeskText(val){
    val = footDesk(val)
    return("calc(1rem + ("+val+" - 16px));")
}

export {mob, mobText, desk, deskText, carDesk, carDeskText, ppDesk, ppDeskText, hsjdmDesk, hsjdmDeskText, msDesk, msDeskText, footDesk, footDeskText}