import React from "react";
import { ThemeContext } from "../../../providers/theme";
import { NavLink, Outlet } from "react-router-dom";
import styled from "styled-components";
import translate from "../../../providers/i18n/translate"

/* Styled components */
const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    background-color: #FFF;
    border-radius: 10px;
`

const Nav = styled.ul`
    position: relative;
    display: none;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.theme.palette.principal};
    border-bottom: solid white 1px;
    width: 100%;
    height: 40px;
    border-radius: 1em 0 0 0;
    @media (min-width: 1024px) {
        display: flex;
    }
`

const StyledNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: ${(props) => props.color ? props.color : "#D1AC00"};
    font-size: 1.25rem;
    text-transform: uppercase;
    opacity: 0.5;
    margin: 0 20px;
    &.active {
        opacity: 1;
    }
    @media (min-width: 1024px) {
        font-size: 1.25rem;
    }
`

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    @media (min-width: 1024px) {
        height: calc(100% - 40px);
    }
`

const HorseLayoutNav = ({children}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <Root>
            <Nav>
                <StyledNavLink to="clinichistory" color={theme.palette.base}>{translate("clinic history")}                   
                </StyledNavLink>
                <StyledNavLink to="mediacenter" color={theme.palette.base}>
                    <span>{translate("mediacenter")}</span>
                </StyledNavLink>
                <StyledNavLink to="reproduction" color={theme.palette.base}>
                    <span>{translate("reproduction")}</span>
                </StyledNavLink>
            </Nav>
            <Content>
                {children}
            </Content>
        </Root>
    );
}

export default HorseLayoutNav;