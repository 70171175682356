import React from 'react';
import styled from 'styled-components/macro';
import VermasButton from './vermasbutton';
import {mob, mobText, msDesk, msDeskText, deskText} from '../utilities'
import {Link} from 'react-router-dom';

const Root = styled.section`
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #00271C;
    border: 1px solid #707070;
    font-family: helvetica;
    padding: ${mob(21)} ${mob(20)} ${mob(14)} ${mob(20)};
    @media(min-width: 1024px){
        padding: ${msDesk(87)} ${msDesk(79.5)} ${msDesk(80)} ${msDesk(79.5)};
    }
`
const Title = styled.span`
    position: relative;
    width: auto;
    height: auto;
    font-size: ${mobText(11)};
    line-height: ${mobText(12)};
    color: white;
    font-weight: bold;
    text-align: left;
    @media(min-width: 1024px){
        width: ${msDesk(1561)};
        font-size: ${msDeskText(60)};
        line-height: ${msDeskText(45)};
    }
`
const ContentContainer = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: ${mob(388)};
    height: ${mob(219+8+129)};
    margin: ${mob(16)} 0 ${mob(16)} 0;
    @media(min-width: 1024px){
        width: ${msDesk(1561)};
        height: ${msDesk(879+5+522)};
        margin: ${msDesk(65)} 0 ${msDesk(92)} 0;
    }
`
const ImgContainer = styled.div`
    position: relative;
    width: ${props => props.id == 0 ? '100%' : '33%'};
    height: ${props => props.id == 0 ? '61.52%' : '36.24%'};
    align-self: ${props => props.id > 0 ? 'flex-end' : ''};
    background-color: white;
    @media(min-width: 1024px){
        height: ${props => props.id == 0 ? '62.52%' : '37.13%'};
        width: ${props => props.id > 0 ? '33.12%' : ''};
    }
`

const img = [{'id': 1, 'name':'rr kuka du lys', 'url':require("../public/KUKA DU LYS.jpeg"), 'birth':'Nacida el 28-11-2018', 'father':'CORNET DU LYS', 'mother':'QUINTA 27'},{'id': 2, 'name':'rr zaropek', 'url':require('../public/ZAROPEK.jpeg'), 'birth':'Nacido el 25-8-2018', 'father':'CASALL ASK', 'mother':'ROYAL CROISSANT'},{'id': 3,'name':'rr rey canuto', 'url':require('../public/REY CANUTO.jpeg'), 'birth':'Nacido el 26-12-2018', 'father':'CORNET DU LYS', 'mother':'ORIANA'},{'id': 4, 'name': 'rr copito', 'url':require('../public/COPITO.jpeg'), 'birth':'Nacido el 4-1- 2019', 'father':'CON AIR', 'mother':'FETZI'}]

export default function () {
  return (
      <Root>
            <Title>MEDIA</Title>
            <ContentContainer>
                {img.map((d,idx) => {
                    return (
                    <ImgContainer key={d.id} id={idx}>{d.name}</ImgContainer>
                    )
                })}
            </ContentContainer>
            <Link style={{all: 'unset'}} to='media'><VermasButton height="20"></VermasButton></Link>
      </Root>
  )
}
