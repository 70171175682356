import React from "react";
import {useSelector, useDispatch} from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../store/action-creators";
import { loadingConstants } from "../../store/reducers/loading/loadingConstants";

export default function Loading(){

    const {open} = useSelector(state => state.loading);
    const dispatch = useDispatch();

    const {loadingAction} = bindActionCreators(actionCreators, dispatch);

    console.log("LOADING")
    console.log(open)

    return(
        <div>
            <h1>{
                open ?
                "LOADING"
                :
                "READY"
            }</h1>
            <button onClick={() => loadingAction(loadingConstants.WAIT)}>WAIT</button>
            <button onClick={() => loadingAction(loadingConstants.READY)}>READY</button>
        </div>
    )
}
