import React from 'react'
import styled from 'styled-components/macro'
import {mob, mobText, footDesk, footDeskText} from '../utilities';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Link } from 'react-router-dom';

const Root = styled.footer`
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
    height: auto;
    padding: ${mob(11)} ${mob(30)} ${mob(5)} ${mob(35)};
    background-color: #00271C;
    font-family: helvetica;
    font-size: ${mobText(8)};
    line-height: ${mobText(11)};
    color: white;
    font-weight: 100;
    @media(min-width: 1024px){
        padding: ${footDesk(54)} ${footDesk(130)} ${footDesk(41)} ${footDesk(130)};
        font-size: ${footDeskText(26)};
        line-height: ${footDeskText(35)};
        background-color: #56876D;
    }
`
const ContactInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${mob(10)};
    @media(min-width: 1024px){
        gap: ${footDesk(30)};
    }
`
const ContactRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: auto;
    height: auto;
`
const ContactRowIcon = styled.div`
    height: ${mob(18.4696569921)};
    svg{
        height: 100%;
        fill: white;
    }
    @media(min-width: 1024px){
        height: ${footDesk(63)};
        svg{
            width: auto;
        }
    }
    :hover{
        cursor: pointer;
        opacity: 0.8;
    }
`
const ContactRowText = styled.span`
    position: relative;
    width: auto;
    height: auto;
    margin-left: ${mob(4)};
`
const Logo = styled.img`
    position: relative;
    height: ${mob(55)};
    width: ${mob(55)};
    @media(min-width: 1024px){
        height: ${footDesk(239)};
        width: ${footDesk(239)};
    }
`
const Location = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 100%;
`
const LocationIcon = styled.div`
    position: relative;
    @media(min-width: 1024px){
        height: ${footDesk(69)};
        svg{
            height: 100%;
            width: auto;
        }
    }
`
const LocationText = styled.span`
    position: relative;
    width: ${mob(74)};
    height: auto;
`

export default function Footer() {
  return (
      <Root>
          <ContactInfo>
              <ContactRow><ContactRowIcon><PhoneIphoneOutlinedIcon/></ContactRowIcon><ContactRowText></ContactRowText></ContactRow>
              <ContactRow><ContactRowIcon><MailOutlinedIcon/></ContactRowIcon><ContactRowText></ContactRowText></ContactRow>
              <ContactRow><ContactRowIcon><YouTubeIcon/></ContactRowIcon></ContactRow>
              <ContactRow><ContactRowIcon><InstagramIcon/></ContactRowIcon></ContactRow>
          </ContactInfo>
          <Logo src={require("../public/2_Logo 2.png")} alt="logo"></Logo>
          <Location><LocationIcon><LocationOnOutlinedIcon/></LocationIcon><LocationText>Oficinas centrales Av. Santamarina 380 Tandil - Buenos Aires</LocationText></Location>
      </Root>
  )
}
