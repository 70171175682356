import styled from 'styled-components';
import { useIntl } from "react-intl";
import HorseImg from './jumpingHorse.gif';
import { LinearProgress } from '@mui/material';

const Root = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 999999999;
    background-color: rgba(255,255,255,0.7);
`

const Text = styled.span`
    font-size: 1.25rem;
`

const ProgressBar = styled.div`
    width: 200px;
    margin: 10px 0;
`

const HorseLoading = ({progress}) => {

    const intl = useIntl();

    return (
        <Root>
            <img width="150px" height="150px" src={HorseImg} alt="Horse loading" />
            <Text>{`${intl.formatMessage({ id: 'loading' })} ${progress ? `${parseInt(progress)}%` : "..."}`}</Text>
            <ProgressBar>
                {
                    progress ?
                    <LinearProgress color="inherit" variant="determinate" value={progress} />
                    : null
                }
            </ProgressBar>
            
        </Root>
    );
}

export default HorseLoading;