import React from "react";
import styled from 'styled-components';
import AccordionRow from "../../tables/table/AccordionRow";
import HeadAccordion from "../../tables/table/HeadAccordion";
import ContentAccordion from "../../tables/table/ContentAccordion";
import { useIntl } from "react-intl";
import Intervention from "../../clinichistory/Intervention";
import NewIntervention from "./NewIntervention";
import { Modal } from "@mui/material";

const Title = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
`

const Date = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 10px 0 0;
`

const Name = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const EditIcon = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 25px;
    cursor: pointer;
    svg {
        font-size: 1.25rem;
    }
`

const InterventionAccordion = ({data, refresh}) => {

    const intl = useIntl();

    const [openEditIntervention, setOpenEditIntervention] = React.useState(false);

    const [ editIntervention, setEditIntervention ] = React.useState(null);

    const handleCloseEditIntervention = () => {
        refresh();
        setOpenEditIntervention(false);   
    }

    const handleEditIntervention = (data) => {
        setEditIntervention(data);
        setOpenEditIntervention(true);
    }

    return (
        <React.Fragment>
        <AccordionRow>
            <HeadAccordion>
                <Title>
                    <Date>{intl.formatDate(data.date, {year: "numeric",month: "numeric" ,day: "numeric" })}</Date>
                    <Name dangerouslySetInnerHTML={{ __html: data.description }} />
                </Title>
            </HeadAccordion>
            <ContentAccordion>
                <Intervention data={data} handleEdit={handleEditIntervention} refresh={refresh} />
            </ContentAccordion>
        </AccordionRow>
            <Modal
                open={openEditIntervention}
                onClose={handleCloseEditIntervention}
            >
                <NewIntervention appointmentId={data.appointmentId} data={editIntervention} handleClose={handleCloseEditIntervention} />
            </Modal>
        </React.Fragment>
    )

}

export default InterventionAccordion;